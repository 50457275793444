import React from "react";
import UsePanel from "./UserPanel";
import OrderStatus from "./OrderStatus";
import RecentCatches from "./RecentCatches";
import SocialSource from "./SocialSource";
import OverView from "./OverView";
import RevenueByLocation from "./RevenueByLocation";
import LatestRegistrations from "./LatestRegistrations";
import LatestCatches from "./LatestCatches";

import { Container, Col, Row, Card, CardBody, CardTitle } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Dashboard = () => {
  document.title = "Home | Fish-Trak - Internal Management Dashboard";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <CardTitle>Overview</CardTitle>
          {/* User Panel Charts */}
          <UsePanel />

          {/* <Row> */}
            {/* Overview Chart */}
            {/* <OverView /> */}
            {/* Social Source Chart */}
            {/* <SocialSource /> */}
          {/* </Row> */}

          {/* <Row> */}
            {/* Order Stats */}
            {/* <OrderStatus /> */}
            {/* Recent Catches */}
            {/* <RecentCatches /> */}
            {/* Catches by Location Vector Map */}
            {/* <RevenueByLocation /> */}
          {/* </Row> */}

          {/* Latest Registrations Table */}
          <LatestCatches />
          <LatestRegistrations />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
