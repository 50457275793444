// AllTags.jsx

import React, { useRef, useState, useEffect } from 'react';
import { 
    Button, 
    Card, 
    CardBody, 
    CardTitle, 
    CardHeader, 
    Col, 
    Container, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    Row, 
    ModalHeader, 
    Input, 
    FormGroup, 
    Label 
} from 'reactstrap';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import { serverTimestamp } from 'firebase/firestore';

// Import Toastify components
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AllTags = () => {
    
    const [initialLoad, setInitialLoad] = useState(true);
    const [allTagsList, setAllTagsList] = useState([]);
    const [filteredTagsList, setFilteredTagsList] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [allTagPrograms, setAllTagPrograms] = useState([]);
    const [allUsersList, setAllUsersList] = useState([]);
    const [allSpeciesList, setAllSpeciesList] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);
    
    // Action buttons
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateTagId, setUpdateTagId] = useState('');
    const [updateTagIndex, setUpdateTagIndex] = useState(null);

    const [deleteTagId, setDeleteTagId] = useState('');
    const [deleteTagIndex, setDeleteTagIndex] = useState(null);
    const [isDeletingUnused, setIsDeletingUnused] = useState(false);
    const [isDeletingUsed, setIsDeletingUsed] = useState(false);
    
    const [restoreTagId, setRestoreTagId] = useState('');
    const [restoreTagIndex, setRestoreTagIndex] = useState(null);
    const [isRestoringUnused, setIsRestoringUnused] = useState(false);
    const [isRestoringUsed, setIsRestoringUsed] = useState(false);
    
    document.title = "Fish Tags | Fish-Trak - Internal Management Dashboard";

    // Modals
    const [modal_list, setmodal_list] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteUsedModal, setDeleteUsedModal] = useState(false);
    const [deleteUnusedModal, setDeleteUnusedModal] = useState(false);
    const [restoreUsedModal, setRestoreUsedModal] = useState(false);
    const [restoreUnusedModal, setRestoreUnusedModal] = useState(false);

    // Filter, Search, & Pagination
    const [selectedSpeciesFilter, setSelectedSpeciesFilter] = useState('');
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    // Multi-Select (Bulk Delete)
    const [selectedTags, setSelectedTags] = useState([]);
    const [bulkDeleteModal, setBulkDeleteModal] = useState(false);
    const [isBulkDeleting, setIsBulkDeleting] = useState(false);

    // ---------------------------
    // NEW: States for "searchable" species filter
    // ---------------------------
    const [speciesSearch, setSpeciesSearch] = useState('');
    const [showSpeciesDropdown, setShowSpeciesDropdown] = useState(false);

    // ---------------------------
    // Modal Toggles
    // ---------------------------
    function tog_list() {
        setmodal_list(!modal_list);
    }
    const toggleModal = () => {
        setmodal_list(!modal_list);
    }
    function toggleEditModal(tagId, tagIndex) {
        if(editModal) {
            setUpdateTagId('');
            setUpdateTagIndex(null);
        } else {
            setUpdateTagId(tagId);
            setUpdateTagIndex(tagIndex);
        }
        setEditModal(!editModal);
    }
    function toggleDeleteUsedModal(tagId, tagIndex) {
        if(deleteUsedModal) {
            setDeleteTagId('');
            setDeleteTagIndex(null);
        } else {
            setDeleteTagId(tagId);
            setDeleteTagIndex(tagIndex);
        }
        setDeleteUsedModal(!deleteUsedModal);
    }
    function toggleDeleteUnusedModal(tagId, tagIndex) {
        if(deleteUnusedModal) {
            setDeleteTagId('');
            setDeleteTagIndex(null);
        } else {
            setDeleteTagId(tagId);
            setDeleteTagIndex(tagIndex);
        }
        setDeleteUnusedModal(!deleteUnusedModal);
    }
    function toggleRestoreUsedModal(tagId, tagIndex) {
        if(restoreUsedModal) {
            setRestoreTagId('');
            setRestoreTagIndex(null);
        } else {
            setRestoreTagId(tagId);
            setRestoreTagIndex(tagIndex);
        }
        setRestoreUsedModal(!restoreUsedModal);
    }
    function toggleRestoreUnusedModal(tagId, tagIndex) {
        if(restoreUnusedModal) {
            setRestoreTagId('');
            setRestoreTagIndex(null);
        } else {
            setRestoreTagId(tagId);
            setRestoreTagIndex(tagIndex);
        }
        setRestoreUnusedModal(!restoreUnusedModal);
    }
    const toggleBulkDeleteModal = () => {
        setBulkDeleteModal(!bulkDeleteModal);
    };

    // ---------------------------
    // Helpers
    // ---------------------------
    function findNameById(userId) {
        const userObject = allUsersList && allUsersList.find((user) => user.id === userId);
        return userObject && userObject.name ? userObject.name : "No User Name";
    }

    // ---------------------------
    // Deletion / Restoration Logic
    // ---------------------------

    // 1. Delete Used Tag
    const deleteUsedTag = () => {
        setIsDeletingUsed(true);
        
        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(allTagsList[deleteTagIndex].id);
        const catchesRef = firebase.firestore().collection('catches');
        
        // Get all catches using the same tag
        catchesRef
         .where('tag', '==', tagRef)
         .get()
         .then(querySnapshot => {
             const batch = firebase.firestore().batch();
             
             querySnapshot.forEach(doc => {
                 const catchItem = doc.data();
                 const catchDocRef = catchesRef.doc(doc.id);
                 
                 // Prepare user data updates
                 const userDocRef = usersRef.doc(catchItem.user.id);
                 let userData = { updated_at: serverTimestamp() };
                 
                 if(catchItem.recatch) {                        
                     if(!catchItem.recatch_count || catchItem.recatch_count <= 1) {
                         userData['recatch_count'] = 0; 
                     } else {
                         userData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                     }
                 } else {
                     if(!catchItem.catch_count || catchItem.catch_count <= 1) {
                         userData['catch_count'] = 0; 
                     } else {
                         userData['catch_count'] = firebase.firestore.FieldValue.increment(-1);
                     }
                 }

                 // Potentially decrement species_count if catchItem.fish_species is set
                 if(catchItem.fish_species && catchItem.fish_species.id) {
                     userData['species_count'] = firebase.firestore.FieldValue.increment(-1);
                 }
                 
                 batch.update(userDocRef, userData);
                 batch.update(catchDocRef, { updated_at: serverTimestamp(), deleted: true });
             });
             
             // Update the tag as deleted
             batch.update(tagRef, { updated_at: serverTimestamp(), deleted: true });
             
             // Commit the batch
             return batch.commit();
         })
         .then(() => {
             fetchAllFishTagsFiltered();
             setDeleteTagId('');
             setDeleteTagIndex(null);
             setIsDeletingUsed(false);
             toast.success('The fish tag has been deleted successfully!');
         })
         .catch((error) => {
             console.error(error);
             setIsDeletingUsed(false);
             toast.error('Could not delete tag entry. Please try again.');
         });
    };

    // 2. Restore Used Tag
    const restoreUsedTag = () => {
        setIsRestoringUsed(true);
        
        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(allTagsList[restoreTagIndex].id);
        const catchesRef = firebase.firestore().collection('catches');
        
        // Get all catches using the same tag
        catchesRef
         .where('tag', '==', tagRef)
         .get()
         .then(querySnapshot => {
             const batch = firebase.firestore().batch();
             
             querySnapshot.forEach(doc => {
                 const catchItem = doc.data();
                 const catchDocRef = catchesRef.doc(doc.id);
                 
                 // Prepare user data updates
                 const userDocRef = usersRef.doc(catchItem.user.id);
                 let userData = { updated_at: serverTimestamp() };
                 
                 if(catchItem.recatch) {                        
                     userData['recatch_count'] = firebase.firestore.FieldValue.increment(1);
                 } else {
                     userData['catch_count'] = firebase.firestore.FieldValue.increment(1);
                 }

                 // Also increment species_count if a species is recorded
                 if(catchItem.fish_species && catchItem.fish_species.id) {
                     userData['species_count'] = firebase.firestore.FieldValue.increment(1);
                 }
                 
                 batch.update(userDocRef, userData);
                 batch.update(catchDocRef, { updated_at: serverTimestamp(), deleted: false });
             });
             
             // Update the tag as not deleted
             batch.update(tagRef, { updated_at: serverTimestamp(), deleted: false });
             
             // Commit the batch
             return batch.commit();
         })
         .then(() => {
             fetchAllFishTagsFiltered();
             setRestoreTagId('');
             setRestoreTagIndex(null);
             setIsRestoringUsed(false);
             toast.success('The fish tag has been restored successfully!');
         })
         .catch((error) => {
             console.error(error);
             setIsRestoringUsed(false);
             toast.error('Could not restore tag entry. Please try again.');
         });
    };

    // 3. Delete Unused Tag
    const deleteUnusedTag = async () => {
        setIsDeletingUnused(true);
        const tagDocRef = firebase.firestore().collection('tags').doc(deleteTagId);

        tagDocRef
          .update({
            updated_at: serverTimestamp(),
            deleted: true
          })
          .then(() => {
            fetchAllFishTagsFiltered();
            setDeleteTagId('');
            setDeleteTagIndex(null);
            setIsDeletingUnused(false);
            toast.success('The fish tag has been deleted successfully!');
          })
          .catch((error) => {
            console.error(error);
            setIsDeletingUnused(false);
            toast.error('Could not delete tag entry. Please try again.');
          });
    };

    // 4. Restore Unused Tag
    const restoreUnusedTag = async () => {
        setIsRestoringUnused(true);
        const tagDocRef = firebase.firestore().collection('tags').doc(restoreTagId);

        tagDocRef
            .update({
              updated_at: serverTimestamp(),
              deleted: false
            })
            .then(() => {
              fetchAllFishTagsFiltered(); 
              setRestoreTagId('');
              setRestoreTagIndex(null);
              setIsRestoringUnused(false);
              toast.success('The fish tag has been restored successfully!');
            })
            .catch((error) => {
              console.error(error);
              setIsRestoringUnused(false);
              toast.error('Could not restore tag entry. Please try again.');
            });
    };

    // ---------------------------
    // CREATE / UPDATE
    // ---------------------------
    const submitUpdateForm = async (event) => {
        setIsUpdating(true);
        event.preventDefault();
        
        const programId = event.target.tag_program.value;
        const userId = event.target.first_angler.value;
        const speciesId = event.target.fish_species.value; 
        const tagRef = firebase.firestore().collection('tags');
                 
        // Create object for updated data
        const tagData = {
          updated_at: serverTimestamp(),
        };

        // Compare & update each field
        if(event.target.tag_number.value !== allTagsList[updateTagIndex].tag) {
            tagData['tag_number'] = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
        }
        if(programId !== allTagsList[updateTagIndex].tag_program.id) {
            tagData['tag_program'] = firebase.firestore().collection('programs').doc(programId);
            tagData['program_image'] = allTagPrograms.find(p => p.id === programId)?.image || '';
            tagData['program_name'] = allTagPrograms.find(p => p.id === programId)?.name || '';
        }
        if(userId !== allTagsList[updateTagIndex].first_angler.id) {
            tagData['first_angler'] = firebase.firestore().collection('users').doc(userId);
        }
        if(speciesId) {
            tagData['fish_species'] = firebase.firestore().collection('species').doc(speciesId);
        } else {
            tagData['fish_species'] = firebase.firestore.FieldValue.delete(); 
        }
        
        tagRef
            .doc(allTagsList[updateTagIndex].id)
            .update(tagData)
            .then(() => {
              toast.success('The tag has been updated successfully!');
              fetchAllFishTagsFiltered(); 
            })
            .catch((error) => {
              console.error(error);
              setIsUpdating(false);
              toast.error('Could not update tag entry. Please try again.');
            });  
    };

    // ---------------------------
    // FILTERS & CSV DOWNLOAD
    // ---------------------------
    const fetchAllSpecies = async () => {
      firebase
       .firestore()
       .collection('species')
       .orderBy('name','asc')
       .onSnapshot(
          querySnapshot => {
              const newSpecies = [];
              querySnapshot.forEach(doc => {
                  const specItem = doc.data();
                  specItem.id = doc.id;
                  newSpecies.push(specItem);
              });
              setAllSpeciesList(newSpecies);
          },
          error => {
              console.log(error);
          }
       );
    };

    // Real-time fetch for the combined list (used+unused) with filters
    const fetchAllFishTagsFiltered = () => {
      let newQuery = firebase
        .firestore()
        .collection('tags')
        .orderBy('created_at','desc');
  
      // Apply species filter if selected
      if (selectedSpeciesFilter) {
        const specRef = firebase.firestore().collection('species').doc(selectedSpeciesFilter);
        newQuery = newQuery.where('fish_species', '==', specRef);
      }
  
      // Apply date range filters
      if (startDateFilter && endDateFilter) {
        newQuery = newQuery
          .where('created_at','>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
          .where('created_at','<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
      } else if (startDateFilter && !endDateFilter) {
        newQuery = newQuery
          .where('created_at','>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
      } else if (!startDateFilter && endDateFilter) {
        newQuery = newQuery
          .where('created_at','<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
      }
  
      // Real-time listener
      const unsubscribe = newQuery.onSnapshot(
        (querySnapshot) => {
          const newTagsList = [];
          querySnapshot.forEach(doc => {
            const tagItem = doc.data();
            const tagId = doc.id;
            tagItem.id = tagId;
            
            tagItem.tag = '#' + tagItem.tag_number;
            tagItem.program = tagItem.program_name;
            
            const createdAt = tagItem?.created_at?.toDate()?.toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
            tagItem.created = createdAt || '';
            const updatedAt = tagItem?.updated_at?.toDate()?.toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
            tagItem.updated = updatedAt || '';

            // Map fish_species reference to species name if it exists
            if (tagItem.fish_species) {
              const speciesDocId = tagItem.fish_species.id;
              const species = allSpeciesList.find(spec => spec.id === speciesDocId);
              tagItem.species = species ? species.name : "Unknown Species";
            } else {
              tagItem.species = "No Species";
            }

            tagItem.userId = tagItem.first_angler ? tagItem.first_angler.id : null;  
            tagItem.user_link = tagItem.first_angler ? 'user/details/' + tagItem.first_angler.id : '#';  
            tagItem.link = 'tag/details/' + tagId;  
            tagItem.program_link = 'program/details/' + (tagItem.tag_program?.id || '#');

            newTagsList.push(tagItem);
          });

          setAllTagsList(newTagsList);
          setFetchedData(true);
          setIsUploading(false);

          setmodal_list(false);
          setDeleteUsedModal(false);
          setDeleteUnusedModal(false);
          setRestoreUsedModal(false);
          setRestoreUnusedModal(false);
          setIsDeletingUnused(false);
          setIsDeletingUsed(false);
          setIsRestoringUnused(false);
          setIsRestoringUsed(false);
          setEditModal(false);
          setIsUpdating(false);
        },
        (error) => {
          console.log(error);
        }
      );

      return () => unsubscribe();
    };

    const handleApplyFilters = () => {
      setCurrentPage(0); 
      fetchAllFishTagsFiltered();
    };

    const handleDownloadCSV = async () => {
      try {
        let csvQuery = firebase
          .firestore()
          .collection('tags')
          .orderBy('created_at','desc');
  
        // Species filter
        if (selectedSpeciesFilter) {
          const specRef = firebase.firestore().collection('species').doc(selectedSpeciesFilter);
          csvQuery = csvQuery.where('fish_species', '==', specRef);
        }
  
        // Date range filters
        if (startDateFilter && endDateFilter) {
          csvQuery = csvQuery
            .where('created_at','>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
            .where('created_at','<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        } else if (startDateFilter && !endDateFilter) {
          csvQuery = csvQuery
            .where('created_at','>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
        } else if (!startDateFilter && endDateFilter) {
          csvQuery = csvQuery
            .where('created_at','<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        }
  
        const snapshot = await csvQuery.get();
        const csvRows = [];
        // CSV header
        csvRows.push([
          'id',
          'tag_number',
          'program_name',
          'species_name',
          'created_at',
          'created_time',
          'updated_at',
          'updated_time',
          'catch_count',
          'used',
          'deleted'
        ].join(','));
  
        snapshot.forEach(doc => {
          const data = doc.data();
          let createdVal = '';
          if(data.created_at) {
            createdVal = data.created_at.toDate().toLocaleString();
          }
          let updatedVal = '';
          if(data.updated_at) {
            updatedVal = data.updated_at.toDate().toLocaleString();
          }
          let speciesName = "No Species";
          if (data.fish_species) {
            const speciesDocId = data.fish_species.id;
            const species = allSpeciesList.find(spec => spec.id === speciesDocId);
            speciesName = species ? species.name : "Unknown Species";
          }
  
          const row = [
            doc.id,
            (data.tag_number || '').replace(/,/g,''),
            (data.program_name || '').replace(/,/g,''),
            (speciesName || '').replace(/,/g,''),
            createdVal,
            updatedVal,
            data.catch_count || 0,
            data.used ? 'true' : 'false',
            data.deleted ? 'true' : 'false'
          ];
          csvRows.push(row.join(','));
        });
  
        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type:'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download','filtered_all_tags.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
  
        toast.success("CSV downloaded successfully.");
      } catch (error) {
        console.error('CSV error:', error);
        toast.error('Could not download CSV. Please try again.');
      }
    };

    // ---------------------------
    // CREATE NEW FISH TAG
    // ---------------------------
    const createNewFishTag = async (event) => {
        setIsUploading(true);
        event.preventDefault();
        
        const programId = event.target.tag_program.value;
        const userId = event.target.first_user.value;
        const speciesId = event.target.fish_species.value; 
        const tagRef = firebase.firestore().collection('tags');
                
        const tagData = {
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
          tag_number: event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, ''),
          catch_count: 0,
          used: false,
          deleted: false,
          program_image: allTagPrograms.find(p => p.id === programId)?.image || '',
          program_name: allTagPrograms.find(p => p.id === programId)?.name || '',
          tag_program: firebase.firestore().collection('programs').doc(programId),
          first_angler: firebase.firestore().collection('users').doc(userId),
        };
        
        // Optional species
        if (speciesId) {
          tagData.fish_species = firebase.firestore().collection('species').doc(speciesId);
        }
        
        try {
            await tagRef.add(tagData);
            toast.success('The new fish tag has been created successfully!');
            fetchAllFishTagsFiltered(); 
        } catch(error) {
            console.error(error);
            setIsUploading(false);
            toast.error('Could not create fish tag entry. Please try again.');
        }
    };

    // ---------------------------
    // FETCH SUPPORT
    // ---------------------------
    const fetchTagPrograms = async () => {
        firebase
         .firestore()
         .collection('programs')
         .orderBy('created_at','desc')
         .onSnapshot(
              querySnapshot => {
                  const newProgramsList = [];
                  querySnapshot.forEach(doc => {
                      const programItem = doc.data();
                      programItem.id = doc.id;
                      
                      programItem.name = programItem.program_name;
                      programItem.image = programItem.image_url;
                      
                      newProgramsList.push(programItem);
                  });
                  setAllTagPrograms(newProgramsList);
              },
              error => {
                  console.log(error);
              }
          );
    };
    
    const fetchAllUsers = async () => {
        firebase
         .firestore()
         .collection('users')
         .orderBy('created_at','desc')
         .onSnapshot(
               querySnapshot => {
                   const newUsersList = [];
                   querySnapshot.forEach(doc => {
                       const userItem = doc.data();
                       userItem.id = doc.id;
                       userItem.name = `${userItem.first_name} ${userItem.last_name}`;
                       userItem.email = userItem.email_address;
                       newUsersList.push(userItem);
                   });
                   setAllUsersList(newUsersList);
               },
               error => {
                   console.log(error);
               }
           );
    };

    useEffect(() => {
        if(initialLoad) {
           setInitialLoad(false);
           fetchAllSpecies();
           fetchTagPrograms();
           fetchAllUsers();
           fetchAllFishTagsFiltered();
        }
    }, [initialLoad]);

    // ---------------------------
    // SEARCH AND FILTER LOGIC
    // ---------------------------
    useEffect(() => {
        let tempTags = [...allTagsList];
        
        // Apply search filter
        if(searchQuery) {
            const query = searchQuery.toLowerCase();
            tempTags = tempTags.filter(tag => 
                tag.tag.toLowerCase().includes(query) ||
                tag.program.toLowerCase().includes(query) ||
                (tag.species && tag.species.toLowerCase().includes(query)) ||
                (tag.userId && findNameById(tag.userId).toLowerCase().includes(query))
            );
        }

        setFilteredTagsList(tempTags);
        setCurrentPage(0);
    }, [allTagsList, searchQuery]);

    // ---------------------------
    // PAGINATION
    // ---------------------------
    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };
    const offset = currentPage * itemsPerPage;
    const currentPageData = filteredTagsList.slice(offset, offset + itemsPerPage);
    const pageCount = Math.ceil(filteredTagsList.length / itemsPerPage);

    // ---------------------------
    // MULTI-SELECT
    // ---------------------------
    const handleSelectTag = (tagId) => {
        setSelectedTags(prev => {
            if (prev.includes(tagId)) {
                return prev.filter(id => id !== tagId);
            } else {
                return [...prev, tagId];
            }
        });
    };
    const handleSelectAll = () => {
        if (selectedTags.length === filteredTagsList.length && filteredTagsList.length > 0) {
            setSelectedTags([]);
        } else {
            const allTagIds = filteredTagsList.map(tag => tag.id);
            setSelectedTags(allTagIds);
        }
    };

    // ---------------------------
    // BULK DELETE
    // ---------------------------
    const deleteSelectedTags = async () => {
        setIsBulkDeleting(true);

        // Separate tags into used and unused
        const usedTags = allTagsList.filter(tag => selectedTags.includes(tag.id) && tag.used && !tag.deleted);
        const unusedTags = allTagsList.filter(tag => selectedTags.includes(tag.id) && !tag.used && !tag.deleted);

        // Delete a single used tag
        const deleteUsedTagBulk = async (tag) => {
            return new Promise((resolve, reject) => {
                const usersRef = firebase.firestore().collection('users');
                const tagRef = firebase.firestore().collection('tags').doc(tag.id);
                const catchesRef = firebase.firestore().collection('catches');

                catchesRef
                  .where('tag','==',tagRef)
                  .get()
                  .then(querySnapshot => {
                      const batch = firebase.firestore().batch();
                      querySnapshot.forEach(doc => {
                          const catchItem = doc.data();
                          const catchDocRef = catchesRef.doc(doc.id);

                          const userDocRef = usersRef.doc(catchItem.user.id);
                          let userData = { updated_at: serverTimestamp() };

                          if(catchItem.recatch) {
                              if(!catchItem.recatch_count || catchItem.recatch_count <= 1) {
                                  userData['recatch_count'] = 0; 
                              } else {
                                  userData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                              }
                          } else {
                              if(!catchItem.catch_count || catchItem.catch_count <= 1) {
                                  userData['catch_count'] = 0; 
                              } else {
                                  userData['catch_count'] = firebase.firestore.FieldValue.increment(-1);
                              }
                          }
                          if(catchItem.fish_species && catchItem.fish_species.id) {
                              userData['species_count'] = firebase.firestore.FieldValue.increment(-1);
                          }

                          batch.update(userDocRef, userData);
                          batch.update(catchDocRef, { updated_at: serverTimestamp(), deleted: true });
                      });

                      batch.update(tagRef, { updated_at: serverTimestamp(), deleted: true });
                      batch.commit()
                        .then(() => resolve())
                        .catch(err => reject(err));
                  })
                  .catch(err => reject(err));
            });
        };

        // Delete a single unused tag
        const deleteUnusedTagBulk = async (tag) => {
            return new Promise((resolve, reject) => {
                const tagRef = firebase.firestore().collection('tags').doc(tag.id);
                tagRef
                  .update({
                    updated_at: serverTimestamp(),
                    deleted: true
                  })
                  .then(() => resolve())
                  .catch(err => reject(err));
            });
        };

        const usedDeletionPromises = usedTags.map(tag => deleteUsedTagBulk(tag));
        const unusedDeletionPromises = unusedTags.map(tag => deleteUnusedTagBulk(tag));

        try {
            await Promise.all([...usedDeletionPromises, ...unusedDeletionPromises]);
            toast.success('Selected tags have been deleted successfully!');
            setSelectedTags([]);
            toggleBulkDeleteModal();
            fetchAllFishTagsFiltered();
        } catch(error) {
            console.error('Error deleting selected tags (bulk):', error);
            toast.error('Could not delete some of the selected tags. Please try again.');
        } finally {
            setIsBulkDeleting(false);
        }
    };

    return (
        <React.Fragment>
            {/* Toastify Container */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="page-content">
                <Container fluid>
                    <CardTitle>Fish Tags</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">All Tags</h4>
                                </CardHeader>

                                <CardBody>
                                    {/* Filters Row */}
                                    <Row className="g-4 mb-3">
                                        <Col sm="1" className="d-flex align-items-start">
                                            <Button
                                              color="success"
                                              className="add-btn"
                                              onClick={() => tog_list()}
                                              id="create-btn"
                                              style={{ width:'100%' }}
                                            >
                                                <i className="mdi mdi-tag-plus"></i>
                                            </Button>
                                        </Col>
                                        <Col sm="1" className="d-flex align-items-start">
                                            <Button
                                              color="danger"
                                              className="add-btn"
                                              onClick={toggleBulkDeleteModal}
                                              disabled={selectedTags.length === 0}
                                              style={{ width:'100%' }}
                                            >
                                                <i className="mdi mdi-delete"></i>
                                            </Button>
                                        </Col>
                                        <Col className="d-flex align-items-start">
                                            <Input
                                              type="text"
                                              name="search"
                                              id="search"
                                              placeholder="Search Tags..."
                                              value={searchQuery}
                                              onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        </Col>

                                        {/* 
                                            Old species filter was here. 
                                            Now replaced by a text-based search input + dropdown
                                        */}
                                        <Col sm="2" style={{ position: "relative" }}>
                                            <Input
                                              type="text"
                                              placeholder="Search Species..."
                                              value={speciesSearch}
                                              onChange={(e) => {
                                                  setSpeciesSearch(e.target.value);
                                                  if(e.target.value.trim() !== '') {
                                                      setShowSpeciesDropdown(true);
                                                  } else {
                                                      setShowSpeciesDropdown(false);
                                                  }
                                              }}
                                              onFocus={() => {
                                                  if(speciesSearch.trim() !== '') {
                                                      setShowSpeciesDropdown(true);
                                                  }
                                              }}
                                            />
                                            {showSpeciesDropdown && (
                                                <div 
                                                  style={{
                                                    position: "absolute",
                                                    top: "100%",
                                                    left: 0,
                                                    right: 0,
                                                    zIndex: 999,
                                                    background: "#fff",
                                                    border: "1px solid #ccc",
                                                    maxHeight: "200px",
                                                    overflowY: "auto"
                                                  }}
                                                >
                                                    {/* "All Species" to reset */}
                                                    <div
                                                        className="p-2"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedSpeciesFilter('');
                                                            setSpeciesSearch('');
                                                            setShowSpeciesDropdown(false);
                                                        }}
                                                    >
                                                        All Species
                                                    </div>
                                                    {allSpeciesList
                                                      .filter(sp => 
                                                        sp.name.toLowerCase().includes(speciesSearch.toLowerCase())
                                                      )
                                                      .map(sp => (
                                                        <div
                                                          key={sp.id}
                                                          className="p-2"
                                                          style={{ cursor:"pointer" }}
                                                          onClick={() => {
                                                            setSelectedSpeciesFilter(sp.id);
                                                            setSpeciesSearch(sp.name);
                                                            setShowSpeciesDropdown(false);
                                                          }}
                                                        >
                                                            {sp.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </Col>

                                        <Col sm="2">
                                            <Flatpickr
                                              className="form-control"
                                              options={{ dateFormat:"Y-m-d" }}
                                              placeholder="Start Date"
                                              value={startDateFilter}
                                              onChange={(dates)=> setStartDateFilter(dates[0] || null)}
                                            />
                                        </Col>
                                        <Col sm="2">
                                            <Flatpickr
                                              className="form-control"
                                              options={{ dateFormat:"Y-m-d" }}
                                              placeholder="End Date"
                                              value={endDateFilter}
                                              onChange={(dates)=> setEndDateFilter(dates[0] || null)}
                                            />
                                        </Col>
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button
                                              color="secondary"
                                              style={{ width:'100%' }}
                                              onClick={handleApplyFilters}
                                            >
                                                <i className="mdi mdi-filter" ></i>
                                            </Button>
                                        </Col>
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button
                                              color="primary"
                                              style={{ width:'100%' }}
                                              onClick={handleDownloadCSV}
                                            >
                                                <i className="mdi mdi-download" ></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                    {/* End Filters Row */}

                                    <div id="tags-list">
                                        <div className="table-responsive table-card mt-3 mb-1">
                                        {(!isUpdating && !isDeletingUsed && !isDeletingUnused) && (
                                            <>
                                                <table className="table align-middle table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            {/* Multi-Select: Select All */}
                                                            <th scope="col" style={{ width: "50px" }}>
                                                                <Input
                                                                  type="checkbox"
                                                                  onChange={() => {
                                                                    if(selectedTags.length === filteredTagsList.length && filteredTagsList.length>0) {
                                                                        setSelectedTags([]);
                                                                    } else {
                                                                        setSelectedTags(filteredTagsList.map(t => t.id));
                                                                    }
                                                                  }}
                                                                  checked={selectedTags.length===filteredTagsList.length && filteredTagsList.length>0}
                                                                />
                                                            </th>
                                                            <th scope="col" style={{ width: "50px" }}>View</th>
                                                            <th scope="col">Fish Tag Number</th>
                                                            <th scope="col">Program Name</th>
                                                            <th scope="col">Angler</th>
                                                            <th scope="col">Created Date</th>
                                                            <th scope="col">Updated Date</th>
                                                            <th scope="col">Catches</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentPageData.length > 0 ? currentPageData.map(tag => (
                                                            <tr key={tag.id}>
                                                                {/* Multi-Select: Row Checkbox */}
                                                                <td>
                                                                    <Input
                                                                      type="checkbox"
                                                                      checked={selectedTags.includes(tag.id)}
                                                                      onChange={() => {
                                                                        if(selectedTags.includes(tag.id)) {
                                                                            setSelectedTags(prev=>prev.filter(x=>x!==tag.id));
                                                                        } else {
                                                                            setSelectedTags(prev=>[...prev, tag.id]);
                                                                        }
                                                                      }}
                                                                    />
                                                                </td>
                                                                <th scope="row">
                                                                    <a className="btn btn-sm btn-success edit-item-btn" href={tag.link}>
                                                                        <i className="mdi mdi-eye-outline"></i>
                                                                    </a>
                                                                </th>
                                                                <td>{tag.tag}</td>
                                                                <td>
                                                                    <a href={tag.program_link} className="fw-medium link-primary">
                                                                        {tag.program}
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <a href={tag.user_link} className="fw-medium link-primary">
                                                                        {findNameById(tag.userId)}
                                                                    </a>
                                                                </td>
                                                                <td>{tag.created}</td>
                                                                <td>{tag.updated}</td>
                                                                <td>
                                                                    <span className="badge badge-soft-success text-uppercase">
                                                                        {tag.catch_count}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {tag.used ?
                                                                        <span className="badge badge-soft-success text-uppercase">Used</span>
                                                                    :
                                                                        <span className="badge badge-soft-danger text-uppercase">Un-Used</span>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex gap-2">
                                                                        {/* Edit Button */}
                                                                        <Button
                                                                            color="warning"
                                                                            size="sm"
                                                                            onClick={() => toggleEditModal(tag.id, allTagsList.findIndex(t=>t.id===tag.id))}
                                                                        >
                                                                            <i className="mdi mdi-square-edit-outline"></i>
                                                                        </Button>

                                                                        {/* Delete or Restore Buttons */}
                                                                        {!tag.used && !tag.deleted && (
                                                                            <Button
                                                                                color="danger"
                                                                                size="sm"
                                                                                onClick={() => toggleDeleteUnusedModal(tag.id, allTagsList.findIndex(t=>t.id===tag.id))}
                                                                            >
                                                                                <i className="mdi mdi-delete-outline"></i>
                                                                            </Button>
                                                                        )}
                                                                        {tag.used && !tag.deleted && (
                                                                            <Button
                                                                                color="danger"
                                                                                size="sm"
                                                                                onClick={() => toggleDeleteUsedModal(tag.id, allTagsList.findIndex(t=>t.id===tag.id))}
                                                                            >
                                                                                <i className="mdi mdi-delete-outline"></i>
                                                                            </Button>
                                                                        )}
                                                                        {!tag.used && tag.deleted && (
                                                                            <Button
                                                                                color="success"
                                                                                size="sm"
                                                                                onClick={() => toggleRestoreUnusedModal(tag.id, allTagsList.findIndex(t=>t.id===tag.id))}
                                                                                style={{ backgroundColor: "green" }}
                                                                            >
                                                                                <i className="mdi mdi-history"></i>
                                                                            </Button>
                                                                        )}
                                                                        {tag.used && tag.deleted && (
                                                                            <Button
                                                                                color="success"
                                                                                size="sm"
                                                                                onClick={() => toggleRestoreUsedModal(tag.id, allTagsList.findIndex(t=>t.id===tag.id))}
                                                                                style={{ backgroundColor: "green" }}
                                                                            >
                                                                                <i className="mdi mdi-history"></i>
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )) : (
                                                            <tr>
                                                                <td colSpan="10" className="text-center">No Fish Tags Found!</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {/* Pagination */}
                                                {pageCount > 1 && (
                                                    <div className="d-flex justify-content-center">
                                                        <ReactPaginate
                                                            previousLabel={"← Previous"}
                                                            nextLabel={"Next →"}
                                                            pageCount={pageCount}
                                                            onPageChange={handlePageClick}
                                                            containerClassName={"pagination"}
                                                            previousLinkClassName={"page-link"}
                                                            nextLinkClassName={"page-link"}
                                                            disabledClassName={"disabled"}
                                                            activeClassName={"active"}
                                                            pageLinkClassName={"page-link"}
                                                            breakLabel={"..."}
                                                            breakClassName={"page-item"}
                                                            breakLinkClassName={"page-link"}
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {allTagsList.length === 0 && (
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Fish Tags Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any fish tags.</p>
                                                </div>
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            
            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={() => toggleEditModal()} centered >
                <ModalHeader className="bg-light p-3" id="editModal" toggle={() => toggleEditModal()}>
                    Edit Tag Program
                </ModalHeader>
                
                <ModalBody>
                    {!isUpdating && allTagsList[updateTagIndex] && (
                        <form className="tablelist-form" onSubmit={submitUpdateForm}>
                            <FormGroup>
                                <Label for="tagnumber-field">Tag Number*</Label>
                                <Input
                                  type="text"
                                  id="tagnumber-field"
                                  className="form-control"
                                  placeholder="#ABCDE1234567890"
                                  maxLength={15}
                                  required
                                  name="tag_number"
                                  defaultValue={allTagsList[updateTagIndex].tag}
                                />
                            </FormGroup>
                            
                            <FormGroup>
                                <Label for="program-field">Tagging Program*</Label>
                                <Input
                                  type="select"
                                  name="tag_program"
                                  id="program-field"
                                  required
                                  defaultValue={allTagsList[updateTagIndex].tag_program.id}
                                >
                                    <option value="">Select Tag Program</option>
                                    {allTagPrograms.map(({ name, id }, index) => (
                                      <option key={index} value={id}>
                                        {name}
                                      </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            
                            <FormGroup>
                                <Label for="angler-field">Assigned User*</Label>
                                <Input
                                  type="select"
                                  name="first_angler"
                                  id="angler-field"
                                  required
                                  defaultValue={
                                    allTagsList[updateTagIndex].first_angler
                                      ? allTagsList[updateTagIndex].first_angler.id
                                      : ''
                                  }
                                >
                                    <option value="">Select User Email</option>
                                    {allUsersList.map(({ email, id }, index) => (
                                      <option key={index} value={id}>
                                        {email}
                                      </option>
                                    ))}
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="species-field">Species</Label>
                                <Input
                                  type="select"
                                  name="fish_species"
                                  id="species-field"
                                  defaultValue={
                                    allTagsList[updateTagIndex].fish_species
                                      ? allTagsList[updateTagIndex].fish_species.id
                                      : ''
                                  }
                                >
                                    <option value="">No Species</option>
                                    {allSpeciesList.map(({ name, id }, index) => (
                                      <option key={index} value={id}>
                                        {name}
                                      </option>
                                    ))}
                                </Input>
                            </FormGroup>
  
                            <ModalFooter>
                                <div className="hstack gap-2 justify-content-end">
                                    <Button
                                      type="button"
                                      color="light"
                                      onClick={() => toggleEditModal()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                      type="submit"
                                      color="success"
                                      id="add-btn"
                                    >
                                        Update
                                    </Button>
                                </div>
                            </ModalFooter>
                        </form>
                    )}
                    
                    {isUpdating && (
                        <div className="d-flex justify-content-center">
                            <ThreeCircles
                              visible={isUpdating}
                              height="100"
                              width="100"
                              color="#4192C3"
                              ariaLabel="three-circles-loading"
                              wrapperStyle={{ margin: "0 auto" }}
                              wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => tog_list()} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => tog_list()}>
                    Add Fish Tag
                </ModalHeader>
                
                <ModalBody>
                    {!isUploading && (
                        <form className="tablelist-form" onSubmit={createNewFishTag}>
                            <FormGroup>
                                <Label for="tagnumber-field">Tag Number*</Label>
                                <Input
                                  type="text"
                                  id="tagnumber-field"
                                  className="form-control"
                                  placeholder="#ABCDE1234567890"
                                  maxLength={15}
                                  required
                                  name="tag_number"
                                />
                            </FormGroup>
                            
                            <FormGroup>
                                <Label for="program-field">Tagging Program*</Label>
                                <Input
                                  type="select"
                                  name="tag_program"
                                  id="program-field"
                                  required
                                >
                                    <option value="">Select Tag Program</option>
                                    {allTagPrograms.map(({ name, id }, index) => (
                                      <option key={index} value={id}>
                                        {name}
                                      </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            
                            <FormGroup>
                                <Label for="angler-field">Assigned User*</Label>
                                <Input
                                  type="select"
                                  name="first_user"
                                  id="angler-field"
                                  required
                                >
                                    <option value="">Select User Email</option>
                                    {allUsersList.map(({ email, id }, index) => (
                                      <option key={index} value={id}>
                                        {email}
                                      </option>
                                    ))}
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="species-field">Species</Label>
                                <Input
                                  type="select"
                                  name="fish_species"
                                  id="species-field"
                                >
                                    <option value="">No Species</option>
                                    {allSpeciesList.map(({ name, id }, index) => (
                                      <option key={index} value={id}>
                                        {name}
                                      </option>
                                    ))}
                                </Input>
                            </FormGroup>
  
                            <ModalFooter>
                                <div className="hstack gap-2 justify-content-end">
                                    <Button
                                      type="button"
                                      color="light"
                                      onClick={() => setmodal_list(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                      type="submit"
                                      color="success"
                                      id="add-btn"
                                    >
                                        Add Tag
                                    </Button>
                                </div>
                            </ModalFooter>
                        </form>
                    )}
                    
                    {isUploading && (
                        <div className="d-flex justify-content-center">
                            <ThreeCircles
                              visible={isUploading}
                              height="100"
                              width="100"
                              color="#4192C3"
                              ariaLabel="three-circles-loading"
                              wrapperStyle={{ margin: "0 auto" }}
                              wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                    
                </ModalBody>
                
            </Modal>

            {/* Delete Unused Modal */}
            <Modal
              isOpen={deleteUnusedModal}
              toggle={() => toggleDeleteUnusedModal()}
              className="modal zoomIn"
              id="deleteUnusedModal"
              centered
            >
                <ModalBody>
                    {!isDeletingUnused && (
                        <>
                            <div className="mt-2 text-center">
                                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                    <h4>Delete Un-Used Fish Tag</h4>
                                    <p className="text-muted mx-4 mb-0">Are you sure you want to delete this fish tag?</p>
                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <Button
                                  type="button"
                                  color="light"
                                  onClick={() => toggleDeleteUnusedModal()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                  type="button"
                                  color="danger"
                                  id="delete-record"
                                  onClick={deleteUnusedTag}
                                >
                                    Delete
                                </Button>
                            </div>
                        </>
                    )}
                        
                    {isDeletingUnused && (
                        <div className="d-flex justify-content-center">
                            <ThreeCircles
                              visible={isDeletingUnused}
                              height="100"
                              width="100"
                              color="#ff3d60"
                              ariaLabel="three-circles-loading"
                              wrapperStyle={{ margin: "0 auto" }}
                              wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>
                
            {/* Delete Used Modal */}
            <Modal
              isOpen={deleteUsedModal}
              toggle={() => toggleDeleteUsedModal()}
              className="modal zoomIn"
              id="deleteUsedModal"
              centered
            >
                <ModalBody>
                    {!isDeletingUsed && (
                        <>
                            <div className="mt-2 text-center">
                                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                    <h4>Delete Used Fish Tag</h4><br/>
                                    <h5 className="text-muted mx-4 mb-0">
                                        This will delete the tag along with connected catches or recatches!!!
                                    </h5><br/>
                                    <p className="text-muted mx-4 mb-0">
                                        Are you sure you want to delete this tag?
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <Button
                                  type="button"
                                  color="light"
                                  onClick={() => toggleDeleteUsedModal()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                  type="button"
                                  color="danger"
                                  id="delete-record"
                                  onClick={deleteUsedTag}
                                >
                                    Delete
                                </Button>
                            </div>
                        </>
                    )}
                        
                    {isDeletingUsed && (
                        <div className="d-flex justify-content-center">
                            <ThreeCircles
                              visible={isDeletingUsed}
                              height="100"
                              width="100"
                              color="#ff3d60"
                              ariaLabel="three-circles-loading"
                              wrapperStyle={{ margin: "0 auto" }}
                              wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>
                
            {/* Restore Unused Modal */}
            <Modal
              isOpen={restoreUnusedModal}
              toggle={() => toggleRestoreUnusedModal()}
              className="modal zoomIn"
              id="restoreUnusedModal"
              centered
            >
                <ModalBody>
                    {!isRestoringUnused ? (
                        <>
                            <div className="mt-2 text-center">
                                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                    <h4>Restore Un-Used Fish Tag</h4>
                                    <p className="text-muted mx-4 mb-0">
                                        Are you sure you want to restore this tag?
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <Button
                                  type="button"
                                  color="light"
                                  onClick={() => toggleRestoreUnusedModal()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                  type="button"
                                  color="success"
                                  id="restore-record"
                                  onClick={restoreUnusedTag}
                                >
                                    Restore
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="d-flex justify-content-center">
                            <ThreeCircles
                              visible={isRestoringUnused}
                              height="100"
                              width="100"
                              color="#4192C3"
                              ariaLabel="three-circles-loading"
                              wrapperStyle={{ margin: "0 auto" }}
                              wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>
                
            {/* Restore Used Modal */}
            <Modal
              isOpen={restoreUsedModal}
              toggle={() => toggleRestoreUsedModal()}
              className="modal zoomIn"
              id="restoreUsedModal"
              centered
            >
                <ModalBody>
                    {!isRestoringUsed ? (
                        <>
                            <div className="mt-2 text-center">
                                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                    <h4>Restore Used Fish Tag</h4><br/>
                                    <h5 className="text-muted mx-4 mb-0">
                                        This will restore the tag along with connected catches or recatches!!!
                                    </h5><br/>
                                    <p className="text-muted mx-4 mb-0">
                                        Are you sure you want to restore this tag?
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <Button
                                  type="button"
                                  color="light"
                                  onClick={() => toggleRestoreUsedModal()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                  type="button"
                                  color="success"
                                  id="restore-record"
                                  onClick={restoreUsedTag}
                                >
                                    Restore
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="d-flex justify-content-center">
                            <ThreeCircles
                              visible={isRestoringUsed}
                              height="100"
                              width="100"
                              color="#4192C3"
                              ariaLabel="three-circles-loading"
                              wrapperStyle={{ margin: "0 auto" }}
                              wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>

            {/* Bulk Delete Confirmation Modal */}
            <Modal 
              isOpen={bulkDeleteModal} 
              toggle={toggleBulkDeleteModal} 
              className="modal zoomIn" 
              id="bulkDeleteModal" 
              centered
            >
                <ModalBody>
                    {!isBulkDeleting ? (
                        <>
                            <div className="mt-2 text-center">
                                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                    <h4>Delete Selected Fish Tags</h4>
                                    <p className="text-muted mx-4 mb-0">
                                        Are you sure you want to delete the selected fish tags?
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <Button
                                  type="button"
                                  color="light"
                                  onClick={toggleBulkDeleteModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                  type="button"
                                  color="danger"
                                  id="bulk-delete-record"
                                  onClick={deleteSelectedTags}
                                >
                                    Delete Selected
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="d-flex justify-content-center">
                            <ThreeCircles
                              visible={isBulkDeleting}
                              height="100"
                              width="100"
                              color="#ff3d60"
                              ariaLabel="three-circles-loading"
                              wrapperStyle={{ margin: "0 auto" }}
                              wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};
    
export default AllTags;