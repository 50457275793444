import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  Table,
  ModalHeader,
  Row,
  Input,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ReactPaginate from 'react-paginate';
import { ThreeCircles } from 'react-loader-spinner';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css"; // Import Flatpickr CSS

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { serverTimestamp } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const Onboardings = () => {
  
  const storage = getStorage();
  
  // ---------------------------
  // STATE VARIABLES
  // ---------------------------
  const [initialLoad, setInitialLoad] = useState(true);
  const [allOnboardings, setAllOnboardings] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  
  // Action Identifiers
  const [deleteOnboardingId, setDeleteOnboardingId] = useState('');
  const [deleteOnboardingIndex, setDeleteOnboardingIndex] = useState(null);
  
  // Modal States
  const [deleteModal, setDeleteModal] = useState(false);
  
  // Edit Modal States
  const [editModal, setEditModal] = useState(false);
  const [onboardingToEdit, setOnboardingToEdit] = useState(null);
  
  // Filter States
  const [filterCompleted, setFilterCompleted] = useState(''); // '', 'true', 'false'
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  
  // Search and Pagination States
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  
  // Users State
  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  
  // ---------------------------
  // DOCUMENT TITLE
  // ---------------------------
  useEffect(() => {
    document.title = "Onboarding Q&A | Fish-Trak - Internal Management Dashboard";
  }, []);
  
  // ---------------------------
  // FETCH USERS FOR EMAIL DROPDOWN
  // ---------------------------
  useEffect(() => {
    const fetchUsers = async () => {
      setIsUsersLoading(true);
      try {
        const usersSnapshot = await firebase.firestore().collection('users').get();
        const usersList = [];
        usersSnapshot.forEach(doc => {
          const userData = doc.data();
          usersList.push({
            id: doc.id,
            email: userData.email_address,
            first_name: userData.first_name,
            last_name: userData.last_name,
          });
        });
        setUsers(usersList);
      } catch (error) {
        console.error('Error fetching users:', error);
        toast.error('Could not fetch users. Please try again.');
      } finally {
        setIsUsersLoading(false);
      }
    };
    
    fetchUsers();
  }, []);
  
  // ---------------------------
  // FETCH ALL ONBOARDINGS
  // ---------------------------
  const fetchOnboardings = async () => {
    setIsUploading(true);
    try {
      let query = firebase.firestore().collection('onboarding');
      
      // Apply filter for 'completed'
      if (filterCompleted === 'true') {
        query = query.where('completed', '==', true);
      } else if (filterCompleted === 'false') {
        query = query.where('completed', '==', false);
      }
      
      // Apply filter for start date
      if (startDate) {
        const startTimestamp = firebase.firestore.Timestamp.fromDate(new Date(startDate.setHours(0, 0, 0, 0)));
        query = query.where('created_at', '>=', startTimestamp);
      }
      
      // Apply filter for end date
      if (endDate) {
        const endTimestamp = firebase.firestore.Timestamp.fromDate(new Date(endDate.setHours(23, 59, 59, 999)));
        query = query.where('created_at', '<=', endTimestamp);
      }
      
      // Order by 'created_at' descending
      query = query.orderBy('created_at', 'desc');
      
      // Execute query
      const snapshot = await query.get();
      
      const newOnboardingsList = [];
      snapshot.forEach(doc => {
        const onboardingItem = doc.data();
        onboardingItem.id = doc.id;
        
        onboardingItem.user_link = 'user/details/' + onboardingItem.user.id;
        onboardingItem.link = 'onboarding/details/' + onboardingItem.id;
        
        const createdAt = onboardingItem.created_at
          ? onboardingItem.created_at.toDate().toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })
          : '';
        onboardingItem.created = createdAt || '';
        
        const updatedAt = onboardingItem.updated_at
          ? onboardingItem.updated_at.toDate().toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })
          : '';
        onboardingItem.updated = updatedAt || '';
        
        newOnboardingsList.push(onboardingItem);
      });
      
      setAllOnboardings(newOnboardingsList);
      setIsUploading(false);
    } catch (err) {
      console.error('Error fetching onboardings:', err);
      toast.error('Could not fetch onboarding data. Please try again.');
      setIsUploading(false);
    }
  };
  
  // ---------------------------
  // INITIAL DATA FETCHING
  // ---------------------------
  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      fetchOnboardings(); // initial fetch
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, filterCompleted, startDate, endDate]);
  
  // ---------------------------
  // SEARCH AND FILTER LOGIC
  // ---------------------------
  const filteredOnboardings = useMemo(() => {
    return allOnboardings.filter(onboarding => {
      const matchesSearch =
        (onboarding.user_first_name && onboarding.user_first_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (onboarding.user_last_name && onboarding.user_last_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (onboarding.user_email && onboarding.user_email.toLowerCase().includes(searchQuery.toLowerCase()));
      return matchesSearch;
    });
  }, [allOnboardings, searchQuery]);
  
  // ---------------------------
  // PAGINATION CALCULATIONS
  // ---------------------------
  const pageCount = Math.ceil(filteredOnboardings.length / itemsPerPage);
  const displayedOnboardings = filteredOnboardings.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  
  // ---------------------------
  // HANDLE PAGE CHANGE
  // ---------------------------
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  
  // ---------------------------
  // HANDLE DELETE MODAL TOGGLE
  // ---------------------------
  const toggleDeleteModal = (onboardingId = '', onboardingIndex = null) => {
    if (deleteModal) {
      setDeleteOnboardingId('');
      setDeleteOnboardingIndex(null);
    } else {
      setDeleteOnboardingId(onboardingId);
      setDeleteOnboardingIndex(onboardingIndex);
    }
    
    setDeleteModal(!deleteModal);
  };
  
  // ---------------------------
  // HANDLE DELETE ONBOARDING
  // ---------------------------
  const deleteOnboarding = async () => {
    
    setIsDeleting(true);
    
    const onboardingToDelete = allOnboardings[deleteOnboardingIndex];
    const userRef = firebase.firestore().collection('users')
      .doc(onboardingToDelete.user.id);
    const onboardingRef = firebase.firestore().collection('onboarding')
      .doc(deleteOnboardingId);
    
    try {
      await onboardingRef.delete();
      await userRef.update({
        onboarded: false,
        updated_at: serverTimestamp(),
      });
      fetchOnboardings();
      toast.success('The onboarding answers have been deleted successfully!');
      setDeleteOnboardingId('');
      setDeleteOnboardingIndex(null);
      setDeleteModal(false);
    } catch (error) {
      console.error('Error deleting onboarding:', error);
      toast.error('Could not delete onboarding entry. Please try again.');
      setIsDeleting(false);
    }
  };
  
  // ---------------------------
  // HANDLE DOWNLOAD CSV
  // ---------------------------
  const handleDownloadCSV = () => {
    try {
      if (!allOnboardings || allOnboardings.length === 0) {
        toast.info('No onboardings to download.');
        return;
      }
      const csvRows = [];
      // CSV headers
      csvRows.push([
        'Onboarding ID',
        'User First Name',
        'User Last Name',
        'User Email',
        'Submitted Date',
        'Completed',
        'Deleted',
        'Years Fishing',
        'Years Fishing Option',
        'Angling Platform',
        'Angling Platform Option',
        'Monthly Fishing Times',
        'Monthly Fishing Option',
        'Personal Best Catch',
        'Target Species',
        'Fishing Locations'
      ].join(','));
      
      // Add rows
      allOnboardings.forEach(item => {
        const fishingLocations = (item.fishing_locations && item.fishing_locations.length > 0)
          ? item.fishing_locations.map(loc => `${loc.location} (${loc.option})`).join('; ')
          : '';
        
        // Prefix 'years_fishing' and 'monthly_fishing_times' with ="value" to prevent Excel from interpreting as dates
        const yearsFishing = item.years_fishing ? `="${escapeCSV(item.years_fishing)}"` : '=""';
        const monthlyFishingTimes = item.monthly_fishing_times ? `="${escapeCSV(item.monthly_fishing_times)}"` : '=""';
        
        const row = [
          escapeCSV(item.id),
          escapeCSV(item.user_first_name),
          escapeCSV(item.user_last_name),
          escapeCSV(item.user_email),
          escapeCSV(item.created),
          escapeCSV(item.completed),
          escapeCSV(item.deleted),
          yearsFishing,
          item.years_fishing_option || '',
          escapeCSV(item.angling_platform),
          item.angling_platform_option || '',
          monthlyFishingTimes,
          item.monthly_fishing_option || '',
          escapeCSV(item.personal_best_catch),
          escapeCSV(item.target_species),
          escapeCSV(fishingLocations)
        ];
        csvRows.push(row.join(','));
      });
      
      const csvString = csvRows.join('\n');
      // Add BOM to ensure Excel correctly recognizes UTF-8 encoding
      const blob = new Blob(['\ufeff' + csvString], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "onboardings.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      toast.success("CSV downloaded successfully.");
    } catch (error) {
      console.error('Error generating CSV:', error);
      toast.error('Could not download CSV. Please try again.');
    }
  };
  
  // ---------------------------
  // HELPER FUNCTION TO ESCAPE CSV FIELDS
  // ---------------------------
  const escapeCSV = (value) => {
    if (value === null || value === undefined) {
      return '';
    }
    if (typeof value === 'boolean') {
      return value ? 'true' : 'false';
    }
    const stringValue = value.toString();
    if (stringValue.includes('"') || stringValue.includes(',') || stringValue.includes('\n')) {
      return `"${stringValue.replace(/"/g, '""')}"`;
    }
    return stringValue;
  };
  
  // ---------------------------
  // HANDLE EDIT MODAL TOGGLE
  // ---------------------------
  const toggleEditModal = (onboarding = null) => {
    if (editModal) {
      setOnboardingToEdit(null);
    } else {
      setOnboardingToEdit(onboarding);
    }
    setEditModal(!editModal);
  };
  
  // ---------------------------
  // HANDLE EDIT FORM CHANGE
  // ---------------------------
  const handleEditChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith('fishing_locations_')) {
      const index = parseInt(name.split('_')[2], 10);
      const selectedLocation = value;
      const locationOptionMap = {
        'Pond': 1,
        'Lake': 2,
        'Stream': 3,
        'River': 4,
        'Tidal Bay': 5,
        'Ocean': 6,
      };
      const optionValue = locationOptionMap[selectedLocation] || '';
      const updatedLocations = [...onboardingToEdit.fishing_locations];
      updatedLocations[index] = { location: selectedLocation, option: optionValue };
      setOnboardingToEdit({ ...onboardingToEdit, fishing_locations: updatedLocations });
    } else if (name === 'years_fishing') {
      // Update years_fishing and corresponding option
      let option = '';
      if (value === '1-5') {
        option = 1;
      } else if (value === '6-10') {
        option = 2;
      } else if (value === '10+') {
        option = 3;
      }
      setOnboardingToEdit({ ...onboardingToEdit, years_fishing: value, years_fishing_option: option });
    } else if (name === 'angling_platform') {
      // Update angling_platform and corresponding option
      let option = '';
      if (value === 'Land or Dock') {
        option = 1;
      } else if (value === 'Boat') {
        option = 2;
      } else if (value === 'Kayak') {
        option = 3;
      }
      setOnboardingToEdit({ ...onboardingToEdit, angling_platform: value, angling_platform_option: option });
    } else if (name === 'monthly_fishing_times') {
      // Update monthly_fishing_times and corresponding option
      let option = '';
      if (value === '1-2') {
        option = 1;
      } else if (value === '3-4') {
        option = 2;
      } else if (value === '4+') {
        option = 3;
      }
      setOnboardingToEdit({ ...onboardingToEdit, monthly_fishing_times: value, monthly_fishing_option: option });
    } else if (name === 'completed') {
      setOnboardingToEdit({ ...onboardingToEdit, completed: checked });
    } else if (name === 'deleted') {
      setOnboardingToEdit({ ...onboardingToEdit, deleted: checked });
    } else {
      setOnboardingToEdit({ ...onboardingToEdit, [name]: value });
    }
  };
  
  // ---------------------------
  // HANDLE ADD FISHING LOCATION
  // ---------------------------
  const handleAddFishingLocation = () => {
    const updatedLocations = onboardingToEdit.fishing_locations ? [...onboardingToEdit.fishing_locations, { location: '', option: '' }] : [{ location: '', option: '' }];
    setOnboardingToEdit({ ...onboardingToEdit, fishing_locations: updatedLocations });
  };
  
  // ---------------------------
  // HANDLE REMOVE FISHING LOCATION
  // ---------------------------
  const handleRemoveFishingLocation = (index) => {
    const updatedLocations = [...onboardingToEdit.fishing_locations];
    updatedLocations.splice(index, 1);
    setOnboardingToEdit({ ...onboardingToEdit, fishing_locations: updatedLocations });
  };
  
  // ---------------------------
  // HANDLE EDIT FORM SUBMIT
  // ---------------------------
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setIsEditing(true);
    
    try {
      // Since email is selected from the dropdown, find the selected user
      const selectedUser = users.find(user => user.email === onboardingToEdit.user_email);
      if (!selectedUser) {
        toast.error('Selected user does not exist.');
        setIsEditing(false);
        return;
      }
      
      const userId = selectedUser.id;
      
      const onboardingRef = firebase.firestore().collection('onboarding').doc(onboardingToEdit.id);
      
      // Update the 'updated_at' field and related user fields
      const updatedData = {
        user_first_name: selectedUser.first_name,
        user_last_name: selectedUser.last_name,
        user_email: selectedUser.email,
        angling_platform: onboardingToEdit.angling_platform,
        angling_platform_option: onboardingToEdit.angling_platform_option,
        years_fishing: onboardingToEdit.years_fishing,
        years_fishing_option: onboardingToEdit.years_fishing_option,
        monthly_fishing_times: onboardingToEdit.monthly_fishing_times,
        monthly_fishing_option: onboardingToEdit.monthly_fishing_option,
        personal_best_catch: onboardingToEdit.personal_best_catch,
        target_species: onboardingToEdit.target_species,
        completed: onboardingToEdit.completed,
        fishing_locations: onboardingToEdit.fishing_locations,
        deleted: onboardingToEdit.deleted, // If editable
        user: firebase.firestore().collection('users').doc(userId),
        updated_at: serverTimestamp(),
      };
      
      await onboardingRef.update(updatedData);
      
      // Update the original user's 'onboarded' status if email has changed
      const originalOnboarding = allOnboardings.find(o => o.id === onboardingToEdit.id);
      const originalUserEmail = originalOnboarding.user_email;
      
      if (originalUserEmail !== selectedUser.email) {
        // Set original user's onboarded to false
        const originalUserSnapshot = await firebase.firestore().collection('users').where('email', '==', originalUserEmail).limit(1).get();
        if (!originalUserSnapshot.empty) {
          const originalUserDoc = originalUserSnapshot.docs[0];
          await originalUserDoc.ref.update({
            onboarded: false,
            updated_at: serverTimestamp(),
          });
        }
        
        // Set new user's onboarded to true
        const newUserRef = firebase.firestore().collection('users').doc(userId);
        await newUserRef.update({
          onboarded: true,
          updated_at: serverTimestamp(),
        });
      }
      
      toast.success('Onboarding entry updated successfully!');
      toggleEditModal();
      fetchOnboardings();
    } catch (error) {
      console.error('Error updating onboarding:', error);
      toast.error('Could not update onboarding entry. Please try again.');
    } finally {
      setIsEditing(false);
    }
  };
  
  // ---------------------------
  // COMPONENT RETURN
  // ---------------------------
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Onboarding Q&A</h4>
                </CardHeader>

                <CardBody>
                  {/* Filter and Action Controls */}
                  <Row className="g-4 mb-3">
                    {/* Search Box */}
                    <Col className="d-flex align-items-start">
                      <Input
                        type="search"
                        name="search"
                        id="search"
                        placeholder="Search Onboardings..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </Col>

                    {/* Filter by Completed */}
                    <Col md="2">
                      <Input
                        type="select"
                        name="filterCompleted"
                        id="filterCompleted"
                        value={filterCompleted}
                        onChange={(e) => setFilterCompleted(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="true">Completed</option>
                        <option value="false">Not Completed</option>
                      </Input>
                    </Col>

                    {/* Filter by Start Date */}
                    <Col md="2">
                      <Flatpickr
                        value={startDate}
                        onChange={date => setStartDate(date[0] || null)}
                        options={{
                          dateFormat: "Y-m-d",
                          maxDate: endDate || null
                        }}
                        className="form-control"
                        placeholder="Start Date"
                      />
                    </Col>

                    {/* Filter by End Date */}
                    <Col md="2">
                      <Flatpickr
                        value={endDate}
                        onChange={date => setEndDate(date[0] || null)}
                        options={{
                          dateFormat: "Y-m-d",
                          minDate: startDate || null
                        }}
                        className="form-control"
                        placeholder="End Date"
                      />
                    </Col>

                    {/* Apply Filters Button */}
                    <Col md="1" className="d-flex align-items-end">
                      <Button
                        color="secondary"
                        onClick={fetchOnboardings}
                        title="Apply Filters"
                        style={{ width: '100%' }}
                      >
                        <i className="mdi mdi-filter"></i>
                      </Button>
                    </Col>

                    {/* Download CSV Button */}
                    <Col md="1" className="d-flex align-items-end">
                      <Button
                        color="primary"
                        onClick={handleDownloadCSV}
                        title="Download CSV"
                        style={{ width: '100%' }}
                      >
                        <i className="mdi mdi-download"></i>
                      </Button>
                    </Col>
                  </Row>
                  {/* end filter row */}

                  <div className="table-responsive table-card mt-3 mb-1">
                    {(!isDeleting && !isUploading) && (
                      <Table className="align-middle table-nowrap">
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}>View</th>
                            <th scope="col">User First Name</th>
                            <th scope="col">User Last Name</th>
                            <th scope="col">User Email</th>
                            <th scope="col">Submitted Date</th>
                            <th scope="col">Completed</th>
                            <th scope="col">Deleted</th>
                            <th scope="col">Years Fishing</th>
                            <th scope="col">Angling Platform</th>
                            <th scope="col">Monthly Fishing Times</th>
                            <th scope="col">Personal Best Catch</th>
                            <th scope="col">Target Species</th>
                            <th scope="col">Fishing Locations</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayedOnboardings.length > 0 ? displayedOnboardings.map((onboarding, index) => (
                            <tr key={onboarding.id}>
                              <td>
                                <a
                                  href={onboarding.link}
                                  className="btn btn-sm btn-success"
                                  title="View Onboarding Details"
                                >
                                  <i className="mdi mdi-eye-outline"></i>
                                </a>
                              </td>
                              <td>
                                {onboarding.user_first_name || 'N/A'}
                              </td>
                              <td>
                                {onboarding.user_last_name || 'N/A'}
                              </td>
                              <td>
                                <a
                                  href={`mailto:${onboarding.user_email}`}
                                  className="fw-medium link-primary"
                                  title="Send Email"
                                >
                                  {onboarding.user_email || 'N/A'}
                                </a>
                              </td>
                              <td>{onboarding.created}</td>
                              <td>
                                {onboarding.completed ? (
                                  <span className="badge badge-soft-success text-uppercase">Yes</span>
                                ) : (
                                  <span className="badge badge-soft-danger text-uppercase">No</span>
                                )}
                              </td>
                              <td>
                                {onboarding.deleted ? (
                                  <span className="badge badge-soft-danger text-uppercase">Yes</span>
                                ) : (
                                  <span className="badge badge-soft-success text-uppercase">No</span>
                                )}
                              </td>
                              <td>{onboarding.years_fishing || 'N/A'}</td>
                              <td>{onboarding.angling_platform || 'N/A'}</td>
                              <td>{onboarding.monthly_fishing_times || 'N/A'}</td>
                              <td>{onboarding.personal_best_catch || 'N/A'}</td>
                              <td>{onboarding.target_species || 'N/A'}</td>
                              <td>
                                {onboarding.fishing_locations && onboarding.fishing_locations.length > 0
                                  ? onboarding.fishing_locations.map((loc, idx) => `${loc.location} (${loc.option})`).join('; ')
                                  : 'N/A'}
                              </td>
                              <td>
                                <div className="d-flex gap-2">
                                  {/* Edit Button */}
                                  <Button
                                    color="info"
                                    size="sm"
                                    onClick={() => toggleEditModal(onboarding)}
                                    title="Edit Onboarding"
                                  >
                                    <i className="mdi mdi-pencil-outline"></i>
                                  </Button>
                                  {/* Delete Button */}
                                  <Button
                                    color="danger"
                                    size="sm"
                                    onClick={() => toggleDeleteModal(onboarding.id, index)}
                                    title="Delete Onboarding"
                                  >
                                    <i className="mdi mdi-delete-outline"></i>
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          )) : (
                            <tr>
                              <td colSpan="14" className="text-center">No Onboardings Found!</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    )}

                    {/* Loader */}
                    {(isDeleting || isUploading) && (
                      <div className="d-flex justify-content-center my-5">
                        <ThreeCircles
                          visible={isDeleting || isUploading}
                          height="100"
                          width="100"
                          color="#4192C3"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{ margin: "0 auto" }}
                          wrapperClass="justify-content-center"
                        />
                      </div>
                    )}

                    {/* No Results Message */}
                    {filteredOnboardings.length === 0 && !isDeleting && !isUploading && (
                      <div className="noresult">
                        <div className="text-center">
                          <h5 className="mt-2">No Onboardings Found!</h5>
                          <p className="text-muted mb-0">We could not find any onboardings matching your criteria.</p>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Pagination Controls */}
                  {pageCount > 1 && (
                    <div className="d-flex justify-content-end">
                      <ReactPaginate
                        previousLabel={"← Previous"}
                        nextLabel={"Next →"}
                        pageCount={pageCount}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        pageLinkClassName={"page-link"}
                        breakLabel={"..."}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                      />
                    </div>
                  )}
                  {/* End Pagination Controls */}

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>

      {/* Remove Modal */}
      <Modal
        isOpen={deleteModal}
        toggle={() => toggleDeleteModal()}
        className="modal zoomIn"
        id="deleteRecordModal"
        centered
      >
        {!isDeleting ? (
          <ModalBody>
            <div className="mt-2 text-center">
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Delete User's Onboarding Answers</h4>
                <p className="text-muted mx-4 mb-0">
                  Are you sure you want to delete these onboarding answers?
                </p>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
              <Button
                type="button"
                color="light"
                onClick={() => toggleDeleteModal()}
              >
                Cancel
              </Button>
              <Button
                type="button"
                color="danger"
                onClick={deleteOnboarding}
              >
                Delete
              </Button>
            </div>
          </ModalBody>
        ) : (
          <ModalBody>
            <div className="d-flex justify-content-center my-5">
              <ThreeCircles
                visible={isDeleting}
                height="100"
                width="100"
                color="#ff3d60"
                ariaLabel="three-circles-loading"
                wrapperStyle={{ margin: "0 auto" }}
                wrapperClass="justify-content-center"
              />
            </div>
          </ModalBody>
        )}
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={editModal}
        toggle={() => toggleEditModal()}
        className="modal zoomIn"
        id="editRecordModal"
        centered
      >
        <ModalHeader toggle={toggleEditModal}>Edit Onboarding Entry</ModalHeader>
        {!isEditing && onboardingToEdit ? (
          <ModalBody>
            <Form onSubmit={handleEditSubmit} className="mt-4">
              <Row>
                {/* User Email */}
                <Col md="6">
                  <FormGroup>
                    <Label for="user_email">User Email</Label>
                    <Input
                      type="select"
                      name="user_email"
                      id="user_email"
                      value={onboardingToEdit.user_email || ''}
                      onChange={(e) => {
                        const selectedEmail = e.target.value;
                        const selectedUser = users.find(user => user.email === selectedEmail);
                        if (selectedUser) {
                          setOnboardingToEdit({
                            ...onboardingToEdit,
                            user_email: selectedUser.email,
                            user_first_name: selectedUser.first_name,
                            user_last_name: selectedUser.last_name,
                            user: firebase.firestore().collection('users').doc(selectedUser.id),
                          });
                        }
                      }}
                      required
                      disabled={isUsersLoading}
                    >
                      <option value="">Select User Email</option>
                      {users.map(user => (
                        <option key={user.id} value={user.email}>
                          {user.email}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                {/* User First Name */}
                <Col md="6">
                  <FormGroup>
                    <Label for="user_first_name">User First Name</Label>
                    <Input
                      type="text"
                      name="user_first_name"
                      id="user_first_name"
                      placeholder="Enter first name"
                      value={onboardingToEdit.user_first_name || ''}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                {/* User Last Name */}
                <Col md="6">
                  <FormGroup>
                    <Label for="user_last_name">User Last Name</Label>
                    <Input
                      type="text"
                      name="user_last_name"
                      id="user_last_name"
                      placeholder="Enter last name"
                      value={onboardingToEdit.user_last_name || ''}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                {/* Completed */}
                <Col md="6">
                  <FormGroup>
                    <Label for="completed">Completed</Label>
                    <div>
                      <Input
                        type="checkbox"
                        name="completed"
                        id="completed"
                        checked={onboardingToEdit.completed || false}
                        onChange={handleEditChange}
                      />{' '}
                      {onboardingToEdit.completed ? 'Yes' : 'No'}
                    </div>
                  </FormGroup>
                </Col>
                {/* Deleted */}
                <Col md="6">
                  <FormGroup>
                    <Label for="deleted">Deleted</Label>
                    <div>
                      <Input
                        type="checkbox"
                        name="deleted"
                        id="deleted"
                        checked={onboardingToEdit.deleted || false}
                        onChange={handleEditChange}
                        disabled // Assuming 'deleted' is managed by the system
                      />{' '}
                      {onboardingToEdit.deleted ? 'Yes' : 'No'}
                    </div>
                  </FormGroup>
                </Col>
                {/* Years Fishing */}
                <Col md="6">
                  <FormGroup>
                    <Label for="years_fishing">Years Fishing</Label>
                    <Input
                      type="select"
                      name="years_fishing"
                      id="years_fishing"
                      value={onboardingToEdit.years_fishing || ''}
                      onChange={handleEditChange}
                      required
                    >
                      <option value="">Select Years Fishing</option>
                      <option value="1-5">1-5</option>
                      <option value="6-10">6-10</option>
                      <option value="10+">10+</option>
                    </Input>
                  </FormGroup>
                </Col>
                {/* Angling Platform */}
                <Col md="6">
                  <FormGroup>
                    <Label for="angling_platform">Angling Platform</Label>
                    <Input
                      type="select"
                      name="angling_platform"
                      id="angling_platform"
                      value={onboardingToEdit.angling_platform || ''}
                      onChange={handleEditChange}
                      required
                    >
                      <option value="">Select Angling Platform</option>
                      <option value="Land or Dock">Land or Dock</option>
                      <option value="Boat">Boat</option>
                      <option value="Kayak">Kayak</option>
                    </Input>
                  </FormGroup>
                </Col>
                {/* Monthly Fishing Times */}
                <Col md="6">
                  <FormGroup>
                    <Label for="monthly_fishing_times">Monthly Fishing Times</Label>
                    <Input
                      type="select"
                      name="monthly_fishing_times"
                      id="monthly_fishing_times"
                      value={onboardingToEdit.monthly_fishing_times || ''}
                      onChange={handleEditChange}
                      required
                    >
                      <option value="">Select Monthly Fishing Times</option>
                      <option value="1-2">1-2</option>
                      <option value="3-4">3-4</option>
                      <option value="4+">4+</option>
                    </Input>
                  </FormGroup>
                </Col>
                {/* Personal Best Catch */}
                <Col md="6">
                  <FormGroup>
                    <Label for="personal_best_catch">Personal Best Catch</Label>
                    <Input
                      type="text"
                      name="personal_best_catch"
                      id="personal_best_catch"
                      placeholder="Enter personal best catch"
                      value={onboardingToEdit.personal_best_catch || ''}
                      onChange={handleEditChange}
                    />
                  </FormGroup>
                </Col>
                {/* Target Species */}
                <Col md="6">
                  <FormGroup>
                    <Label for="target_species">Target Species</Label>
                    <Input
                      type="text"
                      name="target_species"
                      id="target_species"
                      placeholder="Enter target species"
                      value={onboardingToEdit.target_species || ''}
                      onChange={handleEditChange}
                    />
                  </FormGroup>
                </Col>
                {/* Fishing Locations */}
                <Col md="12">
                  <FormGroup>
                    <Label>Fishing Locations</Label>
                    {onboardingToEdit.fishing_locations && onboardingToEdit.fishing_locations.length > 0 ? (
                      onboardingToEdit.fishing_locations.map((loc, idx) => (
                        <Row key={idx} className="mb-2 align-items-center">
                          <Col md="10">
                            <Input
                              type="select"
                              name={`fishing_locations_${idx}`}
                              id={`fishing_locations_${idx}`}
                              value={loc.location || ''}
                              onChange={handleEditChange}
                              required
                            >
                              <option value="">Select Location</option>
                              <option value="Pond">Pond</option>
                              <option value="Lake">Lake</option>
                              <option value="Stream">Stream</option>
                              <option value="River">River</option>
                              <option value="Tidal Bay">Tidal Bay</option>
                              <option value="Ocean">Ocean</option>
                            </Input>
                          </Col>
                          <Col md="2">
                            <Button
                              color="danger"
                              onClick={() => handleRemoveFishingLocation(idx)}
                              title="Remove Location"
                            >
                              <i className="mdi mdi-delete-outline"></i>
                            </Button>
                          </Col>
                        </Row>
                      ))
                    ) : (
                      <p className="text-muted">No fishing locations added.</p>
                    )}
                    <Button color="secondary" onClick={handleAddFishingLocation}>
                      <i className="mdi mdi-plus"></i> Add Location
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex gap-2 justify-content-end">
                <Button
                  type="button"
                  color="light"
                  onClick={() => toggleEditModal()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                >
                  Save Changes
                </Button>
              </div>
            </Form>
          </ModalBody>
        ) : (
          <ModalBody>
            <div className="d-flex justify-content-center my-5">
              <ThreeCircles
                visible={isEditing}
                height="100"
                width="100"
                color="#4192C3"
                ariaLabel="three-circles-loading"
                wrapperStyle={{ margin: "0 auto" }}
                wrapperClass="justify-content-center"
              />
            </div>
          </ModalBody>
        )}
      </Modal>

      {/* Toast Container for Notifications */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
};

export default Onboardings;