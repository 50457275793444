import React, { useRef, useState, useEffect, useMemo } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    ModalHeader,
    Input,
    FormGroup,
    Label,
    Table,
} from 'reactstrap';
import ReactPaginate from 'react-paginate';
import { ThreeCircles } from 'react-loader-spinner';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import { serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, getStorage, ref } from "firebase/storage";

const TagPrograms = () => {

    const storage = getStorage();

    // ---------------------------
    // STATE VARIABLES
    // ---------------------------
    const [initialLoad, setInitialLoad] = useState(true);
    const [allTagPrograms, setAllTagPrograms] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isRestoring, setIsRestoring] = useState(false);
    const [fetchedData, setFetchedData] = useState(false);
    const [programImage, setProgramImage] = useState(null);
    const [updatedImage, setUpdatedImage] = useState(null);

    // Modal States
    const [modalList, setModalList] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [restoreModal, setRestoreModal] = useState(false);

    // Action Identifiers
    const [updateProgramId, setUpdateProgramId] = useState('');
    const [updateProgramIndex, setUpdateProgramIndex] = useState(null);
    const [deleteProgramId, setDeleteProgramId] = useState('');
    const [deleteProgramIndex, setDeleteProgramIndex] = useState(null);
    const [restoreProgramId, setRestoreProgramId] = useState('');
    const [restoreProgramIndex, setRestoreProgramIndex] = useState(null);

    // Search and Pagination States
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    // Reference for Firestore Listener
    const subscriptionRef = useRef(null);

    // ---------------------------
    // DOCUMENT TITLE
    // ---------------------------
    useEffect(() => {
        document.title = "Tag Programs | Fish-Trak - Internal Management Dashboard";
    }, []);

    // ---------------------------
    // FETCH ALL TAG PROGRAMS
    // ---------------------------
    const fetchTagPrograms = () => {
        // Unsubscribe previous listener if any
        if (subscriptionRef.current) {
            subscriptionRef.current();
        }

        const firestore = firebase.firestore();

        const query = firestore.collection('programs').orderBy('created_at', 'desc');

        const unsub = query.onSnapshot(
            (querySnapshot) => {
                const programsArray = [];
                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    programsArray.push({
                        id: doc.id,
                        program_name: data.program_name || '',
                        tag_length: data.tag_length || '',
                        program_region: data.program_region || '',
                        country_code: data.country_code || '',
                        program_type: data.program_type || '',
                        website_url: data.website_url || '',
                        email_address: data.email_address || '',
                        catch_alerts: data.catch_alerts || false,
                        recatch_alerts: data.recatch_alerts || false,
                        instructions: data.instructions || false,
                        questionnaire: data.questionnaire || false,
                        image_url: data.image_url || '',
                        created_at: data.created_at ? data.created_at.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) : '',
                        updated_at: data.updated_at ? data.updated_at.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) : '',
                        deleted: data.deleted || false,
                        link: `program/details/${doc.id}`,
                    });
                });
                setAllTagPrograms(programsArray);
                setFetchedData(true);
                // Reset modal states
                setModalList(false);
                setEditModal(false);
                setDeleteModal(false);
                setRestoreModal(false);
                setIsUploading(false);
                setIsUpdating(false);
                setIsDeleting(false);
                setIsRestoring(false);
            },
            (error) => {
                console.error("Error fetching tag programs:", error);
                toast.error("Error fetching tag programs.");
            }
        );

        subscriptionRef.current = unsub;
    };

    // ---------------------------
    // INITIAL DATA FETCHING
    // ---------------------------
    useEffect(() => {
        if (initialLoad) {
            setInitialLoad(false);
            fetchTagPrograms();
        }
    }, [initialLoad]);

    // ---------------------------
    // CLEANUP ON UNMOUNT
    // ---------------------------
    useEffect(() => {
        return () => {
            if (subscriptionRef.current) {
                subscriptionRef.current();
            }
        };
    }, []);

    // ---------------------------
    // SEARCH AND FILTER LOGIC
    // ---------------------------
    const filteredPrograms = useMemo(() => {
        return allTagPrograms.filter(program => {
            const matchesSearch =
                program.program_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                program.program_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
                program.program_region.toLowerCase().includes(searchQuery.toLowerCase()) ||
                program.country_code.toLowerCase().includes(searchQuery.toLowerCase());
            return matchesSearch;
        });
    }, [allTagPrograms, searchQuery]);

    // ---------------------------
    // PAGINATION CALCULATIONS
    // ---------------------------
    const pageCount = Math.ceil(filteredPrograms.length / itemsPerPage);
    const displayedPrograms = filteredPrograms.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    // ---------------------------
    // HANDLE PAGE CHANGE
    // ---------------------------
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // ---------------------------
    // HANDLE DOWNLOAD CSV
    // ---------------------------
    const handleDownloadCSV = () => {
        try {
            // Build CSV from allTagPrograms array
            const csvRows = [];
            // CSV header
            csvRows.push([
                'Program ID',
                'Program Name',
                'Tag Length',
                'Region',
                'Country',
                'Type',
                'Website URL',
                'Email Address',
                'Catch Alerts',
                'Recatch Alerts',
                'Instructions',
                'Questionnaire',
                'Created At',
                'Updated At',
                'Deleted?'
            ].join(','));

            // Add rows
            allTagPrograms.forEach(program => {
                const row = [
                    program.id,
                    `"${program.program_name.replace(/"/g, '""')}"`, // Escape quotes
                    program.tag_length,
                    `"${program.program_region.replace(/"/g, '""')}"`,
                    program.country_code,
                    program.program_type,
                    program.website_url,
                    program.email_address,
                    program.catch_alerts ? 'Yes' : 'No',
                    program.recatch_alerts ? 'Yes' : 'No',
                    program.instructions ? 'Yes' : 'No',
                    program.questionnaire ? 'Yes' : 'No',
                    program.created_at,
                    program.updated_at,
                    program.deleted ? 'Yes' : 'No'
                ];
                csvRows.push(row.join(','));
            });

            // Convert to Blob
            const csvString = csvRows.join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);

            // Trigger download
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "tag_programs.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            toast.success("CSV downloaded successfully.");
        } catch (error) {
            console.error('Error generating CSV:', error);
            toast.error('Could not download CSV. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE CREATE PROGRAM
    // ---------------------------
    const submitCreateForm = async (event) => {
        event.preventDefault();
        setIsUploading(true);

        const form = event.target;
        const programName = form.program_name.value.trim();
        const tagLength = form.tag_length.value.trim();
        const programRegion = form.program_region.value.trim();
        const countryCode = form.program_country.value;
        const programType = form.program_type.value;
        const websiteURL = form.program_website.value.trim();
        const emailAddress = form.email_address.value.trim();
        const catchAlerts = form.catch_alerts.checked;
        const recatchAlerts = form.recatch_alerts.checked;
        const instructions = form.instructions.checked;
        const questionnaire = form.questionnaire.checked;
        const imageFile = form.image_link.files[0];

        // Validation (additional if needed)
        if (!imageFile) {
            toast.error('Image file is required.');
            setIsUploading(false);
            return;
        }

        // Upload Image to Firebase Storage
        const imageFileName = `program-logos/${programName.replace(/[^0-9a-zA-Z-]/g, '-')}-${Date.now()}`;
        const storageRef = ref(storage, imageFileName);
        const uploadTask = uploadBytesResumable(storageRef, imageFile);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Optional: Implement progress tracking if needed
            },
            (error) => {
                console.error("Error uploading image:", error);
                toast.error('Could not upload image. Please try again.');
                setIsUploading(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadedURL) => {
                    // Create new program in Firestore
                    const programData = {
                        program_name: programName,
                        tag_length: tagLength,
                        program_region: programRegion,
                        country_code: countryCode,
                        program_type: programType,
                        website_url: websiteURL,
                        email_address: emailAddress,
                        catch_alerts: catchAlerts,
                        recatch_alerts: recatchAlerts,
                        instructions: instructions,
                        questionnaire: questionnaire,
                        image_url: downloadedURL,
                        created_at: serverTimestamp(),
                        updated_at: serverTimestamp(),
                        deleted: false,
                    };

                    try {
                        await firebase.firestore().collection('programs').add(programData);
                        toast.success('New program added successfully!');
                        setIsUploading(false);
                        setProgramImage(null);
                        setModalList(false);
                        fetchTagPrograms();
                    } catch (error) {
                        console.error("Error adding program:", error);
                        toast.error('Could not add program. Please try again.');
                        setIsUploading(false);
                    }
                });
            }
        );
    };

    // ---------------------------
    // HANDLE UPDATE PROGRAM
    // ---------------------------
    const submitUpdateForm = async (event) => {
        event.preventDefault();
        setIsUpdating(true);
        setIsUploading(true);

        const form = event.target;
        const programName = form.program_name.value.trim();
        const tagLength = form.tag_length.value.trim();
        const programRegion = form.program_region.value.trim();
        const countryCode = form.program_country.value;
        const programType = form.program_type.value;
        const websiteURL = form.program_website.value.trim();
        const emailAddress = form.email_address.value.trim();
        const catchAlerts = form.catch_alerts.checked;
        const recatchAlerts = form.recatch_alerts.checked;
        const instructions = form.instructions.checked;
        const questionnaire = form.questionnaire.checked;
        const imageFile = form.image_file.files[0];

        const programId = updateProgramId;
        const programRef = firebase.firestore().collection('programs').doc(programId);

        let updatedData = {
            program_name: programName,
            tag_length: tagLength,
            program_region: programRegion,
            country_code: countryCode,
            program_type: programType,
            website_url: websiteURL,
            email_address: emailAddress,
            catch_alerts: catchAlerts,
            recatch_alerts: recatchAlerts,
            instructions: instructions,
            questionnaire: questionnaire,
            updated_at: serverTimestamp(),
        };

        if (imageFile) {
            // Upload new image
            const imageFileName = `program-logos/${programName.replace(/[^0-9a-zA-Z-]/g, '-')}-${Date.now()}`;
            const storageRef = ref(storage, imageFileName);
            const uploadTask = uploadBytesResumable(storageRef, imageFile);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // Optional: Implement progress tracking if needed
                },
                (error) => {
                    console.error("Error uploading image:", error);
                    toast.error('Could not upload image. Please try again.');
                    setIsUpdating(false);
                    setIsUploading(false);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadedURL) => {
                        updatedData.image_url = downloadedURL;

                        try {
                            await programRef.update(updatedData);
                            toast.success('Program updated successfully!');
                            setIsUpdating(false);
                            setIsUploading(false);
                            setUpdatedImage(null);
                            setEditModal(false);
                            fetchTagPrograms();
                        } catch (error) {
                            console.error("Error updating program:", error);
                            toast.error('Could not update program. Please try again.');
                            setIsUpdating(false);
                            setIsUploading(false);
                        }
                    });
                }
            );
        } else {
            // No image update
            try {
                await programRef.update(updatedData);
                toast.success('Program updated successfully!');
                setIsUpdating(false);
                setIsUploading(false);
                setEditModal(false);
                fetchTagPrograms();
            } catch (error) {
                console.error("Error updating program:", error);
                toast.error('Could not update program. Please try again.');
                setIsUpdating(false);
                setIsUploading(false);
            }
        }
    };

    // ---------------------------
    // HANDLE DELETE PROGRAM
    // ---------------------------
    const deleteProgram = async () => {
        setIsDeleting(true);

        const programRef = firebase.firestore().collection('programs').doc(deleteProgramId);

        try {
            await programRef.update({
                deleted: true,
                updated_at: serverTimestamp(),
            });
            toast.success('Program deleted successfully!');
            setIsDeleting(false);
            setDeleteProgramId('');
            setDeleteProgramIndex(null);
            setDeleteModal(false);
            fetchTagPrograms();
        } catch (error) {
            console.error("Error deleting program:", error);
            toast.error('Could not delete program. Please try again.');
            setIsDeleting(false);
        }
    };

    // ---------------------------
    // HANDLE RESTORE PROGRAM
    // ---------------------------
    const restoreProgram = async () => {
        setIsRestoring(true);

        const programRef = firebase.firestore().collection('programs').doc(restoreProgramId);

        try {
            await programRef.update({
                deleted: false,
                updated_at: serverTimestamp(),
            });
            toast.success('Program restored successfully!');
            setIsRestoring(false);
            setRestoreProgramId('');
            setRestoreProgramIndex(null);
            setRestoreModal(false);
            fetchTagPrograms();
        } catch (error) {
            console.error("Error restoring program:", error);
            toast.error('Could not restore program. Please try again.');
            setIsRestoring(false);
        }
    };

    // ---------------------------
    // HANDLE DOWNLOAD CSV
    // ---------------------------
    // Ensure handleDownloadCSV is defined only once

    // ---------------------------
    // COMPONENT RETURN
    // ---------------------------
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Tag Programs</h4>
                                </CardHeader>

                                <CardBody>
                                    {/* Filter and Action Controls */}
                                    <Row className="g-4 mb-3">
                                        {/* Add Program Button */}
                                        <Col sm="auto">
                                            <div className="d-flex gap-1">
                                                <Button
                                                    color="success"
                                                    onClick={() => setModalList(true)}
                                                    id="create-btn"
                                                >
                                                    <i className="ri-add-line align-bottom me-1"></i> Add Program
                                                </Button>
                                            </div>
                                        </Col>
                                        {/* Search Box */}
                                        <Col className="d-flex align-items-start">
                                            <Input
                                                type="search"
                                                name="search"
                                                id="search"
                                                placeholder="Search Programs..."
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        </Col>
                                        {/* Download CSV Button */}
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button
                                                color="primary"
                                                onClick={handleDownloadCSV}
                                                title="Download CSV"
                                                style={{ width: '100%' }}
                                            >
                                                <i className="mdi mdi-download"></i>
                                            </Button>
                                        </Col>
                                    </Row>

                                    {/* Programs Table */}
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        {(!isUpdating && !isDeleting && !isRestoring && !isUploading) && (
                                            <Table className="align-middle table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">Program Name</th>
                                                        <th scope="col">Region</th>
                                                        <th scope="col">Country</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Tag Length</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {displayedPrograms.length > 0 ? displayedPrograms.map((program, index) => (
                                                        <tr key={program.id}>
                                                            <td>
                                                                <a href={program.link} className="btn btn-sm btn-success">
                                                                    <i className="mdi mdi-eye-outline"></i>
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {program.image_url ? (
                                                                    <img
                                                                        className="avatar-sm rounded"
                                                                        alt={program.program_name}
                                                                        src={program.image_url}
                                                                    />
                                                                ) : (
                                                                    <span>No Image</span>
                                                                )}
                                                            </td>
                                                            <td>{program.program_name}</td>
                                                            <td>{program.program_region}</td>
                                                            <td>{program.country_code}</td>
                                                            <td>{program.program_type}</td>
                                                            <td>{program.tag_length}</td>
                                                            <td>
                                                                {program.deleted ? (
                                                                    <span className="badge badge-soft-danger text-uppercase">Deleted</span>
                                                                ) : (
                                                                    <span className="badge badge-soft-success text-uppercase">Active</span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    {/* Edit Button */}
                                                                    <Button
                                                                        color="warning"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            setUpdateProgramId(program.id);
                                                                            setUpdateProgramIndex(index);
                                                                            setEditModal(true);
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-square-edit-outline"></i>
                                                                    </Button>
                                                                    {/* Delete or Restore Buttons */}
                                                                    {!program.deleted ? (
                                                                        <Button
                                                                            color="danger"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                setDeleteProgramId(program.id);
                                                                                setDeleteProgramIndex(index);
                                                                                setDeleteModal(true);
                                                                            }}
                                                                        >
                                                                            <i className="mdi mdi-delete-outline"></i>
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            color="success"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                setRestoreProgramId(program.id);
                                                                                setRestoreProgramIndex(index);
                                                                                setRestoreModal(true);
                                                                            }}
                                                                        >
                                                                            <i className="mdi mdi-history"></i>
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )) : (
                                                        <tr>
                                                            <td colSpan="9" className="text-center">No Tag Programs Found!</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        )}

                                        {/* Loader */}
                                        {(isUpdating || isDeleting || isRestoring || isUploading) && (
                                            <div className="d-flex justify-content-center my-5">
                                                <ThreeCircles
                                                    visible={isUpdating || isDeleting || isRestoring || isUploading}
                                                    height="100"
                                                    width="100"
                                                    color="#4192C3"
                                                    ariaLabel="three-circles-loading"
                                                    wrapperStyle={{ margin: "0 auto" }}
                                                    wrapperClass="justify-content-center"
                                                />
                                            </div>
                                        )}

                                        {/* No Results Message */}
                                        {filteredPrograms.length === 0 && (
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Tag Programs Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any tag programs.</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Pagination Controls */}
                                    {pageCount > 1 && (
                                        <div className="d-flex justify-content-end">
                                            <ReactPaginate
                                                previousLabel={"← Previous"}
                                                nextLabel={"Next →"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageChange}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"page-link"}
                                                nextLinkClassName={"page-link"}
                                                disabledClassName={"disabled"}
                                                activeClassName={"active"}
                                                pageLinkClassName={"page-link"}
                                                breakLabel={"..."}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* --------------------------- */}
            {/* Modals */}
            {/* --------------------------- */}

            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={() => setEditModal(!editModal)} centered>
                <form className="tablelist-form" onSubmit={submitUpdateForm}>
                    <ModalHeader toggle={() => setEditModal(!editModal)}>Edit Tag Program</ModalHeader>
                    <ModalBody>
                        {!isUpdating && allTagPrograms[updateProgramIndex] && (
                            <>
                                <FormGroup>
                                    <Label for="program_name">Program Name*</Label>
                                    <Input
                                        type="text"
                                        name="program_name"
                                        id="program_name"
                                        className="form-control"
                                        placeholder="Fish-Trak Tagging Program"
                                        required
                                        defaultValue={allTagPrograms[updateProgramIndex].program_name}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="tag_length">Tag Length*</Label>
                                    <Input
                                        type="number"
                                        name="tag_length"
                                        id="tag_length"
                                        className="form-control"
                                        placeholder="Max: 15"
                                        required
                                        defaultValue={allTagPrograms[updateProgramIndex].tag_length}
                                        min="1"
                                        max="99"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="program_region">Program Region*</Label>
                                    <Input
                                        type="text"
                                        name="program_region"
                                        id="program_region"
                                        className="form-control"
                                        placeholder="Kentucky"
                                        required
                                        defaultValue={allTagPrograms[updateProgramIndex].program_region}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="program_country">Program Country*</Label>
                                    <Input
                                        type="select"
                                        name="program_country"
                                        id="program_country"
                                        className="form-control"
                                        required
                                        defaultValue={allTagPrograms[updateProgramIndex].country_code}
                                    >
                                        <option value="">Select Program Country</option>
                                        <option value="USA">United States</option>
                                        <option value="CAN">Canada</option>
                                        {/* Add more countries as needed */}
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="program_type">Program Type*</Label>
                                    <Input
                                        type="select"
                                        name="program_type"
                                        id="program_type"
                                        className="form-control"
                                        required
                                        defaultValue={allTagPrograms[updateProgramIndex].program_type}
                                    >
                                        <option value="">Select Program Type</option>
                                        <option value="Private">Private</option>
                                        <option value="Public">Public</option>
                                        <option value="University">University</option>
                                        <option value="Government">Government</option>
                                        <option value="State">State</option>
                                        <option value="Federal">Federal</option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="program_website">Program Website*</Label>
                                    <Input
                                        type="url"
                                        name="program_website"
                                        id="program_website"
                                        className="form-control"
                                        placeholder="https://fish-trak.com"
                                        required
                                        defaultValue={allTagPrograms[updateProgramIndex].website_url}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="email_address">Notification Email*</Label>
                                    <Input
                                        type="email"
                                        name="email_address"
                                        id="email_address"
                                        className="form-control"
                                        placeholder="alerts@fish-trak.com"
                                        required
                                        defaultValue={allTagPrograms[updateProgramIndex].email_address}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Usage Notifications*</Label>
                                    <tr>
                                        <td>
                                            <div className="form-check">
                                                <Input
                                                    type="checkbox"
                                                    name="catch_alerts"
                                                    id="catch_alerts"
                                                    defaultChecked={allTagPrograms[updateProgramIndex].catch_alerts}
                                                />
                                                <Label for="catch_alerts" className="form-check-label">
                                                    Catch Alerts
                                                </Label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check" style={{marginLeft: 20}}>
                                                <Input
                                                    type="checkbox"
                                                    name="recatch_alerts"
                                                    id="recatch_alerts"
                                                    defaultChecked={allTagPrograms[updateProgramIndex].recatch_alerts}
                                                />
                                                <Label for="recatch_alerts" className="form-check-label">
                                                    Recatch Alerts
                                                </Label>
                                            </div>
                                        </td>
                                    </tr>
                                    
                                </FormGroup>

                                <FormGroup>
                                    <Label>Additional Data*</Label>
                                    <tr>
                                        <td>
                                            <div className="form-check">
                                                <Input
                                                    type="checkbox"
                                                    name="instructions"
                                                    id="instructions"
                                                    defaultChecked={allTagPrograms[updateProgramIndex].instructions}
                                                />
                                                <Label for="instructions" className="form-check-label">
                                                    Instructions
                                                </Label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check" style={{marginLeft: 20}}>
                                                <Input
                                                    type="checkbox"
                                                    name="questionnaire"
                                                    id="questionnaire"
                                                    defaultChecked={allTagPrograms[updateProgramIndex].questionnaire}
                                                />
                                                <Label for="questionnaire" className="form-check-label">
                                                    Questionnaire
                                                </Label>
                                            </div>
                                        </td>
                                    </tr>
                                    
                                </FormGroup>

                                <FormGroup>
                                    <Label for="image_file">Image File</Label>
                                    
                                    <tr>
                                      <td>
                                        {allTagPrograms[updateProgramIndex].image_url && (
                                            <img
                                                className="avatar-sm rounded"
                                                alt={allTagPrograms[updateProgramIndex].program_name}
                                                src={allTagPrograms[updateProgramIndex].image_url}
                                            />
                                        )}
                                      </td>
                                      <td>
                                        <Input
                                            type="file"
                                            name="image_file"
                                            id="image_file"
                                            className="form-control"
                                            onChange={(e) => { setUpdatedImage(e.target.files[0]); }}
                                            accept="image/*"
                                            style={{marginLeft: 20, width: "100%"}}
                                        />
                                      </td>
                                    </tr>
                                    
                                    
                                </FormGroup>

                                
                            </>
                        )}
                        {isUpdating && (
                            <div className="d-flex justify-content-center my-5">
                                <ThreeCircles
                                    visible={isUpdating}
                                    height="100"
                                    width="100"
                                    color="#4192C3"
                                    ariaLabel="three-circles-loading"
                                    wrapperStyle={{ margin: "0 auto" }}
                                    wrapperClass="justify-content-center"
                                />
                            </div>
                        )}
                    </ModalBody>
                    {!isUpdating && (
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => setEditModal(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="success"
                                    onClick={submitUpdateForm}
                                >
                                    Update
                                </Button>
                            </div>
                        </ModalFooter>
                    )}
                </form>
            </Modal>

            {/* Add Program Modal */}
            <Modal isOpen={modalList} toggle={() => setModalList(!modalList)} centered>
                <form className="tablelist-form" onSubmit={submitCreateForm}>
                    <ModalHeader toggle={() => setModalList(!modalList)}>Add Tag Program</ModalHeader>
                    <ModalBody>
                        {!isUploading && (
                            <>
                                <FormGroup>
                                    <Label for="program_name">Program Name*</Label>
                                    <Input
                                        type="text"
                                        name="program_name"
                                        id="program_name"
                                        className="form-control"
                                        placeholder="Fish-Trak Tagging Program"
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="tag_length">Tag Length*</Label>
                                    <Input
                                        type="number"
                                        name="tag_length"
                                        id="tag_length"
                                        className="form-control"
                                        placeholder="Max: 15"
                                        required
                                        min="1"
                                        max="99"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="program_region">Program Region*</Label>
                                    <Input
                                        type="text"
                                        name="program_region"
                                        id="program_region"
                                        className="form-control"
                                        placeholder="Kentucky"
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="program_country">Program Country*</Label>
                                    <Input
                                        type="select"
                                        name="program_country"
                                        id="program_country"
                                        className="form-control"
                                        required
                                    >
                                        <option value="">Select Program Country</option>
                                        <option value="USA">United States</option>
                                        <option value="CAN">Canada</option>
                                        {/* Add more countries as needed */}
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="program_type">Program Type*</Label>
                                    <Input
                                        type="select"
                                        name="program_type"
                                        id="program_type"
                                        className="form-control"
                                        required
                                    >
                                        <option value="">Select Program Type</option>
                                        <option value="Private">Private</option>
                                        <option value="Public">Public</option>
                                        <option value="University">University</option>
                                        <option value="Government">Government</option>
                                        <option value="State">State</option>
                                        <option value="Federal">Federal</option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="program_website">Program Website*</Label>
                                    <Input
                                        type="url"
                                        name="program_website"
                                        id="program_website"
                                        className="form-control"
                                        placeholder="https://fish-trak.com"
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="email_address">Notification Email*</Label>
                                    <Input
                                        type="email"
                                        name="email_address"
                                        id="email_address"
                                        className="form-control"
                                        placeholder="alerts@fish-trak.com"
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Usage Notifications*</Label>
                                    <div className="form-check">
                                        <Input
                                            type="checkbox"
                                            name="catch_alerts"
                                            id="catch_alerts"
                                        />
                                        <Label for="catch_alerts" className="form-check-label">
                                            Catch Alerts
                                        </Label>
                                    </div>
                                    <div className="form-check">
                                        <Input
                                            type="checkbox"
                                            name="recatch_alerts"
                                            id="recatch_alerts"
                                        />
                                        <Label for="recatch_alerts" className="form-check-label">
                                            Recatch Alerts
                                        </Label>
                                    </div>
                                </FormGroup>

                                <FormGroup>
                                    <Label>Additional Data*</Label>
                                    <div className="form-check">
                                        <Input
                                            type="checkbox"
                                            name="instructions"
                                            id="instructions"
                                        />
                                        <Label for="instructions" className="form-check-label">
                                            Instructions
                                        </Label>
                                    </div>
                                    <div className="form-check">
                                        <Input
                                            type="checkbox"
                                            name="questionnaire"
                                            id="questionnaire"
                                        />
                                        <Label for="questionnaire" className="form-check-label">
                                            Questionnaire
                                        </Label>
                                    </div>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="image_link">Image File*</Label>
                                    <Input
                                        type="file"
                                        name="image_link"
                                        id="image_link"
                                        className="form-control"
                                        accept="image/*"
                                        required
                                        onChange={(e) => { setProgramImage(e.target.files[0]); }}
                                    />
                                </FormGroup>
                            </>
                        )}
                        {isUploading && (
                            <div className="d-flex justify-content-center my-5">
                                <ThreeCircles
                                    visible={isUploading}
                                    height="100"
                                    width="100"
                                    color="#4192C3"
                                    ariaLabel="three-circles-loading"
                                    wrapperStyle={{ margin: "0 auto" }}
                                    wrapperClass="justify-content-center"
                                />
                            </div>
                        )}
                    </ModalBody>
                    {!isUploading && (
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => setModalList(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={isUploading}
                                >
                                    Add Program
                                </Button>
                            </div>
                        </ModalFooter>
                    )}
                </form>
            </Modal>

            {/* Delete Program Modal */}
            <Modal
                isOpen={deleteModal}
                toggle={() => setDeleteModal(!deleteModal)}
                centered
            >
                {!isDeleting ? (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Delete Tag Program</h4>
                                <p className="text-muted mx-4 mb-0">Are you sure you want to delete this program?</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={() => setDeleteModal(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                onClick={deleteProgram}
                            >
                                Delete
                            </Button>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isDeleting}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Restore Program Modal */}
            <Modal
                isOpen={restoreModal}
                toggle={() => setRestoreModal(!restoreModal)}
                centered
            >
                {!isRestoring ? (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Restore Tag Program</h4>
                                <p className="text-muted mx-4 mb-0">Do you really want to restore this program?</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={() => setRestoreModal(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="success"
                                onClick={restoreProgram}
                            >
                                Restore
                            </Button>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isRestoring}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Toast Container for Notifications */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </React.Fragment>
    );

};

export default TagPrograms;