import React, { useRef, useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, CardHeader } from 'reactstrap';

import { LatestTransationData } from '../../CommonData/Data/index';
import { ThreeCircles } from 'react-loader-spinner'
import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";

const LatestRegistrations = () => {
    
    const [fetchingUsers, setFetchingUsers] = useState(true);
    const [latestUsers, setLatestUsers] = useState([]);
    
    const fetchLatestUsers = async () => {
          
     firebase
     .firestore()
     .collection('users')
     .orderBy('created_at', 'desc')
     .limit(5)
     .onSnapshot(
          querySnapshot => {
              const newLatestUsers = []
              querySnapshot.forEach(doc => {
                  const userItem = doc.data();
                  userItem.id = doc.id;
                  userItem.link = '../user/details/' + userItem.id;  
                  userItem.date = userItem && userItem.created_at && userItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  
                  
                  userItem.mailtoLink = 'mailto:' + userItem.email_address + '?subject=Fish-Trak Admin';
                  
                  newLatestUsers.push(userItem);
              });
              setLatestUsers(newLatestUsers);
              setFetchingUsers(false);
          },
          error => {
              console.log(error)
          }
      )
    };
    
    //Update on load of data
    useEffect(() => {
        fetchLatestUsers();
    }, []);
    
    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            <CardTitle>Latest Registrations</CardTitle>
                        </CardHeader>

                        <CardBody>
                        
                            <ThreeCircles
                            visible={fetchingUsers}
                            height="100"
                            width="100"
                            color="#4192C3"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{style:"margin: 0px auto"}}
                            wrapperClass="justify-content-center"
                            />
                            
                            {!fetchingUsers &&
                        
                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">

                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">User Information</th>
                                            <th scope="col">Registered</th>
                                            <th scope="col">Mobile Number</th>
                                            <th scope="col">Catches</th>
                                            <th scope="col">Recatches</th>
                                            <th scope="col">Home Port</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {latestUsers.map((user, key) => (<tr key={key}>
                                            <td>
                                                {user.image_url ? <img src={user.image_url} alt="user" className="avatar-xs rounded-circle" /> : <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                        {user.first_name.charAt(0)}{user.last_name.charAt(0)}
                                                    </span>
                                                </div>}
                                            </td>
                                            <td>
                                                <p className="mb-1 font-size-12"><a href={user.mailtoLink} class="email-link">{user.email_address}</a></p>
                                                <h5 className="font-size-15 mb-0">{user.first_name} {user.last_name}</h5>
                                            </td>
                                            <td>{user.date}</td>
                                            <td>{user.mobile_number}</td>
                                            <td>{user.catch_count}</td>
                                            <td>{user.recatch_count}</td>
                                            <td>{user.home_port}</td>
                                            <td>
                                                {user.active &&<i className={"mdi mdi-checkbox-blank-circle me-1 text-success"}></i>}
                                                {!user.active &&<i className={"mdi mdi-checkbox-blank-circle me-1 text-danger"}></i>}
                                            </td>
                                            <td>
                                                <a href={user.link} className="btn btn-outline-success btn-sm me-1"><i className="mdi mdi-eye-outline"></i></a>
                                            </td>
                                        </tr>))}
                                    </tbody>
                                </table>
                            </div>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default LatestRegistrations;