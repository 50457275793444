import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Calender
// import Calender from "../Pages/Calender";


// Import E-mail
// import Inbox from "../Pages/E-mail/Inbox";
// import ReadEmail from "../Pages/E-mail/ReadEmail";
// import EmailCompose from "../Pages/E-mail/EmailCompose";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";

// Import Authentication Inner Pages
import Login1 from "../Pages/AuthenticationPages/Login";
import Register1 from "../Pages/AuthenticationPages/Register";
import RecoverPassword from "../Pages/AuthenticationPages/RecoverPassword";
import LockScreen from "../Pages/AuthenticationPages/LockScreen";

// Import Utility Pages
// import StarterPage from "../Pages/Utility/Starter-Page";
// import Maintenance from "../Pages/Utility/Maintenance-Page";
// import ComingSoon from "../Pages/Utility/ComingSoon-Page";
// import TimeLine from "../Pages/Utility/TimeLine-Page";
// import FAQs from "../Pages/Utility/FAQs-Page";
// import Pricing from "../Pages/Utility/Pricing-Page";
// import Error404 from "../Pages/Utility/Error404-Page";
// import Error500 from "../Pages/Utility/Error500-Page";

// Import UIElement Pages
// import UiAlerts from "../Pages/UiElements/UiAlerts";
// import UiBadge from "../Pages/UiElements/UiBadge";
// import UiBreadcrumb from "../Pages/UiElements/UiBreadcrumb";
// import UiButtons from "../Pages/UiElements/UiButtons";
// import UiCards from "../Pages/UiElements/UiCards";
// import UiCarousel from "../Pages/UiElements/UiCarousel";
// import UiDropdown from "../Pages/UiElements/UiDropdowns";
// import UiGrid from "../Pages/UiElements/UiGrid";
// import UiImages from "../Pages/UiElements/UiImages";
// import UiLightbox from "../Pages/UiElements/UiLightbox";
// import UiModals from "../Pages/UiElements/UiModals";
// import UiOffcanvas from "../Pages/UiElements/UiOffcanvas";
// import UiRangeSlider from "../Pages/UiElements/UiRangeSlider";
// import UiSessionTimeout from "../Pages/UiElements/UiSessionTimeout";
// import UiPagination from "../Pages/UiElements/UiPagination";
// import UiProgressBars from "../Pages/UiElements/UiProgressBars";
// import UiPlaceholders from "../Pages/UiElements/UiPlaceholders";
// import UiTabs from "../Pages/UiElements/UiTabs&Accordions";
// import UiTypography from "../Pages/UiElements/UiTypography";
// import UiToasts from "../Pages/UiElements/UiToasts";
// import UiVideo from "../Pages/UiElements/UiVideo";
// import UiPopovers from "../Pages/UiElements/UiPopovers&Tooltips";
// import UiRating from "../Pages/UiElements/UiRating";

// Import Forms
// import FormEditors from "../Pages/Forms/FormEditors";
// import FormUpload from "../Pages/Forms/FormUpload";
// import FormXeditable from "../Pages/Forms/FormXeditable";
// import FormMask from "../Pages/Forms/FormMask";
// import FormElements from "../Pages/Forms/FormElements";
// import FormAdvanced from "../Pages/Forms/FormAdvanced";
// import FormValidations from "../Pages/Forms/FormValidations";
// import FormWizard from "../Pages/Forms/FormWizard";

// Import Tables
// import BasicTable from "../Pages/Tables/BasicTable.js";
// import ListJs from "../Pages/Tables/ListTables/ListTables";
// import DataTable from "../Pages/Tables/DataTables/DataTables";


// Import Charts
// import ApexCharts from "../Pages/Charts/ApexCharts";
// import ChartJs from "../Pages/Charts/ChartjsCharts";
// import Sparklinechart from "../Pages/Charts/SparklineCharts";
// import FloatChart from "../Pages/Charts/FloatCharts";
// import JknobCharts from "../Pages/Charts/JqueryKnobCharts";

// Import Icon Pages
// import IconMaterialdesign from "../Pages/Icons/IconMaterialdesign";
// import IconFontawesome from "../Pages/Icons/IconFontAwesome";
// import IconDripicons from "../Pages/Icons/IconDrip";
// import IconBoxicons from "../Pages/Icons/IconBoxicons"

// Import Map Pages
// import VectorMaps from "../Pages/Maps/VectorMap";
// import GoogleMap from "../Pages/Maps/GoogleMap";



// Import Catch Ledger
import AllCatches from "../Pages/CatchLedger/AllCatches";
import NewCatches from "../Pages/CatchLedger/NewCatches";
import ReCatches from "../Pages/CatchLedger/ReCatches";

// Import Fish Species
import FishSpecies from "../Pages/FishSpecies";

// Import Tagging Programs
import TagPrograms from "../Pages/TagPrograms";

// Import Fish Tags
import AllTags from "../Pages/FishTags/AllTags";
import UsedTages from "../Pages/FishTags/UsedTags";
import UnusedTags from "../Pages/FishTags/UnusedTags";

// Import Registrations
import AllUsers from "../Pages/Registrations/AllUsers";
import ActiveUsers from "../Pages/Registrations/ActiveUsers";
import DisabledUsers from "../Pages/Registrations/DisabledUsers";

//Import Onboardings
import Onboardings from "../Pages/Onboardings";

//Import Details
import UserDetails from "../Pages/Details/UserDetails";
import CatchDetails from "../Pages/Details/CatchDetails";
import TagDetails from "../Pages/Details/TagDetails";
import ProgramDetails from "../Pages/Details/ProgramDetails";
import SpeciesDetails from "../Pages/Details/SpeciesDetails";
import OnboardingDetails from "../Pages/Details/OnboardingDetails";

// Import Notifications
// import SendNew from "../Pages/Notifications/SendNew";
// import Historical from "../Pages/Notifications/Historical";


const authProtectedRoutes = [
  
  // Profile
  { path: "/user-profile", component: <UserProfile /> },

  //Catch Ledger
  { path: "/all-catches", component: <AllCatches /> },
  { path: "/new-catches", component: <NewCatches /> },
  { path: "/re-catches", component: <ReCatches /> },
  
  //Fish Species
  { path: "/fish-species", component: <FishSpecies /> },
  
  //Fish Species
  { path: "/tag-programs", component: <TagPrograms /> },
  
  //Catch Ledger
  { path: "/all-tags", component: <AllTags /> },
  { path: "/used-tags", component: <UsedTages /> },
  { path: "/unused-tags", component: <UnusedTags /> },
  
  //Catch Ledger
  { path: "/all-users", component: <AllUsers /> },
  { path: "/active-users", component: <ActiveUsers /> },
  { path: "/disabled-users", component: <DisabledUsers /> },
  
  //Onboardings
  { path: "/onboardings", component: <Onboardings /> },
  
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },
  
  //Details
  { path: "/user/details/:userId", component: <UserDetails /> },
  { path: "/catch/details/:catchId", component: <CatchDetails /> },
  { path: "/tag/details/:tagId", component: <TagDetails /> },
  { path: "/program/details/:programId", component: <ProgramDetails /> },
  { path: "/species/details/:speciesId", component: <SpeciesDetails /> },
  { path: "/onboarding/details/:onboardingId", component: <OnboardingDetails /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },

  // Authentication Inner Pages
  { path: "/auth-login", component: <Login1 /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },

];

export { authProtectedRoutes, publicRoutes };
