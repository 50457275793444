// NewCatches.jsx

import React, { useRef, useState, useEffect, useMemo } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    ModalHeader,
    Input,
    FormGroup,
    Label
} from 'reactstrap';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import { serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, getStorage, ref } from "firebase/storage";

const NewCatches = () => {

    const storage = getStorage();

    // ---------------------------
    // STATE VARIABLES
    // ---------------------------
    const [initialLoad, setInitialLoad] = useState(true);
    const [newCatchesLedger, setNewCatchesLedger] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [allSpecies, setAllSpecies] = useState([]);
    const [allTagPrograms, setAllTagPrograms] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);

    // Action States
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateCatchId, setUpdateCatchId] = useState('');
    const [updateCatchIndex, setUpdateCatchIndex] = useState(null);
    const [updateTagProgramId, setUpdateTagProgramId] = useState('');
    const [deleteCatchId, setDeleteCatchId] = useState('');
    const [deleteCatchIndex, setDeleteCatchIndex] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isRestoringCatch, setIsRestoringCatch] = useState(false);
    const [restoreCatchId, setRestoreCatchId] = useState('');
    const [restoreCatchIndex, setRestoreCatchIndex] = useState(null);
    const [updatedImage, setUpdatedImage] = useState(null);

    // Modal States
    const [modalList, setModalList] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [restoreCatchModal, setRestoreCatchModal] = useState(false);

    // Filter and Search States
    const [selectedSpeciesFilter, setSelectedSpeciesFilter] = useState('');
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    // OPTIONAL: Text-based species search
    const [speciesSearch, setSpeciesSearch] = useState('');
    const [showSpeciesDropdown, setShowSpeciesDropdown] = useState(false);

    // Pagination States
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    // Reference for Firestore Listener
    const subscriptionRef = useRef(null);

    // ---------------------------
    // MULTI-SELECT / BULK DELETE
    // ---------------------------
    const [selectedCatches, setSelectedCatches] = useState([]);
    const [bulkDeleteModal, setBulkDeleteModal] = useState(false);
    const [isBulkDeleting, setIsBulkDeleting] = useState(false);

    // ---------------------------
    // DOCUMENT TITLE
    // ---------------------------
    useEffect(() => {
        document.title = "New Catches | Fish-Trak - Internal Management Dashboard";
    }, []);

    // ---------------------------
    // FETCH SPECIES
    // ---------------------------
    const fetchAllSpecies = () => {
        firebase
            .firestore()
            .collection('species')
            .orderBy('name', 'asc')
            .onSnapshot(
                querySnapshot => {
                    const newSpeciesList = [];
                    querySnapshot.forEach(doc => {
                        const speciesItem = doc.data();
                        speciesItem.id = doc.id;
                        newSpeciesList.push(speciesItem);
                    });
                    setAllSpecies(newSpeciesList);
                },
                error => {
                    console.log("Error fetching species:", error);
                }
            );
    };

    // ---------------------------
    // FETCH TAG PROGRAMS
    // ---------------------------
    const fetchTagPrograms = () => {
        firebase
            .firestore()
            .collection('programs')
            .orderBy('created_at', 'desc')
            .onSnapshot(
                querySnapshot => {
                    const newProgramsList = [];
                    querySnapshot.forEach(doc => {
                        const programItem = doc.data();
                        programItem.id = doc.id;

                        const createdAt = programItem.created_at?.toDate()?.toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                        });
                        const updatedAt = programItem.updated_at?.toDate()?.toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                        });
                        programItem.created = createdAt;
                        programItem.updated = updatedAt;

                        newProgramsList.push(programItem);
                    });
                    setAllTagPrograms(newProgramsList);
                },
                error => {
                    console.log("Error fetching tag programs:", error);
                }
            );
    };

    // ---------------------------
    // FETCH NEW CATCHES LEDGER WITH FILTERS
    // ---------------------------
    const fetchNewCatchesLedger = () => {
        // Unsubscribe previous listener if any
        if (subscriptionRef.current) {
            subscriptionRef.current();
        }

        // Build Firestore query
        let newQuery = firebase.firestore()
            .collection('catches')
            .where('recatch', '==', false)
            .orderBy('created_at', 'desc');

        if (selectedSpeciesFilter) {
            const speciesRef = firebase.firestore().collection('species').doc(selectedSpeciesFilter);
            newQuery = newQuery.where('fish_species', '==', speciesRef);
        }

        if (startDateFilter && endDateFilter) {
            newQuery = newQuery
                .where('catch_date', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
                .where('catch_date', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        }
        else if (startDateFilter && !endDateFilter) {
            newQuery = newQuery
                .where('catch_date', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
        }
        else if (!startDateFilter && endDateFilter) {
            newQuery = newQuery
                .where('catch_date', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        }

        // Listen to real-time updates
        const unsub = newQuery.onSnapshot(
            (querySnapshot) => {
                const newCatchLedger = [];
                querySnapshot.forEach(doc => {
                    const catchItem = doc.data();
                    const catchId = doc.id;
                    catchItem.id = catchId;
                    catchItem.angler = catchItem.user_name;
                    catchItem.user_link = `/user/details/${catchItem.user.id}`;

                    const catchDate = catchItem.catch_date?.toDate()?.toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                    });
                    catchItem.date = catchDate || '';

                    catchItem.link = `/catch/details/${catchId}`;
                    catchItem.tag_link = `/tag/details/${catchItem.tag.id}`;

                    newCatchLedger.push(catchItem);
                });
                setNewCatchesLedger(newCatchLedger);

                // Reset states / modals
                setEditModal(false);
                setIsUploading(false);
                setIsUpdating(false);
                setModalList(false);
                setDeleteModal(false);
                setIsDeleting(false);
                setRestoreCatchModal(false);
                setIsRestoringCatch(false);
                setFetchedData(true);
            },
            (error) => {
                console.error("Error fetching new catches:", error);
            }
        );

        subscriptionRef.current = unsub;
    };

    // ---------------------------
    // INITIAL DATA FETCHING
    // ---------------------------
    useEffect(() => {
        if (initialLoad) {
            setInitialLoad(false);
            fetchAllSpecies();
            fetchTagPrograms();
            fetchNewCatchesLedger();
        }
    }, [initialLoad]);

    // ---------------------------
    // SEARCH AND FILTER LOGIC
    // ---------------------------
    const filteredCatches = useMemo(() => {
        return newCatchesLedger.filter(catchItem => {
            const nameMatch = catchItem.name.toLowerCase().includes(searchQuery.toLowerCase());
            const tagMatch = catchItem.tag_number.toLowerCase().includes(searchQuery.toLowerCase());
            const anglerMatch = (catchItem.angler || "").toLowerCase().includes(searchQuery.toLowerCase());
            const locationMatch = (catchItem.location_string || "").toLowerCase().includes(searchQuery.toLowerCase());
            return tagMatch || anglerMatch || locationMatch || nameMatch;
        });
    }, [newCatchesLedger, searchQuery]);

    // ---------------------------
    // PAGINATION CALCULATIONS
    // ---------------------------
    const pageCount = Math.ceil(filteredCatches.length / itemsPerPage);
    const displayedCatches = filteredCatches.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    // ---------------------------
    // HANDLE PAGE CHANGE
    // ---------------------------
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // ---------------------------
    // HANDLE APPLY FILTERS BUTTON
    // ---------------------------
    const handleApplyFilters = () => {
        fetchNewCatchesLedger();
        setCurrentPage(0); // Reset to first page when filters are applied
    };

    // ---------------------------
    // HANDLE CSV DOWNLOAD
    // ---------------------------
    const handleDownloadCSV = async () => {
        try {
            let csvQuery = firebase.firestore()
                .collection('catches')
                .where('recatch', '==', false)
                .orderBy('created_at','desc');

            if (selectedSpeciesFilter) {
                const speciesRef = firebase.firestore().collection('species').doc(selectedSpeciesFilter);
                csvQuery = csvQuery.where('fish_species', '==', speciesRef);
            }

            if (startDateFilter && endDateFilter) {
                csvQuery = csvQuery
                    .where('catch_date', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
                    .where('catch_date', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }
            else if (startDateFilter && !endDateFilter) {
                csvQuery = csvQuery
                    .where('catch_date', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
            }
            else if (!startDateFilter && endDateFilter) {
                csvQuery = csvQuery
                    .where('catch_date', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }

            const snapshot = await csvQuery.get();
            const csvRows = [];
            // Headers
            csvRows.push([
                "id",
                "name",
                "user_name",
                "location_string",
                "location_area",
                "location_city",
                "location_state",
                "location_state_code",
                "location_country",
                "tag_number",
                "catch_date",
                "catch_time",
                "deleted",
                "length",
                "weight",
                "info",
                "image_url"
            ].join(","));

            snapshot.forEach(doc => {
                const data = doc.data();
                let dateStr = "";
                if (data.catch_date) {
                    dateStr = data.catch_date.toDate().toLocaleString();
                }
                const row = [
                    doc.id,
                    (data.name || "").replace(/,/g, ""),
                    (data.user_name || "").replace(/,/g, ""),
                    (data.location_string || "").replace(/,/g, ""),
                    (data.location_area || "").replace(/,/g, ""),
                    (data.location_city || "").replace(/,/g, ""),
                    (data.location_state || "").replace(/,/g, ""),
                    (data.location_state_code || "").replace(/,/g, ""),
                    (data.location_country || "").replace(/,/g, ""),
                    (data.tag_number || "").replace(/,/g, ""),
                    dateStr,
                    data.deleted ? "true" : "false",
                    (data.length || "").toString().replace(/,/g, ""),
                    (data.weight || "").toString().replace(/,/g, ""),
                    (data.info || "").replace(/,/g, ""),
                    (data.image_url || "").replace(/,/g, "")
                ];
                csvRows.push(row.join(","));
            });

            const csvString = csvRows.join("\n");
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "filtered_new_catches.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('CSV download error:', error);
            alert('Could not download CSV. Please try again.');
        }
    };

    // ---------------------------
    // CRUD OPERATIONS
    // ---------------------------

    // Handle Update Catch Form Submission
    const handleUpdateCatch = (event) => {
        event.preventDefault();
        submitUpdateForm(event);
    };

    const submitUpdateForm = async (event) => {
        setIsUploading(true);
        setIsUpdating(true);
        event.preventDefault();

        if (updatedImage) {
            await updateImage(event);
        } else {
            await updateCatch(event);
        }
    };

    const updateImage = async (event) => {
        event.preventDefault();

        const catchTimestamp = new Date(event.target.catch_datetime.value).getTime();
        const catchUserId = newCatchesLedger[updateCatchIndex].user.id;
        const catchTagNumber = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');

        const imageFileName = `catch-images/${catchTagNumber}-${catchUserId}-${catchTimestamp}`;
        const storageRef = ref(storage, imageFileName);

        const uploadTask = uploadBytesResumable(storageRef, updatedImage);
        uploadTask.on(
            'state_changed',
            snapshot => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            error => {
                console.log(error);
                setIsUpdating(false);
                setIsUploading(false);
                alert('Could not update catch image. Please try again.');
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(downloadedURL => {
                    updateCatch(event, downloadedURL);
                });
            }
        );
    };

    const updateCatch = async (event, downloadedURL) => {
        const speciesId = event.target.fish_species.value;
        const speciesIndex = event.target.fish_species.selectedIndex - 1;
        const catchTimestampFirebase = firebase.firestore.Timestamp.fromDate(new Date(event.target.catch_datetime.value));

        const catchData = { updated_at: serverTimestamp() };

        // Compare and update fields if changed
        if (event.target.catch_name.value !== newCatchesLedger[updateCatchIndex].name) {
            catchData['name'] = event.target.catch_name.value.replace(/[^0-9a-zA-Z'-,.?& ]/g, '');
        }
        if (catchTimestampFirebase !== newCatchesLedger[updateCatchIndex].catch_date) {
            catchData['catch_date'] = catchTimestampFirebase;
        }
        if (event.target.location_string.value !== newCatchesLedger[updateCatchIndex].location_string) {
            catchData['location_string'] = event.target.location_string.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        }

        // Location GeoPoint
        if (
            event.target.catch_latitude.value !== String(newCatchesLedger[updateCatchIndex].location.latitude) ||
            event.target.catch_longitude.value !== String(newCatchesLedger[updateCatchIndex].location.longitude)
        ) {
            catchData['location'] = new firebase.firestore.GeoPoint(
                parseFloat(event.target.catch_latitude.value.replace(/[^0-9.-]/g, '')),
                parseFloat(event.target.catch_longitude.value.replace(/[^0-9.-]/g, ''))
            );
        }
        if (event.target.location_area.value !== newCatchesLedger[updateCatchIndex].location_area) {
            catchData['location_area'] = event.target.location_area.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        }
        if (event.target.location_city.value !== newCatchesLedger[updateCatchIndex].location_city) {
            catchData['location_city'] = event.target.location_city.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        }
        if (event.target.state_string.value !== newCatchesLedger[updateCatchIndex].location_state) {
            catchData['location_state'] = event.target.state_string.value.replace(/[^a-zA-Z ]/g, '');
        }
        if (event.target.state_code.value !== newCatchesLedger[updateCatchIndex].location_state_code) {
            catchData['location_state_code'] = event.target.state_code.value.replace(/[^a-zA-Z]/g, '');
        }
        if (event.target.catch_country.value !== newCatchesLedger[updateCatchIndex].location_country) {
            catchData['location_country'] = event.target.catch_country.value.replace(/[^a-zA-Z-,&+.' ]/g, '');
        }

        // Tag Program
        if (
            newCatchesLedger[updateCatchIndex].tag_program &&
            event.target.tag_program.value !== newCatchesLedger[updateCatchIndex].tag_program.id
        ) {
            catchData['tag_program'] = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
        } else if (!newCatchesLedger[updateCatchIndex].tag_program?.id) {
            catchData['tag_program'] = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
        }

        // Tag Number
        if (event.target.tag_number.value !== newCatchesLedger[updateCatchIndex].tag_number) {
            catchData['tag_number'] = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
        }

        // Fish Species
        if (event.target.fish_species.value !== newCatchesLedger[updateCatchIndex].fish_species.id) {
            catchData['fish_species'] = firebase.firestore().collection('species').doc(speciesId);
            catchData['species_name'] = allSpecies[speciesIndex]?.name || '';
        }

        // Length & Weight
        const oldLen = newCatchesLedger[updateCatchIndex].length || '';
        if (event.target.fish_length.value !== oldLen) {
            catchData['length'] = event.target.fish_length.value.replace(/[^0-9.,]/g, '');
        }
        const oldWeight = newCatchesLedger[updateCatchIndex].weight || '';
        if (event.target.fish_weight.value !== oldWeight) {
            catchData['weight'] = event.target.fish_weight.value.replace(/[^0-9.,]/g, '');
        }

        // Info
        const oldInfo = newCatchesLedger[updateCatchIndex].info || '';
        if (event.target.catch_info.value !== oldInfo) {
            catchData['info'] = event.target.catch_info.value.replace(/[^0-9a-zA-Z!@%&-=+;:'",.? ]/g, '');
        }

        // Re-catch and Metric System
        if (event.target.catch_type.value !== String(newCatchesLedger[updateCatchIndex].recatch)) {
            catchData['recatch'] = JSON.parse(event.target.catch_type.value);
        }
        if (event.target.catch_units.value !== String(newCatchesLedger[updateCatchIndex].metric_system)) {
            catchData['metric_system'] = JSON.parse(event.target.catch_units.value);
        }

        // New Image URL
        if (downloadedURL) {
            catchData['image_url'] = downloadedURL;
        }

        // Update Firestore Document
        const catchRef = firebase.firestore().collection('catches').doc(newCatchesLedger[updateCatchIndex].id);
        catchRef
            .update(catchData)
            .then(() => {
                alert('The catch has been updated successfully!');
                fetchNewCatchesLedger();
                setIsUpdating(false);
                setIsUploading(false);
                setEditModal(false);
            })
            .catch((error) => {
                console.error("Error updating catch:", error);
                setIsUpdating(false);
                setIsUploading(false);
                alert('Could not update catch entry. Please try again.');
            });
    };

    // ---------------------------
    // HANDLE DELETE CATCH
    // ---------------------------
    const deleteCatch = async () => {
        setIsDeleting(true);

        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(newCatchesLedger[deleteCatchIndex].tag.id);
        const catchesRef = firebase.firestore().collection('catches');

        try {
            // Get all catches using the same tag
            const snapshot = await catchesRef.where('tag', '==', tagRef).get();

            const batch = firebase.firestore().batch();

            snapshot.forEach(doc => {
                const catchItem = doc.data();

                // For each catch decrement user catch/recatch count
                const userDoc = usersRef.doc(catchItem.user.id);
                const userData = { updated_at: serverTimestamp() };
                if (catchItem.recatch) {
                    if (!catchItem.recatch_count || catchItem.recatch_count <= 1) {
                        userData['recatch_count'] = 0;
                    } else {
                        userData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                    }
                } else {
                    if (!catchItem.catch_count || catchItem.catch_count <= 1) {
                        userData['catch_count'] = 0;
                    } else {
                        userData['catch_count'] = firebase.firestore.FieldValue.increment(-1);
                    }
                }
                batch.update(userDoc, userData);

                // Delete the catch or recatch
                batch.update(catchesRef.doc(doc.id), {
                    updated_at: serverTimestamp(),
                    deleted: true
                });
            });

            // Delete the tag
            batch.update(tagRef, {
                updated_at: serverTimestamp(),
                deleted: true
            });

            await batch.commit();
            alert('The catch has been deleted successfully!');
            fetchNewCatchesLedger();
            setDeleteCatchId('');
            setDeleteCatchIndex(null);
            setIsDeleting(false);
            setDeleteModal(false);
        } catch (error) {
            console.error("Error deleting catch:", error);
            setIsDeleting(false);
            alert('Could not delete catch entry. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE RESTORE CATCH
    // ---------------------------
    const restoreCatch = async () => {
        setIsRestoringCatch(true);

        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(newCatchesLedger[restoreCatchIndex].tag.id);
        const catchesRef = firebase.firestore().collection('catches');

        try {
            // Get all catches using the same tag
            const snapshot = await catchesRef.where('tag', '==', tagRef).get();

            const batch = firebase.firestore().batch();

            snapshot.forEach(doc => {
                const catchItem = doc.data();

                // For each catch increment user catch/recatch count
                const userDoc = usersRef.doc(catchItem.user.id);
                const userData = { updated_at: serverTimestamp() };
                if (catchItem.recatch) {
                    userData['recatch_count'] = firebase.firestore.FieldValue.increment(1);
                } else {
                    userData['catch_count'] = firebase.firestore.FieldValue.increment(1);
                }
                batch.update(userDoc, userData);

                // Restore the catch or recatch
                batch.update(catchesRef.doc(doc.id), {
                    updated_at: serverTimestamp(),
                    deleted: false
                });
            });

            // Restore the tag
            batch.update(tagRef, {
                updated_at: serverTimestamp(),
                deleted: false
            });

            await batch.commit();
            alert('The catch has been restored successfully!');
            fetchNewCatchesLedger();
            setRestoreCatchId('');
            setRestoreCatchIndex(null);
            setIsRestoringCatch(false);
            setRestoreCatchModal(false);
        } catch (error) {
            console.error("Error restoring catch:", error);
            setIsRestoringCatch(false);
            alert('Could not restore catch entry. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE EDIT CATCH MODAL TOGGLE
    // ---------------------------
    const openEditModal = (catchId, catchIndex) => {
        setUpdateCatchId(catchId);
        setUpdateCatchIndex(catchIndex);
        setEditModal(true);
    };

    const closeEditModal = () => {
        setUpdateCatchId('');
        setUpdateCatchIndex(null);
        setUpdatedImage(null);
        setEditModal(false);
    };

    // ---------------------------
    // HANDLE DELETE CATCH MODAL TOGGLE
    // ---------------------------
    const openDeleteModal = (catchId, catchIndex) => {
        setDeleteCatchId(catchId);
        setDeleteCatchIndex(catchIndex);
        setDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setDeleteCatchId('');
        setDeleteCatchIndex(null);
        setDeleteModal(false);
    };

    // ---------------------------
    // HANDLE RESTORE CATCH MODAL TOGGLE
    // ---------------------------
    const openRestoreCatchModalHandler = (catchId, catchIndex) => {
        setRestoreCatchId(catchId);
        setRestoreCatchIndex(catchIndex);
        setRestoreCatchModal(true);
    };

    const closeRestoreCatchModalHandler = () => {
        setRestoreCatchId('');
        setRestoreCatchIndex(null);
        setRestoreCatchModal(false);
    };

    // ---------------------------
    // HANDLE ADD CATCH
    // ---------------------------
    const handleAddCatch = async (event) => {
        event.preventDefault();
        setIsUploading(true);

        const form = event.target;
        const catchName = form.catch_name.value.trim();
        const catchDatetime = form.catch_datetime.value;
        const locationString = form.location_string.value.trim();
        const catchLatitude = parseFloat(form.catch_latitude.value);
        const catchLongitude = parseFloat(form.catch_longitude.value);
        const tagNumber = form.tag_number.value.trim();
        const fishSpecies = form.fish_species.value;
        const fishLength = parseFloat(form.fish_length.value) || 0;
        const fishWeight = parseFloat(form.fish_weight.value) || 0;
        const catchInfo = form.catch_info.value.trim();
        const catchType = JSON.parse(form.catch_type.value);
        const catchUnits = JSON.parse(form.catch_units.value);
        const tagProgram = form.tag_program.value;

        let imageUrl = '';

        // Handle Image Upload
        if (form.image_link.files[0]) {
            const imageFile = form.image_link.files[0];
            const catchTimestamp = new Date(catchDatetime).getTime();
            const imageFileName = `catch-images/${tagNumber}-${firebase.auth().currentUser.uid}-${catchTimestamp}`;
            const storageRef = ref(storage, imageFileName);
            const uploadTask = uploadBytesResumable(storageRef, imageFile);

            await new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    snapshot => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                    },
                    error => {
                        console.log(error);
                        setIsUploading(false);
                        alert('Could not upload catch image. Please try again.');
                        reject(error);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then(url => {
                            imageUrl = url;
                            resolve();
                        });
                    }
                );
            });
        }

        // Prepare Catch Data
        const catchData = {
            name: catchName,
            catch_date: firebase.firestore.Timestamp.fromDate(new Date(catchDatetime)),
            location_string: locationString,
            location: new firebase.firestore.GeoPoint(catchLatitude, catchLongitude),
            location_area: form.location_area.value.trim(),
            location_city: form.location_city.value.trim(),
            location_state: form.state_string.value.trim(),
            location_state_code: form.state_code.value.trim(),
            location_country: form.catch_country.value.trim(),
            tag_number: tagNumber,
            fish_species: firebase.firestore().collection('species').doc(fishSpecies),
            species_name: allSpecies.find(sp => sp.id === fishSpecies)?.name || '',
            length: fishLength,
            weight: fishWeight,
            info: catchInfo,
            recatch: catchType,
            metric_system: catchUnits,
            tag_program: firebase.firestore().collection('programs').doc(tagProgram),
            image_url: imageUrl,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
            deleted: false,
            user: firebase.auth().currentUser ? firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid) : null,
            user_name: firebase.auth().currentUser ? firebase.auth().currentUser.email : 'Unknown'
        };

        try {
            await firebase.firestore().collection('catches').add(catchData);
            alert('Catch added successfully!');
            fetchNewCatchesLedger();
            setModalList(false);
            setIsUploading(false);
        } catch (error) {
            console.error("Error adding catch:", error);
            setIsUploading(false);
            alert('Could not add catch. Please try again.');
        }
    };

    // ---------------------------
    // FETCH UPDATE TAG PROGRAM ID
    // ---------------------------
    const getUpdateTag = async (catchId, catchIndex) => {
        const tagId = newCatchesLedger[catchIndex].tag.id;
        firebase
            .firestore()
            .collection('tags')
            .doc(tagId)
            .get()
            .then((tagDoc) => {
                const tagData = tagDoc.data();
                if (tagData && tagData.tag_program) {
                    setUpdateTagProgramId(tagData.tag_program.id);
                }
            })
            .catch(error => {
                console.error("Error fetching tag program:", error);
            });
    };

    // ---------------------------
    // USE EFFECT FOR LIST AND PAGINATION
    // ---------------------------
    useEffect(() => {
        if (fetchedData && newCatchesLedger.length > 0) {
            setFetchedData(false);
        }
    }, [fetchedData, newCatchesLedger]);

    // ---------------------------
    // MULTI-SELECT: SELECT / DESELECT
    // ---------------------------
    const handleSelectCatch = (catchId) => {
        setSelectedCatches(prev => {
            if (prev.includes(catchId)) {
                return prev.filter(id => id !== catchId);
            } else {
                return [...prev, catchId];
            }
        });
    };

    const handleSelectAllOnPage = () => {
        if (displayedCatches.length === 0) return;
        const allOnPage = displayedCatches.map(c => c.id);
        const alreadyAllSelected = allOnPage.every(id => selectedCatches.includes(id));
        if (alreadyAllSelected) {
            // Unselect them
            setSelectedCatches(prev => prev.filter(id => !allOnPage.includes(id)));
        } else {
            // Add them
            setSelectedCatches(prev => [...new Set([...prev, ...allOnPage])]);
        }
    };

    const toggleBulkDeleteModal = () => {
        setBulkDeleteModal(!bulkDeleteModal);
    };

    const deleteSelectedCatches = async () => {
        setIsBulkDeleting(true);

        const catchesRef = firebase.firestore().collection('catches');
        const tagsRef = firebase.firestore().collection('tags');
        const usersRef = firebase.firestore().collection('users');
        const batch = firebase.firestore().batch();

        try {
            for (const cId of selectedCatches) {
                const catchDocRef = catchesRef.doc(cId);
                const catchSnap = await catchDocRef.get();
                if (!catchSnap.exists) {
                    alert(`Catch with ID ${cId} does not exist.`);
                    continue;
                }
                const catchData = catchSnap.data();

                // Similar logic as deleteCatch
                if (catchData.recatch) {
                    // Just set this doc to deleted
                    const userDocRef = usersRef.doc(catchData.user.id);
                    let userData = { updated_at: serverTimestamp() };

                    if (!catchData.recatch_count || catchData.recatch_count <= 1) {
                        userData['recatch_count'] = 0;
                    } else {
                        userData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                    }

                    // Decrement species_count
                    if (catchData.fish_species && catchData.fish_species.id) {
                        userData['species_count'] = firebase.firestore.FieldValue.increment(-1);
                    }

                    batch.update(userDocRef, userData);
                    batch.update(catchDocRef, {
                        updated_at: serverTimestamp(),
                        deleted: true
                    });

                } else {
                    // recatch=false => we must set doc, associated tag, and all docs with the same tag to deleted
                    const tagDocRef = tagsRef.doc(catchData.tag.id);
                    const allCatchesWithTag = await catchesRef.where('tag', '==', tagDocRef).get();
                    allCatchesWithTag.forEach((snap) => {
                        const itemData = snap.data();
                        const itemRef = snap.ref;

                        // update user
                        const userDocRef = usersRef.doc(itemData.user.id);
                        let userData = { updated_at: serverTimestamp() };
                        if (itemData.recatch) {
                            if (!itemData.recatch_count || itemData.recatch_count <= 1) {
                                userData['recatch_count'] = 0;
                            } else {
                                userData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                            }
                        } else {
                            if (!itemData.catch_count || itemData.catch_count <= 1) {
                                userData['catch_count'] = 0;
                            } else {
                                userData['catch_count'] = firebase.firestore.FieldValue.increment(-1);
                            }
                        }

                        if (itemData.fish_species && itemData.fish_species.id) {
                            userData['species_count'] = firebase.firestore.FieldValue.increment(-1);
                        }

                        batch.update(userDocRef, userData);
                        batch.update(itemRef, { updated_at: serverTimestamp(), deleted: true });
                    });

                    // mark the tag as deleted
                    batch.update(tagDocRef, {
                        updated_at: serverTimestamp(),
                        deleted: true
                    });
                }
            }

            await batch.commit();
            alert('Selected catches have been deleted successfully!');
            setSelectedCatches([]);
            toggleBulkDeleteModal();
            fetchNewCatchesLedger();
        } catch (error) {
            console.error("Error in bulk deleting new catches:", error);
            alert('Could not delete some of the selected catches. Please try again.');
        } finally {
            setIsBulkDeleting(false);
        }
    };

    // ---------------------------
    // COMPONENT RETURN
    // ---------------------------
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">New Catches</h4>
                                </CardHeader>

                                <CardBody>
                                    {/* Filter and Action Controls */}
                                    <Row className="g-4 mb-3">
                                        {/* Add Catch Button */}
                                        <Col sm="auto">
                                            <div className="d-flex gap-1">
                                                <Button
                                                    color="success"
                                                    onClick={() => setModalList(true)}
                                                    id="create-btn"
                                                >
                                                    <i className="mdi mdi-map-marker-plus"></i>
                                                </Button>
                                            </div>
                                        </Col>

                                        {/* Bulk Delete Button (NEW) */}
                                        <Col sm="auto">
                                            <Button
                                                color="danger"
                                                onClick={toggleBulkDeleteModal}
                                                disabled={selectedCatches.length === 0}
                                            >
                                                <i className="mdi mdi-delete"></i>
                                            </Button>
                                        </Col>

                                        {/* Search Box */}
                                        <Col className="d-flex align-items-start">
                                            <Input
                                                type="search"
                                                name="search"
                                                id="search"
                                                placeholder="Search Catches..."
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        </Col>

                                        {/* Species Filter (Dropdown) */}
                                        {/* <Col sm="2">
                                            <Input
                                                type="select"
                                                name="species-filter"
                                                id="species-filter"
                                                value={selectedSpeciesFilter}
                                                onChange={(e) => setSelectedSpeciesFilter(e.target.value)}
                                            >
                                                <option value="">All Species</option>
                                                {allSpecies.map(spec => (
                                                    <option key={spec.id} value={spec.id}>
                                                        {spec.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col> */}

                                        {/* OPTIONAL: Text-based species search (commented out if you only want the dropdown)*/}
                                        <Col sm="2" style={{ position:'relative' }}>
                                            <Input
                                                type="text"
                                                placeholder="Search Species..."
                                                value={speciesSearch}
                                                onChange={(e)=>{
                                                    setSpeciesSearch(e.target.value);
                                                    if(e.target.value.trim()!==''){
                                                        setShowSpeciesDropdown(true);
                                                    } else {
                                                        setShowSpeciesDropdown(false);
                                                    }
                                                }}
                                                onFocus={()=>{
                                                    if(speciesSearch.trim()!==''){
                                                        setShowSpeciesDropdown(true);
                                                    }
                                                }}
                                            />
                                            {showSpeciesDropdown && (
                                                <div
                                                    style={{
                                                        position:'absolute',
                                                        top:'100%',
                                                        left:0,
                                                        right:0,
                                                        zIndex:999,
                                                        background:'#fff',
                                                        border:'1px solid #ccc',
                                                        maxHeight:'200px',
                                                        overflowY:'auto'
                                                    }}
                                                >
                                                    <div
                                                        className="p-2"
                                                        style={{ cursor:'pointer' }}
                                                        onClick={()=>{
                                                            setSelectedSpeciesFilter('');
                                                            setSpeciesSearch('');
                                                            setShowSpeciesDropdown(false);
                                                        }}
                                                    >
                                                        All Species
                                                    </div>
                                                    {allSpecies
                                                        .filter(sp => sp.name.toLowerCase().includes(speciesSearch.toLowerCase()))
                                                        .map(sp => (
                                                            <div
                                                                key={sp.id}
                                                                className="p-2"
                                                                style={{ cursor:'pointer' }}
                                                                onClick={()=>{
                                                                    setSelectedSpeciesFilter(sp.id);
                                                                    setSpeciesSearch(sp.name);
                                                                    setShowSpeciesDropdown(false);
                                                                }}
                                                            >
                                                                {sp.name}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )}
                                        </Col>
                                        

                                        {/* Start Date */}
                                        <Col sm="2">
                                            <Flatpickr
                                                id="start-date"
                                                className="form-control"
                                                options={{ dateFormat: "Y-m-d" }}
                                                placeholder="Start Date"
                                                value={startDateFilter}
                                                onChange={(dates) => setStartDateFilter(dates[0] || null)}
                                            />
                                        </Col>

                                        {/* End Date */}
                                        <Col sm="2">
                                            <Flatpickr
                                                id="end-date"
                                                className="form-control"
                                                options={{ dateFormat: "Y-m-d" }}
                                                placeholder="End Date"
                                                value={endDateFilter}
                                                onChange={(dates) => setEndDateFilter(dates[0] || null)}
                                            />
                                        </Col>

                                        {/* Apply Filters Button */}
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button color="secondary" onClick={handleApplyFilters} style={{ width: '100%' }}>
                                                <i className="mdi mdi-filter"></i>
                                            </Button>
                                        </Col>

                                        {/* Download CSV Button */}
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button color="primary" onClick={handleDownloadCSV} style={{ width: '100%' }}>
                                                <i className="mdi mdi-download"></i>
                                            </Button>
                                        </Col>
                                    </Row>

                                    {/* Catches Table */}
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        {(!isUpdating && !isDeleting && !isRestoringCatch && !isBulkDeleting) && (
                                            <table className="table align-middle table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        {/* Multi-Select: "Select All" on this page */}
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <Input
                                                                type="checkbox"
                                                                onChange={handleSelectAllOnPage}
                                                                checked={
                                                                    displayedCatches.length > 0 &&
                                                                    displayedCatches.every(c => selectedCatches.includes(c.id))
                                                                }
                                                            />
                                                        </th>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">Catch Name</th>
                                                        <th scope="col">Angler Name</th>
                                                        <th scope="col">Catch Location</th>
                                                        <th scope="col">Tag Number</th>
                                                        <th scope="col">Catch Date</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {displayedCatches.length > 0 ? displayedCatches.map((catchItem, index) => (
                                                        <tr key={catchItem.id}>
                                                            {/* Per-row checkbox */}
                                                            <td>
                                                                <Input
                                                                    type="checkbox"
                                                                    checked={selectedCatches.includes(catchItem.id)}
                                                                    onChange={() => handleSelectCatch(catchItem.id)}
                                                                />
                                                            </td>
                                                            <th scope="row">
                                                                <a className="btn btn-sm btn-success" href={catchItem.link}>
                                                                    <i className="mdi mdi-eye-outline"></i>
                                                                </a>
                                                            </th>
                                                            <td>
                                                                {catchItem.image_url ? (
                                                                    <img
                                                                        className="avatar-sm rounded"
                                                                        alt={catchItem.name}
                                                                        src={catchItem.image_url}
                                                                    />
                                                                ) : (
                                                                    <span>No Image</span>
                                                                )}
                                                            </td>
                                                            <td>{catchItem.name}</td>
                                                            <td>
                                                                <a href={catchItem.user_link} className="fw-medium link-primary">
                                                                    {catchItem.angler}
                                                                </a>
                                                            </td>
                                                            <td>{catchItem.location_string}</td>
                                                            <td>
                                                                <a href={catchItem.tag_link} className="fw-medium link-primary">
                                                                    #{catchItem.tag_number}
                                                                </a>
                                                            </td>
                                                            <td>{catchItem.date}</td>
                                                            <td>
                                                                {catchItem.recatch ? (
                                                                    <span className="badge badge-soft-warning text-uppercase">
                                                                        Re-Catch
                                                                    </span>
                                                                ) : (
                                                                    <span className="badge badge-soft-success text-uppercase">
                                                                        Catch
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    {/* Edit Button */}
                                                                    <Button
                                                                        color="warning"
                                                                        size="sm"
                                                                        onClick={() => openEditModal(catchItem.id, newCatchesLedger.findIndex(c => c.id === catchItem.id))}
                                                                    >
                                                                        <i className="mdi mdi-square-edit-outline"></i>
                                                                    </Button>
                                                                    {/* Delete or Restore Buttons */}
                                                                    {!catchItem.deleted ? (
                                                                        <Button
                                                                            color="danger"
                                                                            size="sm"
                                                                            onClick={() => openDeleteModal(catchItem.id, newCatchesLedger.findIndex(c => c.id === catchItem.id))}
                                                                        >
                                                                            <i className="mdi mdi-delete-outline"></i>
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            color="success"
                                                                            size="sm"
                                                                            onClick={() => openRestoreCatchModalHandler(catchItem.id, newCatchesLedger.findIndex(c => c.id === catchItem.id))}
                                                                        >
                                                                            <i className="mdi mdi-history"></i>
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )) : (
                                                        <tr>
                                                            <td colSpan="10" className="text-center">No Catches Found!</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        )}

                                        {/* Loader */}
                                        {(isUpdating || isDeleting || isRestoringCatch || isBulkDeleting) && (
                                            <div className="d-flex justify-content-center my-5">
                                                <ThreeCircles
                                                    visible={
                                                        isUpdating || 
                                                        isDeleting || 
                                                        isRestoringCatch ||
                                                        isBulkDeleting
                                                    }
                                                    height="100"
                                                    width="100"
                                                    color="#4192C3"
                                                    ariaLabel="three-circles-loading"
                                                    wrapperStyle={{ margin: "0 auto" }}
                                                    wrapperClass="justify-content-center"
                                                />
                                            </div>
                                        )}

                                        {/* No Results Message */}
                                        {filteredCatches.length === 0 && (
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Catches Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any catches.</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Pagination Controls */}
                                    {pageCount > 1 && (
                                        <div className="d-flex justify-content-end">
                                            <ReactPaginate
                                                previousLabel={"← Previous"}
                                                nextLabel={"Next →"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageChange}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"page-link"}
                                                nextLinkClassName={"page-link"}
                                                disabledClassName={"disabled"}
                                                activeClassName={"active"}
                                                pageLinkClassName={"page-link"}
                                                breakLabel={"..."}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* --------------------------- */}
            {/* Modals */}
            {/* --------------------------- */}

            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={closeEditModal} centered>
                <ModalHeader toggle={closeEditModal}>Edit Catch</ModalHeader>
                {!isUpdating && newCatchesLedger[updateCatchIndex] && (
                    <form className="tablelist-form" onSubmit={handleUpdateCatch}>
                        <ModalBody>
                            <FormGroup>
                                <Label for="name-field">Catch Name*</Label>
                                <Input
                                    type="text"
                                    name="catch_name"
                                    id="name-field"
                                    className="form-control"
                                    placeholder="Enter Name"
                                    required
                                    defaultValue={newCatchesLedger[updateCatchIndex].name}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="catch-datetime">Catch Date & Time*</Label>
                                <Flatpickr
                                    id="catch-datetime"
                                    className="form-control"
                                    options={{
                                        enableTime: true,
                                        dateFormat: "Y-m-d H:i",
                                        defaultDate: newCatchesLedger[updateCatchIndex].catch_date.toDate()
                                    }}
                                    placeholder="Select Date & Time"
                                    name="catch_datetime"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="image-field">Image File</Label>
                                <Input
                                    type="file"
                                    name="image_link"
                                    id="image-field"
                                    className="form-control"
                                    onChange={(e) => { setUpdatedImage(e.target.files[0]); }}
                                />
                                {newCatchesLedger[updateCatchIndex].image_url && (
                                    <img
                                        className="avatar-sm rounded mt-2"
                                        alt={newCatchesLedger[updateCatchIndex].name}
                                        src={newCatchesLedger[updateCatchIndex].image_url}
                                    />
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label for="location-string">Full Catch Location*</Label>
                                <Input
                                    type="text"
                                    id="location-string"
                                    className="form-control"
                                    name="location_string"
                                    placeholder="Toms River"
                                    required
                                    defaultValue={newCatchesLedger[updateCatchIndex].location_string}
                                />
                            </FormGroup>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="catch-latitude">Latitude*</Label>
                                        <Input
                                            type="number"
                                            id="catch-latitude"
                                            className="form-control"
                                            name="catch_latitude"
                                            placeholder="Enter Latitude*"
                                            step="any"
                                            required
                                            defaultValue={newCatchesLedger[updateCatchIndex].location.latitude}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="catch-longitude">Longitude*</Label>
                                        <Input
                                            type="number"
                                            id="catch-longitude"
                                            className="form-control"
                                            name="catch_longitude"
                                            placeholder="Enter Longitude*"
                                            step="any"
                                            required
                                            defaultValue={newCatchesLedger[updateCatchIndex].location.longitude}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="location-area">Catch Location Area*</Label>
                                        <Input
                                            type="text"
                                            id="location-area"
                                            className="form-control"
                                            name="location_area"
                                            placeholder="Toms River"
                                            required
                                            defaultValue={newCatchesLedger[updateCatchIndex].location_area}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="location-city">Catch Location City*</Label>
                                        <Input
                                            type="text"
                                            id="location-city"
                                            className="form-control"
                                            name="location_city"
                                            placeholder="Atlantic City"
                                            required
                                            defaultValue={newCatchesLedger[updateCatchIndex].location_city}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="state-string">Catch State*</Label>
                                        <Input
                                            type="text"
                                            id="state-string"
                                            className="form-control"
                                            name="state_string"
                                            placeholder="New York"
                                            required
                                            defaultValue={newCatchesLedger[updateCatchIndex].location_state}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="state-code">State Code*</Label>
                                        <Input
                                            type="text"
                                            id="state-code"
                                            className="form-control"
                                            name="state_code"
                                            placeholder="NY"
                                            required
                                            maxLength={2}
                                            defaultValue={newCatchesLedger[updateCatchIndex].location_state_code}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label for="catch-country">Catch Country*</Label>
                                <Input
                                    type="select"
                                    name="catch_country"
                                    id="catch-country"
                                    required
                                    defaultValue={newCatchesLedger[updateCatchIndex].location_country}
                                >
                                    <option value="">Select Catch Country</option>
                                    <option value="USA">United States</option>
                                    <option value="CAN">Canada</option>
                                    {/* Add more countries as needed */}
                                </Input>
                            </FormGroup>

                            {updateTagProgramId && (
                                <FormGroup>
                                    <Label for="tag-program">Tagging Program*</Label>
                                    <Input
                                        type="select"
                                        name="tag_program"
                                        id="tag-program"
                                        required
                                        defaultValue={updateTagProgramId}
                                    >
                                        <option value="">Select Tag Program*</option>
                                        {allTagPrograms.map((prog) => (
                                            <option key={prog.id} value={prog.id}>
                                                {prog.program_name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            )}

                            <FormGroup>
                                <Label for="tag-number">Tag Number*</Label>
                                <Input
                                    type="text"
                                    id="tag-number"
                                    name="tag_number"
                                    className="form-control"
                                    placeholder="Enter Tag Number*"
                                    maxLength={15}
                                    required
                                    defaultValue={newCatchesLedger[updateCatchIndex].tag_number}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="fish-species">Fish Species*</Label>
                                <Input
                                    type="select"
                                    name="fish_species"
                                    id="fish-species"
                                    required
                                    defaultValue={newCatchesLedger[updateCatchIndex].fish_species.id}
                                >
                                    <option value="">Select Fish Species*</option>
                                    {allSpecies.map((sp) => (
                                        <option key={sp.id} value={sp.id}>
                                            {sp.name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="fish-length">
                                            Fish Length {newCatchesLedger[updateCatchIndex].metric_system ? "(CM)" : "(IN)"}
                                        </Label>
                                        <Input
                                            type="number"
                                            id="fish-length"
                                            className="form-control"
                                            placeholder="Enter Fish Length"
                                            name="fish_length"
                                            defaultValue={newCatchesLedger[updateCatchIndex].length}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="fish-weight">
                                            Fish Weight {newCatchesLedger[updateCatchIndex].metric_system ? "(KG)" : "(LB)"}
                                        </Label>
                                        <Input
                                            type="number"
                                            id="fish-weight"
                                            className="form-control"
                                            placeholder="Enter Fish Weight*"
                                            name="fish_weight"
                                            defaultValue={newCatchesLedger[updateCatchIndex].weight}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label for="catch-info">Catch Information</Label>
                                <Input
                                    type="text"
                                    id="catch-info"
                                    className="form-control"
                                    placeholder="Catch Information"
                                    name="catch_info"
                                    defaultValue={newCatchesLedger[updateCatchIndex].info}
                                />
                            </FormGroup>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="catch-type">Catch Type*</Label>
                                        <Input
                                            type="select"
                                            name="catch_type"
                                            id="catch-type"
                                            required
                                            defaultValue={String(newCatchesLedger[updateCatchIndex].recatch)}
                                        >
                                            <option value="">Select Catch Type*</option>
                                            <option value="false">New Catch</option>
                                            <option value="true">Re-Catch</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="catch-units">Unit System*</Label>
                                        <Input
                                            type="select"
                                            name="catch_units"
                                            id="catch-units"
                                            required
                                            defaultValue={String(newCatchesLedger[updateCatchIndex].metric_system)}
                                        >
                                            <option value="">Select Units System*</option>
                                            <option value="true">Metric System</option>
                                            <option value="false">Imperial System</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={closeEditModal}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={isUploading}
                                >
                                    Update
                                </Button>
                            </div>
                        </ModalFooter>
                    </form>
                )}
                {/* Loader for Update Modal */}
                {isUpdating && (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isUpdating}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Add Catch Modal */}
            <Modal isOpen={modalList} toggle={() => setModalList(!modalList)} centered>
                <ModalHeader toggle={() => setModalList(!modalList)}>Add Catch</ModalHeader>
                {!isUploading ? (
                    <form className="tablelist-form" onSubmit={handleAddCatch}>
                        <ModalBody>
                            <FormGroup>
                                <Label for="add-name">Catch Name*</Label>
                                <Input
                                    type="text"
                                    name="catch_name"
                                    id="add-name"
                                    className="form-control"
                                    placeholder="Enter Name"
                                    required
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="add-catch-datetime">Catch Date & Time*</Label>
                                <Flatpickr
                                    id="add-catch-datetime"
                                    className="form-control"
                                    options={{
                                        enableTime: true,
                                        dateFormat: "Y-m-d H:i",
                                    }}
                                    placeholder="Select Date & Time"
                                    name="catch_datetime"
                                    required
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="add-image">Image File</Label>
                                <Input
                                    type="file"
                                    name="image_link"
                                    id="add-image"
                                    className="form-control"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="add-location-string">Full Catch Location*</Label>
                                <Input
                                    type="text"
                                    id="add-location-string"
                                    className="form-control"
                                    name="location_string"
                                    placeholder="Toms River"
                                    required
                                />
                            </FormGroup>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="add-catch-latitude">Latitude*</Label>
                                        <Input
                                            type="number"
                                            id="add-catch-latitude"
                                            className="form-control"
                                            name="catch_latitude"
                                            placeholder="Enter Latitude*"
                                            step="any"
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="add-catch-longitude">Longitude*</Label>
                                        <Input
                                            type="number"
                                            id="add-catch-longitude"
                                            className="form-control"
                                            name="catch_longitude"
                                            placeholder="Enter Longitude*"
                                            step="any"
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label for="add-tag-number">Tag Number*</Label>
                                <Input
                                    type="text"
                                    id="add-tag-number"
                                    name="tag_number"
                                    className="form-control"
                                    placeholder="Enter Tag Number*"
                                    maxLength={15}
                                    required
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="add-fish-species">Fish Species*</Label>
                                <Input
                                    type="select"
                                    name="fish_species"
                                    id="add-fish-species"
                                    required
                                >
                                    <option value="">Select Fish Species*</option>
                                    {allSpecies.map((sp) => (
                                        <option key={sp.id} value={sp.id}>
                                            {sp.name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="add-fish-length">
                                            Fish Length {/* Add unit information if needed */}
                                        </Label>
                                        <Input
                                            type="number"
                                            id="add-fish-length"
                                            className="form-control"
                                            placeholder="Enter Fish Length"
                                            name="fish_length"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="add-fish-weight">
                                            Fish Weight {/* Add unit information if needed */}
                                        </Label>
                                        <Input
                                            type="number"
                                            id="add-fish-weight"
                                            className="form-control"
                                            placeholder="Enter Fish Weight*"
                                            name="fish_weight"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label for="add-catch-info">Catch Information</Label>
                                <Input
                                    type="text"
                                    id="add-catch-info"
                                    className="form-control"
                                    placeholder="Catch Information"
                                    name="catch_info"
                                />
                            </FormGroup>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="add-catch-type">Catch Type*</Label>
                                        <Input
                                            type="select"
                                            name="catch_type"
                                            id="add-catch-type"
                                            required
                                        >
                                            <option value="">Select Catch Type*</option>
                                            <option value="false">New Catch</option>
                                            <option value="true">Re-Catch</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="add-catch-units">Unit System*</Label>
                                        <Input
                                            type="select"
                                            name="catch_units"
                                            id="add-catch-units"
                                            required
                                        >
                                            <option value="">Select Units System*</option>
                                            <option value="true">Metric System</option>
                                            <option value="false">Imperial System</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label for="add-tag-program">Tagging Program*</Label>
                                <Input
                                    type="select"
                                    name="tag_program"
                                    id="add-tag-program"
                                    required
                                >
                                    <option value="">Select Tag Program*</option>
                                    {allTagPrograms.map((prog) => (
                                        <option key={prog.id} value={prog.id}>
                                            {prog.program_name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => setModalList(false)}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={isUploading}
                                >
                                    Add Catch
                                </Button>
                            </div>
                        </ModalFooter>
                    </form>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isUploading}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Delete Catch Modal */}
            <Modal
                isOpen={deleteModal}
                toggle={closeDeleteModal}
                centered
            >
                {!isDeleting ? (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Delete Catch Data</h4><br />
                                <h5 className="text-muted mx-4 mb-0">
                                    This will delete the catch, connected re-catches, and the fish-tag it used!
                                </h5><br />
                                <p className="text-muted mx-4 mb-0">
                                    Are you sure you want to delete this catch?
                                </p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={closeDeleteModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                onClick={deleteCatch}
                            >
                                Delete
                            </Button>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isDeleting}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Restore Catch Modal */}
            <Modal
                isOpen={restoreCatchModal}
                toggle={closeRestoreCatchModalHandler}
                centered
            >
                {!isRestoringCatch ? (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Restore Catch Data</h4><br />
                                <h5 className="text-muted mx-4 mb-0">
                                    This will restore the catch, connected re-catches, and the fish-tag it used!
                                </h5><br />
                                <p className="text-muted mx-4 mb-0">
                                    Are you sure you want to restore this catch?
                                </p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={closeRestoreCatchModalHandler}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="success"
                                onClick={restoreCatch}
                            >
                                Restore
                            </Button>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isRestoringCatch}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Bulk Delete Confirmation Modal (NEW) */}
            <Modal
                isOpen={bulkDeleteModal}
                toggle={toggleBulkDeleteModal}
                className="modal zoomIn"
                id="bulkDeleteModal"
                centered
            >
                {!isBulkDeleting ? (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Delete Selected Catches</h4>
                                <p className="text-muted mx-4 mb-0">
                                    Are you sure you want to delete the selected catches?
                                </p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={toggleBulkDeleteModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                onClick={deleteSelectedCatches}
                            >
                                Delete Selected
                            </Button>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center">
                            <ThreeCircles
                                visible={isBulkDeleting}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>
        </React.Fragment>
    );
};

export default NewCatches;