import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import List from 'list.js';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner'
import Slider from "react-slick";
import "../Utility/TimeLine.css";

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";

const OnboardingDetails = () => {
  
  document.title = "Onboarding Details | Fish-Trak - Internal Management Dashboard";
  
  //On mount variables
  const { onboardingId } = useParams();
  const storage = getStorage();
  const navigate = useNavigate();
  const onboardingRef = firebase.firestore().collection('onboarding').doc(onboardingId);
  
  //Session booleans
  const [initialLoad, setInitialLoad] = useState(true);
  const [fetchingDetails, setFetchingDetails] = useState(true);
  const [fetchingUser, setFetchingUser] = useState(true);
  
  //Data variables
  const [onboardingObject, setOnboardingObject] = useState({});
  const [userObject, setUserObject] = useState({});
  
  //Action buttons
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  
  const [deleteModal, setDeleteModal] = useState(false);
  function toggleDeleteModal() {

      setDeleteModal(!deleteModal);
      
  }
  
  const deleteOnboarding = async () => {
      
      setIsDeleting(true);
              
      //Delete onboarding from database
      const userRef = firebase.firestore().collection('users').doc(onboardingObject.user.id);
      const onboardingRef = firebase.firestore().collection('onboarding').doc(onboardingId);  
      onboardingRef
        .delete()
        .then(() => {
  
          userRef
              .update({
                  onboarded: false,
                  updated_at: serverTimestamp(),
                })
              .then((userResponse) => {
                alert('The onboarding answers have been deleted successfully!');
                navigate("../../onboardings");
              })
              .catch((error) => {
                setIsDeleting(false);
                alert('Could not update user onboarding status. Please try again.');
              });  
  
        })
        .catch((error) => {
          setIsDeleting(false);
          alert('Could not delete onboarding entry. Please try again.');
        });
  };
  
  //Get user details
  const getOnboardingDetails = async () => {
    onboardingRef
    .get()
    .then((onboardingDoc) => {
      const onboardingData = onboardingDoc.data();
      onboardingData.id = onboardingDoc.id;
      
      const submittedAt = onboardingData && onboardingData.created_at && onboardingData.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
      onboardingData.submitted = submittedAt;
      
      setOnboardingObject(onboardingData);
      setFetchingDetails(false);
      getUserDetails(onboardingData.user.id);
    })
    .catch((error) => {
      setFetchingDetails(false);
      alert('Could not get onboarding details. Please try again.');
    });  
  };
  
  //Get user details
  const getUserDetails = async (userId) => {
    firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .get()
    .then((userDoc) => {
      const userData = userDoc.data();
      userData.id = userDoc.id;
      const userLastName = !!userData.last_name ? userData.last_name : '';
      userData.full_name = userData.first_name + ' ' + userLastName;
      userData.user_link = '../../user/details/' + userDoc.id; 
      userData.mailtoLink = 'mailto:' + userData.email_address + '?subject=Fish-Trak Admin';
      
      setUserObject(userData);
      setFetchingUser(false);
    })
    .catch((error) => {
      setFetchingUser(false);
      alert('Could not get user details. Please try again.');
    });  
  };
  
  useEffect(() => {
    if(initialLoad) {
       setInitialLoad(false);
       if(onboardingId) { 
          getOnboardingDetails();
        }
     }
  });
  
  return (
    <React.Fragment>
      <div className="page-content">
        
        
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
              <CardHeader>
                  <h4 className="card-title mb-0">Onboarding User</h4>
              </CardHeader>
              
                <CardBody>
                
                  <ThreeCircles
                  visible={fetchingDetails || fetchingUser}
                  height="100"
                  width="100"
                  color="#4192C3"
                  ariaLabel="three-circles-loading"
                  wrapperClass="justify-content-center"
                  />
                  
                  {!fetchingDetails && !fetchingUser &&
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={userObject.image_url}
                        alt={userObject.email_address}
                        className="avatar-xl rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userObject.full_name}</h5>
                        <p className="mb-1"><a href={userObject.mailtoLink} class="email-link">{userObject.email_address}</a></p>
                        <p className="mb-0">{userObject.mobile_number}</p>
                        <p className="mb-0">{userObject.home_port}</p>
                        <p className="mb-0">
                          {userObject && userObject.onboarded &&
                            <span className="badge badge-soft-success text-uppercase">Onboarded</span>
                          }
                          {userObject && !userObject.onboarded &&
                            <span className="badge badge-soft-danger text-uppercase">Pending</span>
                          }
                        </p>
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                        <div className="edit">
                          <a className="btn btn-md btn-success edit-item-btn" href={userObject.user_link}><i className="mdi mdi-eye-outline"></i></a>
                        </div>
                        {userObject.email_address &&
                        <div className="edit">
                          <a className="btn btn-md btn-success edit-item-btn" style={{ backgroundColor: "darkBlue" }} href={userObject.mailtoLink}><i className="mdi mdi-email-send"></i></a>
                        </div>
                        }
                    </div>
                  </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
          <Col lg={12}>
              <Card>
                  <CardHeader>
                      <h4 className="card-title mb-0">Onboarding Answers</h4>
                  </CardHeader>
                    
                  {fetchingDetails && fetchingUser &&  
                  <CardBody>   
                    <ThreeCircles
                    visible={fetchingDetails || fetchingUser}
                    height="100"
                    width="100"
                    color="#4192C3"
                    ariaLabel="three-circles-loading"
                    wrapperClass="justify-content-center"
                    />
                  </CardBody>
                  }
                    
                  {!fetchingDetails && !fetchingUser &&
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1 align-self-center mb-1">
                        <div className="text-muted">
                          <h5>Submitted: {onboardingObject.submitted}</h5>
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        <div className="remove">
                            <button className="btn btn-md btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={() => toggleDeleteModal()}><i className="mdi mdi-delete-outline" ></i></button>
                        </div>
                      </div>
                    </div>
                    
                    <ul className="verti-timeline list-unstyled">
                      <li className="event-list">
                        <div>
                          <p className="text-primary">Question 1</p>
                          <h5>How many years have you been fishing?</h5>
                          <p className="text-muted">
                            {!!onboardingObject.years_fishing ? (onboardingObject.years_fishing + ' Years') : ''}
                          </p>
                        </div>
                      </li>
                      <li className="event-list">
                        <div>
                          <p className="text-primary">Question 2</p>
                          <h5>What is your angling platform?</h5>
                          <p className="text-muted">
                            {!!onboardingObject.angling_platform ? onboardingObject.angling_platform : ''}
                          </p>
                        </div>
                      </li>
                      <li className="event-list">
                        <div>
                          <p className="text-primary">Question 3</p>
                          <h5>Where do you fish?</h5>
                            
                            {onboardingObject.fishing_locations && (onboardingObject.fishing_locations.length > 0) && onboardingObject.fishing_locations.map((item, index) => (
                              <p className="text-muted">
                                {item.location}
                              </p>
                            ))}

                        </div>
                      </li>
                      <li className="event-list">
                        <div>
                          <p className="text-primary">Question 4</p>
                          <h5>How many times do you fish, each month?</h5>
                          <p className="text-muted">
                            {!!onboardingObject.monthly_fishing_times ? (onboardingObject.monthly_fishing_times + ' Times') : ''}
                          </p>
                        </div>
                      </li>
                      <li className="event-list">
                        <div>
                          <p className="text-primary">Question 5</p>
                          <h5>What is your personal best catch?</h5>
                          <p className="text-muted">
                            {!!onboardingObject.personal_best_catch ? onboardingObject.personal_best_catch : ''}
                          </p>
                        </div>
                      </li>
                      <li className="event-list">
                        <div>
                          <p className="text-primary">Question 6</p>
                          <h5>What fish species do you target?</h5>
                          <p className="text-muted">
                            {!!onboardingObject.target_species ? onboardingObject.target_species : ''}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </CardBody>
                  }
              </Card>
            </Col>
          </Row>
                    
          
        </Container>
      </div>
      
      {/* Remove Modal */}
      <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(); }} className="modal zoomIn" id="deleteRecordModal" centered >
      
          {!isDeleting &&
          <ModalBody>
              <div className="mt-2 text-center">
                  <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                      <h4>Delete User's Onboarding Answers</h4><br/>
                      <h5 className="text-muted mx-4 mb-0">This will NOT delete the account!!!</h5><br/>
                      <p className="text-muted mx-4 mb-0">Are you sure you want to delete these onboarding answers?</p>
                  </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteModal()}>Cancel</button>
                  <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteOnboarding}>Delete</button>
              </div>
          </ModalBody>
          }
              
              
              <ModalBody>
                  <ThreeCircles
                    visible={isDeleting}
                    height="100"
                    width="100"
                    color="#ff3d60"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
              </ModalBody>
      </Modal>
      
    </React.Fragment>
  );
};

export default OnboardingDetails;
