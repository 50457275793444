import React, { useRef, useState, useEffect, useMemo } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    ModalHeader,
    Input,
    FormGroup,
    Label
} from 'reactstrap';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import Papa from 'papaparse';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import { serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, getStorage, ref } from "firebase/storage";

const AllCatches = () => {

    const storage = getStorage();

    // ---------------------------
    // STATE VARIABLES
    // ---------------------------
    const [initialLoad, setInitialLoad] = useState(true);
    const [allCatchesLedger, setAllCatchesLedger] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [allSpecies, setAllSpecies] = useState([]);
    const [allTagPrograms, setAllTagPrograms] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);

    // Action States
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateCatchId, setUpdateCatchId] = useState('');
    const [updateCatchIndex, setUpdateCatchIndex] = useState(null);
    const [deleteCatchId, setDeleteCatchId] = useState('');
    const [deleteCatchIndex, setDeleteCatchIndex] = useState(null);
    const [isDeletingRecatch, setIsDeletingRecatch] = useState(false);
    const [isDeletingCatch, setIsDeletingCatch] = useState(false);
    const [isRestoringRecatch, setIsRestoringRecatch] = useState(false);
    const [isRestoringCatch, setIsRestoringCatch] = useState(false);
    const [restoreCatchId, setRestoreCatchId] = useState('');
    const [restoreCatchIndex, setRestoreCatchIndex] = useState(null);
    const [updatedImage, setUpdatedImage] = useState(null);

    // Modal States
    const [modalList, setModalList] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteRecatchModal, setDeleteRecatchModal] = useState(false);
    const [deleteCatchModal, setDeleteCatchModal] = useState(false);
    const [restoreRecatchModal, setRestoreRecatchModal] = useState(false);
    const [restoreCatchModal, setRestoreCatchModal] = useState(false);
    const [uploadCSVModal, setUploadCSVModal] = useState(false);

    // Filter and Search States
    const [selectedSpeciesFilter, setSelectedSpeciesFilter] = useState('');
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    // ---------------------------
    // TEXT-BASED SPECIES SEARCH (OPTIONAL)
    // ---------------------------
    const [speciesSearch, setSpeciesSearch] = useState('');
    const [showSpeciesDropdown, setShowSpeciesDropdown] = useState(false);

    // Pagination States
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    // CSV Upload States
    const [csvFile, setCsvFile] = useState(null);
    const [isCsvUploading, setIsCsvUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    // Reference for Firestore Listener
    const subscriptionRef = useRef(null);

    // ---------------------------
    // MULTI-SELECT STATES (Bulk Delete)
    // ---------------------------
    const [selectedCatches, setSelectedCatches] = useState([]);
    const [bulkDeleteModal, setBulkDeleteModal] = useState(false);
    const [isBulkDeleting, setIsBulkDeleting] = useState(false);

    // ---------------------------
    // DOCUMENT TITLE
    // ---------------------------
    useEffect(() => {
        document.title = "Catch Ledger | Fish-Trak - Internal Management Dashboard";
    }, []);

    // ---------------------------
    // FIND USER NAME BY ID
    // ---------------------------
    const findNameById = (userId) => {
        const user = allCatchesLedger.find(catchItem => catchItem.user && catchItem.user.id === userId);
        return user ? user.user_name : "No User Name";
    };

    // ---------------------------
    // FETCH SPECIES
    // ---------------------------
    const fetchAllSpecies = () => {
        firebase
            .firestore()
            .collection('species')
            .orderBy('name', 'asc')
            .onSnapshot(
                querySnapshot => {
                    const newSpeciesList = [];
                    querySnapshot.forEach(doc => {
                        const speciesItem = doc.data();
                        speciesItem.id = doc.id;
                        newSpeciesList.push(speciesItem);
                    });
                    setAllSpecies(newSpeciesList);
                },
                error => {
                    console.log("Error fetching species:", error);
                    toast.error("Error fetching species.");
                }
            );
    };

    // ---------------------------
    // FETCH TAG PROGRAMS
    // ---------------------------
    const fetchTagPrograms = () => {
        firebase
            .firestore()
            .collection('programs')
            .orderBy('created_at', 'desc')
            .onSnapshot(
                querySnapshot => {
                    const newProgramsList = [];
                    querySnapshot.forEach(doc => {
                        const programItem = doc.data();
                        programItem.id = doc.id;

                        const createdAt = programItem.created_at?.toDate().toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                        });
                        const updatedAt = programItem.updated_at?.toDate().toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                        });
                        programItem.created = createdAt;
                        programItem.updated = updatedAt;

                        newProgramsList.push(programItem);
                    });
                    setAllTagPrograms(newProgramsList);
                },
                error => {
                    console.log("Error fetching tag programs:", error);
                    toast.error("Error fetching tag programs.");
                }
            );
    };

    // ---------------------------
    // FETCH ALL CATCHES LEDGER WITH FILTERS
    // ---------------------------
    const fetchAllCatchesLedger = () => {
        // Unsubscribe previous listener if any
        if (subscriptionRef.current) {
            subscriptionRef.current();
        }

        // Build Firestore query
        let newQuery = firebase.firestore()
            .collection('catches')
            .orderBy('created_at', 'desc');

        if (selectedSpeciesFilter) {
            const speciesRef = firebase.firestore().collection('species').doc(selectedSpeciesFilter);
            newQuery = newQuery.where('fish_species', '==', speciesRef);
        }

        if (startDateFilter && endDateFilter) {
            newQuery = newQuery
                .where('catch_date', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
                .where('catch_date', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        }
        else if (startDateFilter && !endDateFilter) {
            newQuery = newQuery
                .where('catch_date', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
        }
        else if (!startDateFilter && endDateFilter) {
            newQuery = newQuery
                .where('catch_date', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        }

        // Listen to real-time updates
        const unsub = newQuery.onSnapshot(
            (querySnapshot) => {
                const newCatchLedger = [];
                querySnapshot.forEach(doc => {
                    const catchItem = doc.data();
                    const catchId = doc.id;
                    catchItem.id = catchId;
                    catchItem.angler = catchItem.user_name || 'Unknown';
                    catchItem.user_link = `/user/details/${catchItem.user?.id || ''}`;

                    const catchDate = catchItem.catch_date?.toDate().toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                    }) || '';
                    catchItem.date = catchDate;

                    catchItem.link = `/catch/details/${catchId}`;
                    catchItem.tag_link = `/tag/details/${catchItem.tag?.id || ''}`;

                    newCatchLedger.push(catchItem);
                });
                setAllCatchesLedger(newCatchLedger);

                // Reset states / modals
                setEditModal(false);
                setIsUploading(false);
                setIsUpdating(false);
                setModalList(false);
                setDeleteCatchModal(false);
                setDeleteRecatchModal(false);
                setIsDeletingCatch(false);
                setIsDeletingRecatch(false);
                setRestoreCatchModal(false);
                setRestoreRecatchModal(false);
                setIsRestoringCatch(false);
                setIsRestoringRecatch(false);
                setFetchedData(true);
            },
            (error) => {
                console.error("Error fetching catches:", error);
                toast.error("Error fetching catches.");
            }
        );

        subscriptionRef.current = unsub;
    };

    // ---------------------------
    // INITIAL DATA FETCHING
    // ---------------------------
    useEffect(() => {
        if (initialLoad) {
            setInitialLoad(false);
            fetchAllSpecies();
            fetchTagPrograms();
            fetchAllCatchesLedger();
        }
    }, [initialLoad]);

    // ---------------------------
    // CLEANUP ON UNMOUNT
    // ---------------------------
    useEffect(() => {
        return () => {
            if (subscriptionRef.current) {
                subscriptionRef.current();
            }
        };
    }, []);

    // ---------------------------
    // SEARCH AND FILTER LOGIC
    // ---------------------------
    const filteredCatches = useMemo(() => {
        return allCatchesLedger.filter(catchItem => {
            const matchesSearch =
                catchItem.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                catchItem.angler.toLowerCase().includes(searchQuery.toLowerCase()) ||
                catchItem.location_string.toLowerCase().includes(searchQuery.toLowerCase()) ||
                catchItem.tag_number.toLowerCase().includes(searchQuery.toLowerCase());
            return matchesSearch;
        });
    }, [allCatchesLedger, searchQuery]);

    // ---------------------------
    // PAGINATION CALCULATIONS
    // ---------------------------
    const pageCount = Math.ceil(filteredCatches.length / itemsPerPage);
    const displayedCatches = filteredCatches.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    // ---------------------------
    // HANDLE PAGE CHANGE
    // ---------------------------
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // ---------------------------
    // HANDLE APPLY FILTERS BUTTON
    // ---------------------------
    const handleApplyFilters = () => {
        fetchAllCatchesLedger();
        setCurrentPage(0); // Reset to first page when filters are applied
        toast.info("Filters applied.");
    };

    // ---------------------------
    // HANDLE CSV DOWNLOAD
    // ---------------------------
    const handleDownloadCSV = async () => {
        try {
            let csvQuery = firebase.firestore()
                .collection('catches')
                .orderBy('created_at', 'desc');

            // If species chosen
            if (selectedSpeciesFilter) {
                const speciesRef = firebase.firestore().collection('species').doc(selectedSpeciesFilter);
                csvQuery = csvQuery.where('fish_species', '==', speciesRef);
            }
            // If date range
            if (startDateFilter && endDateFilter) {
                csvQuery = csvQuery
                    .where('catch_date', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
                    .where('catch_date', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }
            else if (startDateFilter && !endDateFilter) {
                csvQuery = csvQuery
                    .where('catch_date', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
            }
            else if (!startDateFilter && endDateFilter) {
                csvQuery = csvQuery
                    .where('catch_date', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }

            const snapshot = await csvQuery.get();
            const csvRows = [];
            // Add headers
            csvRows.push([
                "id",
                "name",
                "user_name",
                "location_string",
                "location_area",
                "location_city",
                "location_state",
                "location_state_code",
                "location_country",
                "tag_number",
                "catch_date",
                "catch_time",
                "recatch",
                "deleted",
                "length",
                "weight",
                "info",
                "image_url",
                "species_name",
                "latest_anglers",
                "latest_catches"
            ].join(","));

            snapshot.forEach(doc => {
                const data = doc.data();
                // Convert date => string if present
                let cDate = "";
                if (data.catch_date) {
                    cDate = data.catch_date.toDate().toLocaleString();
                }

                const latestAnglers = data.latest_anglers ? data.latest_anglers.map(angler => angler.id).join('|') : '';
                const latestCatches = data.latest_catches ? data.latest_catches.map(catchRef => catchRef.id).join('|') : '';

                const rowArray = [
                    doc.id,
                    (data.name || "").replace(/,/g, ""),
                    (data.user_name || "").replace(/,/g, ""),
                    (data.location_string || "").replace(/,/g, ""),
                    (data.location_area || "").replace(/,/g, ""),
                    (data.location_city || "").replace(/,/g, ""),
                    (data.location_state || "").replace(/,/g, ""),
                    (data.location_state_code || "").replace(/,/g, ""),
                    (data.location_country || "").replace(/,/g, ""),
                    (data.tag_number || "").replace(/,/g, ""),
                    cDate,
                    data.recatch ? "true" : "false",
                    data.deleted ? "true" : "false",
                    (data.length || "").toString().replace(/,/g, ""),
                    (data.weight || "").toString().replace(/,/g, ""),
                    (data.info || "").replace(/,/g, ""),
                    (data.image_url || "").replace(/,/g, ""),
                    (data.species_name || "").replace(/,/g, ""),
                    latestAnglers,
                    latestCatches
                ];
                csvRows.push(rowArray.join(","));
            });

            // Combine into CSV string
            const csvString = csvRows.join("\n");

            // Download via Blob
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "filtered_catches.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            toast.success("CSV downloaded successfully.");
        } catch (error) {
            console.error('CSV download error:', error);
            toast.error('Could not download CSV. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE CSV FILE SELECTION
    // ---------------------------
    const handleCsvFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setCsvFile(event.target.files[0]);
        }
    };

    // ---------------------------
    // IMPORT SINGLE CSV ROW
    // ---------------------------
    const importCsvRow = async (row) => {
        const firestore = firebase.firestore();

        // Extract fields from CSV...
        // (User's existing code unchanged)
        const tagNumber = (row['tag_number'] || '').trim();
        const firstAnglerEmail = (row['first_angler_email'] || '').trim();
        const catchDateStr = row['catch_date'] || '';
        const fishColor = (row['fish_color'] || '').trim();
        const fishSpeciesId = (row['fish_species'] || '').trim();
        const imageUrl = (row['image_url'] || '').trim();
        const info = (row['info'] || '').trim();
        const length = parseFloat(row['length']) || 0;
        const locationLat = parseFloat(row['location_lat']) || 0;
        const locationLng = parseFloat(row['location_lng']) || 0;
        const locationArea = (row['location_area'] || '').trim();
        const locationCity = (row['location_city'] || '').trim();
        const locationCountry = (row['location_country'] || '').trim();
        const locationState = (row['location_state'] || '').trim();
        const locationStateCode = (row['location_state_code'] || '').trim();
        const locationString = (row['location_string'] || '').trim();
        const metricSystem = row['metric_system'] ? row['metric_system'].toLowerCase() === 'true' : false;
        const name = (row['name'] || '').trim();
        const recatch = row['recatch'] ? row['recatch'].toLowerCase() === 'true' : false;
        const speciesName = (row['species_name'] || '').trim();
        const userName = (row['user_name'] || '').trim();
        const weight = parseFloat(row['weight']) || 0;

        // Validate required fields
        if (!tagNumber) {
            throw new Error('Missing required field: tag_number');
        }
        if (!catchDateStr) {
            throw new Error('Missing required field: catch_date');
        }
        if (!fishSpeciesId) {
            throw new Error('Missing required field: fish_species');
        }
        if (!name) {
            throw new Error('Missing required field: name');
        }

        // Determine user reference...
        let userRef = null;
        let userDoc = null;
        if (firstAnglerEmail) {
            const usersSnapshot = await firestore.collection('users')
                .where('email_address', '==', firstAnglerEmail.toLowerCase())
                .get();
            if (usersSnapshot.empty) {
                throw new Error(`User with email ${firstAnglerEmail} not found.`);
            }
            userDoc = usersSnapshot.docs[0];
            userRef = firestore.collection('users').doc(userDoc.id);
        } else {
            const currentUser = firebase.auth().currentUser;
            if (!currentUser) {
                throw new Error('Logged-in user data not found.');
            }
            userRef = firestore.collection('users').doc(currentUser.uid);
            userDoc = await userRef.get();
            if (!userDoc.exists) {
                throw new Error('Logged-in user document does not exist.');
            }
        }

        const userPrivate = userDoc.data().privacy_mode || false;

        const tagData = {
            tag_number: tagNumber,
            first_angler: userRef,
            catch_count: 0,
            created_at: catchDateStr ? firebase.firestore.Timestamp.fromDate(new Date(catchDateStr)) : serverTimestamp(),
            updated_at: catchDateStr ? firebase.firestore.Timestamp.fromDate(new Date(catchDateStr)) : serverTimestamp(),
            deleted: false,
            used: true,
            tag_program: firestore.collection('programs').doc(allTagPrograms[0]?.id || ''),
            program_image: allTagPrograms[0]?.program_image || '',
            program_name: allTagPrograms[0]?.program_name || '',
            private: userPrivate,
        };

        const tagRef = await firestore.collection('tags').add(tagData);

        let imageUrlFinal = '';
        if (imageUrl) {
            imageUrlFinal = imageUrl;
        }

        const catchData = {
            catch_date: catchDateStr ? firebase.firestore.Timestamp.fromDate(new Date(catchDateStr)) : serverTimestamp(),
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
            fish_color: fishColor || '',
            fish_species: firestore.collection('species').doc(fishSpeciesId),
            image_url: imageUrlFinal || '',
            info: info || '',
            length: length || 0,
            weight: weight || 0,
            location: new firebase.firestore.GeoPoint(locationLat, locationLng),
            location_area: locationArea || '',
            location_city: locationCity || '',
            location_country: locationCountry || '',
            location_state: locationState || '',
            location_state_code: locationStateCode || '',
            location_string: locationString || '',
            metric_system: metricSystem,
            name: name || '',
            recatch: recatch,
            species_name: speciesName || '',
            tag_program: firestore.collection('programs').doc(allTagPrograms[0]?.id || ''),
            tag: tagRef,
            user: userRef,
            user_name: userName || (userDoc.data().first_name + ' ' + userDoc.data().last_name) || '',
            deleted: false,
        };

        const catchRef = await firestore.collection('catches').add(catchData);

        try {
            await tagRef.update({
                latest_anglers: firebase.firestore.FieldValue.arrayUnion(userRef),
                latest_catches: firebase.firestore.FieldValue.arrayUnion(catchRef),
                updated_at: serverTimestamp()
            });
        } catch (error) {
            console.error("Error updating tag document:", error);
            throw new Error('Failed to update tag with latest_anglers and latest_catches.');
        }

        const userUpdateData = {};
        if (recatch) {
            userUpdateData['recatch_count'] = firebase.firestore.FieldValue.increment(1);
        } else {
            userUpdateData['catch_count'] = firebase.firestore.FieldValue.increment(1);
        }
        userUpdateData['species_count'] = firebase.firestore.FieldValue.increment(1);

        await userRef.update(userUpdateData);
    };

    // ---------------------------
    // HANDLE ADD CATCH
    // ---------------------------
    const handleAddCatch = async (event) => {
        event.preventDefault();
        setIsUploading(true);

        const form = event.target;
        const catchName = form.catch_name.value.trim();
        const catchDatetime = form.catch_datetime.value;
        const locationString = form.location_string.value.trim();
        const catchLatitude = parseFloat(form.catch_latitude.value);
        const catchLongitude = parseFloat(form.catch_longitude.value);
        const tagNumber = form.tag_number.value.trim();
        const fishSpecies = form.fish_species.value;
        const fishLength = parseFloat(form.fish_length.value) || 0;
        const fishWeight = parseFloat(form.fish_weight.value) || 0;
        const catchInfo = form.catch_info.value.trim();
        const catchType = JSON.parse(form.catch_type.value);
        const catchUnits = JSON.parse(form.catch_units.value);
        const tagProgram = form.tag_program.value;

        let imageUrl = '';

        // Handle Image Upload
        if (form.image_file.files[0]) {
            const imageFile = form.image_file.files[0];
            const catchTimestamp = new Date(catchDatetime).getTime();
            const imageFileName = `catch-images/${tagNumber}-${firebase.auth().currentUser.uid}-${catchTimestamp}`;
            const storageRef = ref(storage, imageFileName);
            const uploadTask = uploadBytesResumable(storageRef, imageFile);

            await new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    snapshot => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadProgress(progress);
                    },
                    error => {
                        console.log(error);
                        setIsUploading(false);
                        toast.error('Could not upload catch image. Please try again.');
                        reject(error);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then(url => {
                            imageUrl = url;
                            resolve();
                        });
                    }
                );
            });
        }

        const catchData = {
            name: catchName,
            catch_date: firebase.firestore.Timestamp.fromDate(new Date(catchDatetime)),
            location_string: locationString,
            location: new firebase.firestore.GeoPoint(catchLatitude, catchLongitude),
            location_area: form.location_area.value.trim(),
            location_city: form.location_city.value.trim(),
            location_state: form.state_string.value.trim(),
            location_state_code: form.state_code.value.trim(),
            location_country: form.catch_country.value.trim(),
            tag_number: tagNumber,
            fish_species: firebase.firestore().collection('species').doc(fishSpecies),
            species_name: allSpecies.find(sp => sp.id === fishSpecies)?.name || '',
            length: fishLength,
            weight: fishWeight,
            info: catchInfo,
            recatch: catchType,
            metric_system: catchUnits,
            tag_program: firebase.firestore().collection('programs').doc(tagProgram),
            image_url: imageUrl,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
            deleted: false,
            user: firebase.auth().currentUser ? firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid) : null,
            user_name: firebase.auth().currentUser ? firebase.auth().currentUser.email : 'Unknown'
        };

        try {
            await firebase.firestore().collection('catches').add(catchData);

            const tagRef = firebase.firestore().collection('tags').where('tag_number', '==', tagNumber).limit(1);
            const tagSnapshot = await tagRef.get();
            if (!tagSnapshot.empty) {
                const tagDoc = tagSnapshot.docs[0];
                await tagDoc.ref.update({
                    latest_anglers: firebase.firestore.FieldValue.arrayUnion(catchData.user),
                    latest_catches: firebase.firestore.FieldValue.arrayUnion(catchData.id),
                    updated_at: serverTimestamp(),
                });
            }

            // Increment counts in user document
            const currentUser = firebase.auth().currentUser;
            if (currentUser) {
                const userRef = firebase.firestore().collection('users').doc(currentUser.uid);
                const userUpdateData = {};
                if (catchType) {
                    userUpdateData['recatch_count'] = firebase.firestore.FieldValue.increment(1);
                } else {
                    userUpdateData['catch_count'] = firebase.firestore.FieldValue.increment(1);
                }
                userUpdateData['species_count'] = firebase.firestore.FieldValue.increment(1);

                await userRef.update(userUpdateData);
            }

            toast.success(`Catch "${catchName}" added successfully.`);
            fetchAllCatchesLedger();
            setIsUploading(false);
            setUploadProgress(0);
            setModalList(false);
        } catch (error) {
            console.error("Error adding catch:", error);
            setIsUploading(false);
            toast.error('Could not add catch. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE UPDATE CATCH
    // ---------------------------
    const handleUpdateCatch = (event) => {
        event.preventDefault();
        submitUpdateForm(event);
    };

    const submitUpdateForm = async (event) => {
        setIsUpdating(true);
        setIsUploading(true);
        event.preventDefault();

        if (updatedImage) {
            await updateImage(event);
        } else {
            await updateCatch(event);
        }
    };

    const updateImage = async (event) => {
        event.preventDefault();

        const catchTimestamp = event.target.catch_datetime.value.replace(/[^0-9-]/g, '-');
        const catchUserId = allCatchesLedger[updateCatchIndex].user.id;
        const catchTagNumber = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');

        const imageFileName = `catch-images/${catchTagNumber}-${catchUserId}-${catchTimestamp}`;
        const storageRef = ref(storage, imageFileName);

        const uploadTask = uploadBytesResumable(storageRef, updatedImage);
        uploadTask.on(
            'state_changed',
            snapshot => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            error => {
                console.log(error);
                setIsUpdating(false);
                setIsUploading(false);
                toast.error('Could not update catch image. Please try again.');
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(downloadedURL => {
                    updateCatch(event, downloadedURL);
                });
            }
        );
    };

    const updateCatch = async (event, downloadedURL = null) => {
        const speciesId = event.target.fish_species.value;
        const speciesIndex = event.target.fish_species.selectedIndex - 1;
        const catchTimestampFirebase = firebase.firestore.Timestamp.fromDate(new Date(event.target.catch_datetime.value));

        const catchData = { updated_at: serverTimestamp() };

        // Compare each field & update if changed...
        if (event.target.catch_name.value !== allCatchesLedger[updateCatchIndex].name) {
            catchData['name'] = event.target.catch_name.value.replace(/[^0-9a-zA-Z'-,.?& ]/g, '');
        }
        if (catchTimestampFirebase !== allCatchesLedger[updateCatchIndex].catch_date) {
            catchData['catch_date'] = catchTimestampFirebase;
        }
        if (event.target.location_string.value !== allCatchesLedger[updateCatchIndex].location_string) {
            catchData['location_string'] = event.target.location_string.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        }

        // location lat/lng
        if (
            event.target.catch_latitude.value !== String(allCatchesLedger[updateCatchIndex].location.latitude) ||
            event.target.catch_longitude.value !== String(allCatchesLedger[updateCatchIndex].location.longitude)
        ) {
            catchData['location'] = new firebase.firestore.GeoPoint(
                parseFloat(event.target.catch_latitude.value.replace(/[^0-9.-]/g, '')),
                parseFloat(event.target.catch_longitude.value.replace(/[^0-9.-]/g, ''))
            );
        }
        if (event.target.location_area.value !== allCatchesLedger[updateCatchIndex].location_area) {
            catchData['location_area'] = event.target.location_area.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        }
        if (event.target.location_city.value !== allCatchesLedger[updateCatchIndex].location_city) {
            catchData['location_city'] = event.target.location_city.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        }
        if (event.target.state_string.value !== allCatchesLedger[updateCatchIndex].location_state) {
            catchData['location_state'] = event.target.state_string.value.replace(/[^a-zA-Z ]/g, '');
        }
        if (event.target.state_code.value !== allCatchesLedger[updateCatchIndex].location_state_code) {
            catchData['location_state_code'] = event.target.state_code.value.replace(/[^a-zA-Z]/g, '');
        }
        if (event.target.catch_country.value !== allCatchesLedger[updateCatchIndex].location_country) {
            catchData['location_country'] = event.target.catch_country.value.replace(/[^a-zA-Z-,&+.' ]/g, '');
        }

        // tag_program
        if (
            allCatchesLedger[updateCatchIndex].tag_program &&
            event.target.tag_program.value !== allCatchesLedger[updateCatchIndex].tag_program.id
        ) {
            catchData['tag_program'] = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
        } else if (!allCatchesLedger[updateCatchIndex].tag_program?.id && event.target.tag_program.value) {
            catchData['tag_program'] = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
        }

        // tag_number
        if (event.target.tag_number.value !== allCatchesLedger[updateCatchIndex].tag_number) {
            catchData['tag_number'] = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
        }

        // fish_species
        if (event.target.fish_species.value !== allCatchesLedger[updateCatchIndex].fish_species.id) {
            catchData['fish_species'] = firebase.firestore().collection('species').doc(speciesId);
            catchData['species_name'] = allSpecies[speciesIndex]?.name || '';
        }

        // length/weight
        const oldLen = allCatchesLedger[updateCatchIndex].length || '';
        if (event.target.fish_length.value !== String(oldLen)) {
            catchData['length'] = parseFloat(event.target.fish_length.value.replace(/[^0-9.]/g, '')) || 0;
        }
        const oldWeight = allCatchesLedger[updateCatchIndex].weight || '';
        if (event.target.fish_weight.value !== String(oldWeight)) {
            catchData['weight'] = parseFloat(event.target.fish_weight.value.replace(/[^0-9.]/g, '')) || 0;
        }

        // info
        const oldInfo = allCatchesLedger[updateCatchIndex].info || '';
        if (event.target.catch_info.value !== oldInfo) {
            catchData['info'] = event.target.catch_info.value.replace(/[^0-9a-zA-Z!@%&-=+;:'",.? ]/g, '');
        }

        // recatch, metric_system
        if (event.target.catch_type.value !== String(allCatchesLedger[updateCatchIndex].recatch)) {
            catchData['recatch'] = JSON.parse(event.target.catch_type.value);
        }
        if (event.target.catch_units.value !== String(allCatchesLedger[updateCatchIndex].metric_system)) {
            catchData['metric_system'] = JSON.parse(event.target.catch_units.value);
        }

        // If new image was uploaded
        if (downloadedURL) {
            catchData['image_url'] = downloadedURL;
        }

        const catchRef = firebase.firestore().collection('catches').doc(allCatchesLedger[updateCatchIndex].id);
        try {
            await catchRef.update(catchData);
            toast.success('The catch has been updated successfully!');
            fetchAllCatchesLedger();
            setIsUpdating(false);
            setIsUploading(false);
            setUpdatedImage(null);
            setEditModal(false);
        } catch (error) {
            console.error("Error updating catch:", error);
            setIsUpdating(false);
            setIsUploading(false);
            toast.error('Could not update catch entry. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE DELETE CATCH
    // ---------------------------
    const deleteCatch = async () => {
        setIsDeletingCatch(true);

        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(allCatchesLedger[deleteCatchIndex].tag.id);
        const catchesRef = firebase.firestore().collection('catches');

        try {
            // Get all catches using the same tag
            const snapshot = await catchesRef.where('tag', '==', tagRef).get();

            const batch = firebase.firestore().batch();

            snapshot.forEach(doc => {
                const catchItem = doc.data();

                // For each catch decrement user catch/recatch count
                const userDoc = usersRef.doc(catchItem.user.id);
                const userData = { updated_at: serverTimestamp() };
                if (catchItem.recatch) {
                    if (!catchItem.recatch_count || catchItem.recatch_count <= 1) {
                        userData['recatch_count'] = 0;
                    } else {
                        userData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                    }
                } else {
                    if (!catchItem.catch_count || catchItem.catch_count <= 1) {
                        userData['catch_count'] = 0;
                    } else {
                        userData['catch_count'] = firebase.firestore.FieldValue.increment(-1);
                    }
                }
                batch.update(userDoc, userData);

                // Delete the catch or recatch
                batch.update(catchesRef.doc(doc.id), {
                    updated_at: serverTimestamp(),
                    deleted: true
                });
            });

            // Delete the tag
            batch.update(tagRef, {
                updated_at: serverTimestamp(),
                deleted: true
            });

            await batch.commit();
            toast.success('The catch has been deleted successfully!');
            fetchAllCatchesLedger();
            setDeleteCatchId('');
            setDeleteCatchIndex(null);
            setIsDeletingCatch(false);
            setDeleteCatchModal(false);
        } catch (error) {
            console.error("Error deleting catch:", error);
            setIsDeletingCatch(false);
            toast.error('Could not delete catch entry. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE RESTORE CATCH
    // ---------------------------
    const restoreCatch = async () => {
        setIsRestoringCatch(true);

        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(allCatchesLedger[restoreCatchIndex].tag.id);
        const catchesRef = firebase.firestore().collection('catches');

        try {
            // Get all catches using the same tag
            const snapshot = await catchesRef.where('tag', '==', tagRef).get();

            const batch = firebase.firestore().batch();

            snapshot.forEach(doc => {
                const catchItem = doc.data();

                // For each catch increment user catch/recatch count
                const userDoc = usersRef.doc(catchItem.user.id);
                const userData = { updated_at: serverTimestamp() };
                if (catchItem.recatch) {
                    userData['recatch_count'] = firebase.firestore.FieldValue.increment(1);
                } else {
                    userData['catch_count'] = firebase.firestore.FieldValue.increment(1);
                }
                batch.update(userDoc, userData);

                // Restore the catch or recatch
                batch.update(catchesRef.doc(doc.id), {
                    updated_at: serverTimestamp(),
                    deleted: false
                });
            });

            // Restore the tag
            batch.update(tagRef, {
                updated_at: serverTimestamp(),
                deleted: false
            });

            await batch.commit();
            toast.success('The catch has been restored successfully!');
            fetchAllCatchesLedger();
            setRestoreCatchId('');
            setRestoreCatchIndex(null);
            setIsRestoringCatch(false);
            setRestoreCatchModal(false);
        } catch (error) {
            console.error("Error restoring catch:", error);
            setIsRestoringCatch(false);
            toast.error('Could not restore catch entry. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE DELETE RE-CATCH
    // ---------------------------
    const deleteRecatch = async () => {
        setIsDeletingRecatch(true);

        const userRef = firebase.firestore().collection('users').doc(allCatchesLedger[deleteCatchIndex].user.id);
        const tagRef = firebase.firestore().collection('tags').doc(allCatchesLedger[deleteCatchIndex].tag.id);
        const catchRef = firebase.firestore().collection('catches').doc(deleteCatchId);

        try {
            await catchRef.update({
                updated_at: serverTimestamp(),
                deleted: true
            });

            await tagRef.update({
                updated_at: serverTimestamp(),
                catch_count: firebase.firestore.FieldValue.increment(-1),
                latest_anglers: firebase.firestore.FieldValue.arrayRemove(userRef)
            });

            await userRef.update({
                updated_at: serverTimestamp(),
                recatch_count: firebase.firestore.FieldValue.increment(-1)
            });

            fetchAllCatchesLedger();
            toast.success('The recatch has been deleted successfully!');
            setDeleteCatchId('');
            setDeleteCatchIndex(null);
            setIsDeletingRecatch(false);
            setDeleteRecatchModal(false);
        } catch (error) {
            console.error("Error deleting recatch:", error);
            setIsDeletingRecatch(false);
            toast.error('Could not delete recatch entry. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE RESTORE RE-CATCH
    // ---------------------------
    const restoreRecatch = async () => {
        setIsRestoringRecatch(true);

        const userRef = firebase.firestore().collection('users').doc(allCatchesLedger[restoreCatchIndex].user.id);
        const tagRef = firebase.firestore().collection('tags').doc(allCatchesLedger[restoreCatchIndex].tag.id);
        const catchRef = firebase.firestore().collection('catches').doc(restoreCatchId);

        try {
            await catchRef.update({
                updated_at: serverTimestamp(),
                deleted: false
            });

            await tagRef.update({
                updated_at: serverTimestamp(),
                catch_count: firebase.firestore.FieldValue.increment(1),
                latest_anglers: firebase.firestore.FieldValue.arrayUnion(userRef)
            });

            await userRef.update({
                updated_at: serverTimestamp(),
                recatch_count: firebase.firestore.FieldValue.increment(1)
            });

            fetchAllCatchesLedger();
            toast.success('The recatch has been restored successfully!');
            setRestoreCatchId('');
            setRestoreCatchIndex(null);
            setIsRestoringRecatch(false);
            setRestoreRecatchModal(false);
        } catch (error) {
            console.error("Error restoring recatch:", error);
            setIsRestoringRecatch(false);
            toast.error('Could not restore recatch entry. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE EDIT CATCH MODAL TOGGLE
    // ---------------------------
    const toggleEditModal = (catchId, catchIndex) => {
        if (editModal) {
            setUpdateCatchId('');
            setUpdateCatchIndex(null);
            setUpdatedImage(null);
        } else {
            setUpdateCatchId(catchId);
            setUpdateCatchIndex(catchIndex);
        }
        setEditModal(!editModal);
    };

    // ---------------------------
    // HANDLE DELETE RE-CATCH MODAL TOGGLE
    // ---------------------------
    const toggleDeleteRecatchModal = (catchId, catchIndex) => {
        if (deleteRecatchModal) {
            setDeleteCatchId('');
            setDeleteCatchIndex(null);
        } else {
            setDeleteCatchId(catchId);
            setDeleteCatchIndex(catchIndex);
        }
        setDeleteRecatchModal(!deleteRecatchModal);
    };

    // ---------------------------
    // HANDLE DELETE CATCH MODAL TOGGLE
    // ---------------------------
    const toggleDeleteCatchModal = (catchId, catchIndex) => {
        if (deleteCatchModal) {
            setDeleteCatchId('');
            setDeleteCatchIndex(null);
        } else {
            setDeleteCatchId(catchId);
            setDeleteCatchIndex(catchIndex);
        }
        setDeleteCatchModal(!deleteCatchModal);
    };

    // ---------------------------
    // HANDLE RESTORE RE-CATCH MODAL TOGGLE
    // ---------------------------
    const toggleRestoreRecatchModal = (catchId, catchIndex) => {
        if (restoreRecatchModal) {
            setRestoreCatchId('');
            setRestoreCatchIndex(null);
        } else {
            setRestoreCatchId(catchId);
            setRestoreCatchIndex(catchIndex);
        }
        setRestoreRecatchModal(!restoreRecatchModal);
    };

    // ---------------------------
    // HANDLE RESTORE CATCH MODAL TOGGLE
    // ---------------------------
    const toggleRestoreCatchModal = (catchId, catchIndex) => {
        if (restoreCatchModal) {
            setRestoreCatchId('');
            setRestoreCatchIndex(null);
        } else {
            setRestoreCatchId(catchId);
            setRestoreCatchIndex(catchIndex);
        }
        setRestoreCatchModal(!restoreCatchModal);
    };

    // ---------------------------
    // HANDLE CSV UPLOAD MODAL TOGGLE
    // ---------------------------
    const toggleUploadCSVModal = () => {
        setCsvFile(null);
        setUploadProgress(0);
        setUploadCSVModal(!uploadCSVModal);
    };

    // ---------------------------
    // HANDLE CSV UPLOAD
    // ---------------------------
    const handleUploadCSV = () => {
        if (!csvFile) {
            toast.error('No CSV file selected.');
            return;
        }
        setIsCsvUploading(true);
        setUploadProgress(0);

        Papa.parse(csvFile, {
            header: true,
            skipEmptyLines: true,
            complete: async function(results) {
                const data = results.data;
                if (data.length === 0) {
                    toast.error("CSV file is empty.");
                    setIsCsvUploading(false);
                    return;
                }

                let processedCount = 0;
                let successCount = 0;
                let failureCount = 0;

                for (let i = 0; i < data.length; i++) {
                    const row = data[i];
                    try {
                        await importCsvRow(row);
                        processedCount++;
                        successCount++;
                        setUploadProgress(Math.round((processedCount / data.length) * 100));
                    } catch (err) {
                        processedCount++;
                        failureCount++;
                        console.error('Error importing row', row, err);
                    }
                }

                toast.success(`CSV upload completed. Successfully imported ${successCount} of ${data.length} rows.`);
                if (failureCount > 0) {
                    toast.error(`${failureCount} row(s) failed to import.`);
                }
                setIsCsvUploading(false);
                setCsvFile(null);
                toggleUploadCSVModal();
                fetchAllCatchesLedger();
            },
            error: function(err) {
                console.error("Error parsing CSV:", err);
                toast.error("Error parsing CSV file.");
                setIsCsvUploading(false);
            }
        });
    };

    // ---------------------------
    // HANDLE CREATE CATCH MODAL TOGGLE
    // ---------------------------
    const toggleCreateCatchModal = () => {
        setModalList(!modalList);
    };

    // ---------------------------
    // MULTI-SELECT / BULK DELETE
    // ---------------------------
    const toggleBulkDeleteModal = () => {
        setBulkDeleteModal(!bulkDeleteModal);
    };

    const handleSelectCatch = (catchId) => {
        setSelectedCatches(prev => {
            if (prev.includes(catchId)) {
                return prev.filter(id => id !== catchId);
            } else {
                return [...prev, catchId];
            }
        });
    };

    const handleSelectAllOnPage = () => {
        if (displayedCatches.length === 0) return;
        const allOnPage = displayedCatches.map(c => c.id);
        const alreadyAllSelected = allOnPage.every(id => selectedCatches.includes(id));
        if (alreadyAllSelected) {
            // Unselect them
            setSelectedCatches(prev => prev.filter(id => !allOnPage.includes(id)));
        } else {
            // Add them
            setSelectedCatches(prev => [...new Set([...prev, ...allOnPage])]);
        }
    };

    const deleteSelectedCatches = async () => {
        setIsBulkDeleting(true);

        const catchesRef = firebase.firestore().collection('catches');
        const tagsRef = firebase.firestore().collection('tags');
        const usersRef = firebase.firestore().collection('users');
        const batch = firebase.firestore().batch();

        try {
            for (const cId of selectedCatches) {
                const catchDocRef = catchesRef.doc(cId);
                const catchSnap = await catchDocRef.get();
                if (!catchSnap.exists) {
                    toast.error(`Catch with ID ${cId} does not exist.`);
                    continue;
                }
                const catchData = catchSnap.data();

                if (catchData.recatch) {
                    // Just mark this recatch doc as deleted
                    const userDocRef = usersRef.doc(catchData.user.id);
                    let userData = { updated_at: serverTimestamp() };

                    if (!catchData.recatch_count || catchData.recatch_count <= 1) {
                        userData['recatch_count'] = 0;
                    } else {
                        userData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                    }

                    // Decrement species_count
                    if (catchData.fish_species && catchData.fish_species.id) {
                        userData['species_count'] = firebase.firestore.FieldValue.increment(-1);
                    }

                    batch.update(userDocRef, userData);
                    batch.update(catchDocRef, {
                        updated_at: serverTimestamp(),
                        deleted: true
                    });
                } else {
                    // This is a new catch => we must delete the entire tag + all catches with that tag
                    const tagDocRef = tagsRef.doc(catchData.tag.id);

                    const allCatchesWithTag = await catchesRef.where('tag', '==', tagDocRef).get();
                    allCatchesWithTag.forEach((snapDoc) => {
                        const itemData = snapDoc.data();
                        const itemRef = snapDoc.ref;

                        // Update user
                        const userDocRef = usersRef.doc(itemData.user.id);
                        let userData = { updated_at: serverTimestamp() };

                        if (itemData.recatch) {
                            if (!itemData.recatch_count || itemData.recatch_count <= 1) {
                                userData['recatch_count'] = 0;
                            } else {
                                userData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                            }
                        } else {
                            if (!itemData.catch_count || itemData.catch_count <= 1) {
                                userData['catch_count'] = 0;
                            } else {
                                userData['catch_count'] = firebase.firestore.FieldValue.increment(-1);
                            }
                        }

                        if (itemData.fish_species && itemData.fish_species.id) {
                            userData['species_count'] = firebase.firestore.FieldValue.increment(-1);
                        }

                        batch.update(userDocRef, userData);
                        batch.update(itemRef, { updated_at: serverTimestamp(), deleted: true });
                    });

                    // Mark the tag as deleted
                    batch.update(tagDocRef, {
                        updated_at: serverTimestamp(),
                        deleted: true
                    });
                }
            }

            await batch.commit();
            toast.success('Selected catches have been deleted successfully!');
            setSelectedCatches([]);
            toggleBulkDeleteModal();
            fetchAllCatchesLedger();
        } catch (error) {
            console.error('Error bulk deleting catches:', error);
            toast.error('Could not delete some of the selected catches. Please try again.');
        } finally {
            setIsBulkDeleting(false);
        }
    };

    // ---------------------------
    // COMPONENT RETURN
    // ---------------------------
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Possibly a breadcrumbs here if needed */}
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">All Catches</h4>
                                </CardHeader>

                                <CardBody>
                                    {/* Filter and Action Controls */}
                                    <Row className="g-4 mb-3">
                                        {/* Add Catch Button */}
                                        <Col sm="auto">
                                            <div className="d-flex gap-1">
                                                <Button
                                                    color="success"
                                                    onClick={() => setModalList(true)}
                                                    id="create-btn"
                                                >
                                                    <i className="mdi mdi-map-marker-plus"></i>
                                                </Button>
                                            </div>
                                        </Col>

                                        {/* Bulk Delete Button (NEW) */}
                                        <Col sm="auto">
                                            <Button
                                                color="danger"
                                                onClick={toggleBulkDeleteModal}
                                                disabled={selectedCatches.length === 0}
                                            >
                                                <i className="mdi mdi-delete"></i>
                                            </Button>
                                        </Col>

                                        {/* Search Box */}
                                        <Col className="d-flex align-items-start">
                                            <Input
                                                type="search"
                                                name="search"
                                                id="search"
                                                placeholder="Search Catches..."
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        </Col>

                                        {/* Species Filter (Dropdown) */}
                                        {/* <Col sm="2">
                                            <Input
                                                type="select"
                                                name="species-filter"
                                                id="species-filter"
                                                value={selectedSpeciesFilter}
                                                onChange={(e) => setSelectedSpeciesFilter(e.target.value)}
                                            >
                                                <option value="">All Species</option>
                                                {allSpecies.map(spec => (
                                                    <option key={spec.id} value={spec.id}>
                                                        {spec.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col> */}

                                        {/* OPTIONAL: Text-based species search (commented out if you only want the dropdown) */}
                                        
                                        <Col sm="2">
                                            <Input
                                                type="text"
                                                placeholder="Search species..."
                                                value={speciesSearch}
                                                onChange={(e) => {
                                                    setSpeciesSearch(e.target.value);
                                                    if(e.target.value.trim() !== ''){
                                                        setShowSpeciesDropdown(true);
                                                    } else {
                                                        setShowSpeciesDropdown(false);
                                                    }
                                                }}
                                                onFocus={()=>{
                                                    if(speciesSearch.trim() !== ''){
                                                        setShowSpeciesDropdown(true);
                                                    }
                                                }}
                                            />
                                            {showSpeciesDropdown && (
                                                <div
                                                    style={{
                                                        position:'absolute',
                                                        top:'100%',
                                                        left:0,
                                                        right:0,
                                                        zIndex:999,
                                                        background:'#fff',
                                                        border:'1px solid #ccc',
                                                        maxHeight:'200px',
                                                        overflowY:'auto'
                                                    }}
                                                >
                                                    <div
                                                        className="p-2"
                                                        style={{ cursor:'pointer' }}
                                                        onClick={() => {
                                                            setSelectedSpeciesFilter('');
                                                            setSpeciesSearch('');
                                                            setShowSpeciesDropdown(false);
                                                        }}
                                                    >
                                                        All Species
                                                    </div>
                                                    {allSpecies
                                                        .filter(sp => sp.name.toLowerCase().includes(speciesSearch.toLowerCase()))
                                                        .map(sp => (
                                                            <div
                                                                key={sp.id}
                                                                className="p-2"
                                                                style={{ cursor:'pointer' }}
                                                                onClick={() => {
                                                                    setSelectedSpeciesFilter(sp.id);
                                                                    setSpeciesSearch(sp.name);
                                                                    setShowSpeciesDropdown(false);
                                                                }}
                                                            >
                                                                {sp.name}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )}
                                        </Col>
                                        

                                        {/* Start Date */}
                                        <Col sm="2">
                                            <Flatpickr
                                                id="start-date"
                                                className="form-control"
                                                options={{ dateFormat: "Y-m-d" }}
                                                placeholder="Start Date"
                                                value={startDateFilter}
                                                onChange={(dates) => setStartDateFilter(dates[0] || null)}
                                            />
                                        </Col>
                                        {/* End Date */}
                                        <Col sm="2">
                                            <Flatpickr
                                                id="end-date"
                                                className="form-control"
                                                options={{ dateFormat: "Y-m-d" }}
                                                placeholder="End Date"
                                                value={endDateFilter}
                                                onChange={(dates) => setEndDateFilter(dates[0] || null)}
                                            />
                                        </Col>

                                        {/* Apply Filters Button */}
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button color="secondary" onClick={handleApplyFilters} style={{ width: '100%' }}>
                                                <i className="mdi mdi-filter"></i>
                                            </Button>
                                        </Col>

                                        {/* Download CSV Button */}
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button color="primary" onClick={handleDownloadCSV} style={{ width: '100%' }}>
                                                <i className="mdi mdi-download"></i>
                                            </Button>
                                        </Col>

                                        {/* Upload CSV Button */}
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button
                                                color="info"
                                                onClick={toggleUploadCSVModal}
                                                style={{ width: '100%' }}
                                            >
                                                <i className="mdi mdi-upload"></i>
                                            </Button>
                                        </Col>
                                    </Row>

                                    {/* Catches Table */}
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        {(!isUpdating && !isDeletingRecatch && !isDeletingCatch && !isRestoringRecatch && !isRestoringCatch && !isCsvUploading && !isUploading && !isBulkDeleting) && (
                                            <table className="table align-middle table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        {/* Multi-Select: "Select All" on this page */}
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <Input
                                                                type="checkbox"
                                                                onChange={handleSelectAllOnPage}
                                                                checked={
                                                                    displayedCatches.length > 0 &&
                                                                    displayedCatches.every(c => selectedCatches.includes(c.id))
                                                                }
                                                            />
                                                        </th>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">Catch Name</th>
                                                        <th scope="col">Angler Name</th>
                                                        <th scope="col">Catch Location</th>
                                                        <th scope="col">Tag Number</th>
                                                        <th scope="col">Catch Date</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {displayedCatches.length > 0 ? displayedCatches.map((catchItem) => (
                                                        <tr key={catchItem.id}>
                                                            {/* Per-row checkbox for multi-select */}
                                                            <td>
                                                                <Input
                                                                    type="checkbox"
                                                                    checked={selectedCatches.includes(catchItem.id)}
                                                                    onChange={() => handleSelectCatch(catchItem.id)}
                                                                />
                                                            </td>
                                                            <th scope="row">
                                                                <a className="btn btn-sm btn-success" href={catchItem.link}>
                                                                    <i className="mdi mdi-eye-outline"></i>
                                                                </a>
                                                            </th>
                                                            <td>
                                                                {catchItem.image_url ? (
                                                                    <img
                                                                        className="avatar-sm rounded"
                                                                        alt={catchItem.name}
                                                                        src={catchItem.image_url}
                                                                    />
                                                                ) : (
                                                                    <span>No Image</span>
                                                                )}
                                                            </td>
                                                            <td>{catchItem.name}</td>
                                                            <td>
                                                                <a href={catchItem.user_link} className="fw-medium link-primary">
                                                                    {catchItem.angler}
                                                                </a>
                                                            </td>
                                                            <td>{catchItem.location_string}</td>
                                                            <td>
                                                                <a href={catchItem.tag_link} className="fw-medium link-primary">
                                                                    #{catchItem.tag_number}
                                                                </a>
                                                            </td>
                                                            <td>{catchItem.date}</td>
                                                            <td>
                                                                {catchItem.recatch ? (
                                                                    <span className="badge badge-soft-warning text-uppercase">
                                                                        Re-Catch
                                                                    </span>
                                                                ) : (
                                                                    <span className="badge badge-soft-success text-uppercase">
                                                                        Catch
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    {/* Edit Button */}
                                                                    <Button
                                                                        color="warning"
                                                                        size="sm"
                                                                        onClick={() => toggleEditModal(catchItem.id, allCatchesLedger.findIndex(c => c.id === catchItem.id))}
                                                                    >
                                                                        <i className="mdi mdi-square-edit-outline"></i>
                                                                    </Button>
                                                                    {/* Delete or Restore Buttons */}
                                                                    {catchItem.recatch && !catchItem.deleted && (
                                                                        <Button
                                                                            color="danger"
                                                                            size="sm"
                                                                            onClick={() => toggleDeleteRecatchModal(catchItem.id, allCatchesLedger.findIndex(c => c.id === catchItem.id))}
                                                                        >
                                                                            <i className="mdi mdi-delete-outline"></i>
                                                                        </Button>
                                                                    )}
                                                                    {!catchItem.recatch && !catchItem.deleted && (
                                                                        <Button
                                                                            color="danger"
                                                                            size="sm"
                                                                            onClick={() => toggleDeleteCatchModal(catchItem.id, allCatchesLedger.findIndex(c => c.id === catchItem.id))}
                                                                        >
                                                                            <i className="mdi mdi-delete-outline"></i>
                                                                        </Button>
                                                                    )}
                                                                    {catchItem.recatch && catchItem.deleted && (
                                                                        <Button
                                                                            color="success"
                                                                            size="sm"
                                                                            onClick={() => toggleRestoreRecatchModal(catchItem.id, allCatchesLedger.findIndex(c => c.id === catchItem.id))}
                                                                        >
                                                                            <i className="mdi mdi-history"></i>
                                                                        </Button>
                                                                    )}
                                                                    {!catchItem.recatch && catchItem.deleted && (
                                                                        <Button
                                                                            color="success"
                                                                            size="sm"
                                                                            onClick={() => toggleRestoreCatchModal(catchItem.id, allCatchesLedger.findIndex(c => c.id === catchItem.id))}
                                                                        >
                                                                            <i className="mdi mdi-history"></i>
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )) : (
                                                        <tr>
                                                            <td colSpan="10" className="text-center">No Catches Found!</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        )}

                                        {/* Loader */}
                                        {(isUpdating || isDeletingRecatch || isDeletingCatch || isRestoringRecatch || isRestoringCatch || isCsvUploading || isUploading || isBulkDeleting) && (
                                            <div className="d-flex justify-content-center my-5">
                                                <ThreeCircles
                                                    visible={
                                                        isUpdating ||
                                                        isDeletingRecatch ||
                                                        isDeletingCatch ||
                                                        isRestoringRecatch ||
                                                        isRestoringCatch ||
                                                        isCsvUploading ||
                                                        isUploading ||
                                                        isBulkDeleting
                                                    }
                                                    height="100"
                                                    width="100"
                                                    color="#4192C3"
                                                    ariaLabel="three-circles-loading"
                                                    wrapperStyle={{ margin: "0 auto" }}
                                                    wrapperClass="justify-content-center"
                                                />
                                            </div>
                                        )}

                                        {/* No Results Message */}
                                        {filteredCatches.length === 0 && (
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Catches Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any catches.</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Pagination Controls */}
                                    {pageCount > 1 && (
                                        <div className="d-flex justify-content-end">
                                            <ReactPaginate
                                                previousLabel={"← Previous"}
                                                nextLabel={"Next →"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageChange}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"page-link"}
                                                nextLinkClassName={"page-link"}
                                                disabledClassName={"disabled"}
                                                activeClassName={"active"}
                                                pageLinkClassName={"page-link"}
                                                breakLabel={"..."}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* --------------------------- */}
            {/* Modals */}
            {/* --------------------------- */}

            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={() => toggleEditModal()} centered>
                <form className="tablelist-form" onSubmit={handleUpdateCatch}>
                    <ModalHeader toggle={() => toggleEditModal()}>Edit Catch</ModalHeader>
                    <ModalBody>
                        {!isUpdating && allCatchesLedger[updateCatchIndex] && (
                            <>
                                <FormGroup>
                                    <Label for="name-field">Catch Name*</Label>
                                    <Input
                                        type="text"
                                        name="catch_name"
                                        id="name-field"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        required
                                        defaultValue={allCatchesLedger[updateCatchIndex].name}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="catch-datetime">Catch Date & Time*</Label>
                                    <Flatpickr
                                        id="catch-datetime"
                                        className="form-control"
                                        options={{
                                            enableTime: true,
                                            dateFormat: "Y-m-d H:i",
                                            defaultDate: allCatchesLedger[updateCatchIndex].catch_date.toDate()
                                        }}
                                        placeholder="Select Date & Time"
                                        name="catch_datetime"
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="image-field">Image File</Label>
                                    <Input
                                        type="file"
                                        name="image_file"
                                        id="image-field"
                                        className="form-control"
                                        onChange={(e) => { setUpdatedImage(e.target.files[0]); }}
                                        accept="image/*"
                                    />
                                    {allCatchesLedger[updateCatchIndex].image_url && (
                                        <img
                                            className="avatar-sm rounded mt-2"
                                            alt={allCatchesLedger[updateCatchIndex].name}
                                            src={allCatchesLedger[updateCatchIndex].image_url}
                                        />
                                    )}
                                </FormGroup>

                                <FormGroup>
                                    <Label for="location-string">Full Catch Location*</Label>
                                    <Input
                                        type="text"
                                        id="location-string"
                                        className="form-control"
                                        name="location_string"
                                        placeholder="Toms River"
                                        required
                                        defaultValue={allCatchesLedger[updateCatchIndex].location_string}
                                    />
                                </FormGroup>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="catch-latitude">Latitude*</Label>
                                            <Input
                                                type="number"
                                                id="catch-latitude"
                                                className="form-control"
                                                name="catch_latitude"
                                                placeholder="Enter Latitude*"
                                                step="any"
                                                required
                                                defaultValue={allCatchesLedger[updateCatchIndex].location.latitude}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="catch-longitude">Longitude*</Label>
                                            <Input
                                                type="number"
                                                id="catch-longitude"
                                                className="form-control"
                                                name="catch_longitude"
                                                placeholder="Enter Longitude*"
                                                step="any"
                                                required
                                                defaultValue={allCatchesLedger[updateCatchIndex].location.longitude}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="location-area">Catch Location Area*</Label>
                                            <Input
                                                type="text"
                                                id="location-area"
                                                className="form-control"
                                                name="location_area"
                                                placeholder="Toms River"
                                                required
                                                defaultValue={allCatchesLedger[updateCatchIndex].location_area}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="location-city">Catch Location City*</Label>
                                            <Input
                                                type="text"
                                                id="location-city"
                                                className="form-control"
                                                name="location_city"
                                                placeholder="Atlantic City"
                                                required
                                                defaultValue={allCatchesLedger[updateCatchIndex].location_city}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="state-string">Catch State*</Label>
                                            <Input
                                                type="text"
                                                id="state-string"
                                                className="form-control"
                                                name="state_string"
                                                placeholder="New York"
                                                required
                                                defaultValue={allCatchesLedger[updateCatchIndex].location_state}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="state-code">State Code*</Label>
                                            <Input
                                                type="text"
                                                id="state-code"
                                                className="form-control"
                                                name="state_code"
                                                placeholder="NY"
                                                required
                                                maxLength={2}
                                                defaultValue={allCatchesLedger[updateCatchIndex].location_state_code}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    <Label for="catch-country">Catch Country*</Label>
                                    <Input
                                        type="select"
                                        name="catch_country"
                                        id="catch-country"
                                        required
                                        defaultValue={allCatchesLedger[updateCatchIndex].location_country}
                                    >
                                        <option value="">Select Catch Country</option>
                                        <option value="USA">United States</option>
                                        <option value="CAN">Canada</option>
                                        {/* More countries as needed */}
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="tag-number">Tag Number*</Label>
                                    <Input
                                        type="text"
                                        id="tag-number"
                                        name="tag_number"
                                        className="form-control"
                                        placeholder="Enter Tag Number*"
                                        maxLength={15}
                                        required
                                        defaultValue={allCatchesLedger[updateCatchIndex].tag_number}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="fish-species">Fish Species*</Label>
                                    <Input
                                        type="select"
                                        name="fish_species"
                                        id="fish-species"
                                        required
                                        defaultValue={allCatchesLedger[updateCatchIndex].fish_species.id}
                                    >
                                        <option value="">Select Fish Species*</option>
                                        {allSpecies.map((sp) => (
                                            <option key={sp.id} value={sp.id}>
                                                {sp.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="fish-length">
                                                Fish Length {allCatchesLedger[updateCatchIndex].metric_system ? "(CM)" : "(IN)"}
                                            </Label>
                                            <Input
                                                type="number"
                                                id="fish-length"
                                                className="form-control"
                                                placeholder={`Enter Fish Length (${allCatchesLedger[updateCatchIndex].metric_system ? 'CM' : 'IN'})`}
                                                name="fish_length"
                                                step="any"
                                                defaultValue={allCatchesLedger[updateCatchIndex].length}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="fish-weight">
                                                Fish Weight {allCatchesLedger[updateCatchIndex].metric_system ? "(KG)" : "(LB)"}
                                            </Label>
                                            <Input
                                                type="number"
                                                id="fish-weight"
                                                className="form-control"
                                                placeholder={`Enter Fish Weight (${allCatchesLedger[updateCatchIndex].metric_system ? 'KG' : 'LB'})`}
                                                name="fish_weight"
                                                step="any"
                                                defaultValue={allCatchesLedger[updateCatchIndex].weight}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    <Label for="catch-info">Catch Information</Label>
                                    <Input
                                        type="text"
                                        id="catch-info"
                                        className="form-control"
                                        placeholder="Catch Information"
                                        name="catch_info"
                                        defaultValue={allCatchesLedger[updateCatchIndex].info}
                                    />
                                </FormGroup>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="catch-type">Catch Type*</Label>
                                            <Input
                                                type="select"
                                                name="catch_type"
                                                id="catch-type"
                                                required
                                                defaultValue={allCatchesLedger[updateCatchIndex].recatch}
                                            >
                                                <option value="">Select Catch Type*</option>
                                                <option value="false">New Catch</option>
                                                <option value="true">Re-Catch</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="catch-units">Unit System*</Label>
                                            <Input
                                                type="select"
                                                name="catch_units"
                                                id="catch-units"
                                                required
                                                defaultValue={allCatchesLedger[updateCatchIndex].metric_system}
                                            >
                                                <option value="">Select Units System*</option>
                                                <option value="true">Metric System</option>
                                                <option value="false">Imperial System</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {isUpdating && (
                            <div className="d-flex justify-content-center my-5">
                                <ThreeCircles
                                    visible={isUpdating}
                                    height="100"
                                    width="100"
                                    color="#4192C3"
                                    ariaLabel="three-circles-loading"
                                    wrapperStyle={{ margin: "0 auto" }}
                                    wrapperClass="justify-content-center"
                                />
                            </div>
                        )}
                    </ModalBody>
                    {!isUpdating && (
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => toggleEditModal()}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    color="success"
                                    onClick={handleUpdateCatch}
                                >
                                    Update
                                </Button>
                            </div>
                        </ModalFooter>
                    )}
                </form>
            </Modal>

            {/* Add Catch Modal */}
            <Modal isOpen={modalList} toggle={() => setModalList(!modalList)} centered>
                <form className="tablelist-form" onSubmit={handleAddCatch}>
                    <ModalHeader toggle={() => setModalList(!modalList)}>Add Catch</ModalHeader>
                    <ModalBody>
                        {!isUploading && (
                            <>
                                <FormGroup>
                                    <Label for="add-name">Catch Name*</Label>
                                    <Input
                                        type="text"
                                        name="catch_name"
                                        id="add-name"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="add-catch-datetime">Catch Date & Time*</Label>
                                    <Flatpickr
                                        id="add-catch-datetime"
                                        className="form-control"
                                        options={{
                                            enableTime: true,
                                            dateFormat: "Y-m-d H:i",
                                        }}
                                        placeholder="Select Date & Time"
                                        name="catch_datetime"
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="add-image">Image File</Label>
                                    <Input
                                        type="file"
                                        name="image_file"
                                        id="add-image"
                                        className="form-control"
                                        accept="image/*"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="add-location-string">Full Catch Location*</Label>
                                    <Input
                                        type="text"
                                        id="add-location-string"
                                        className="form-control"
                                        name="location_string"
                                        placeholder="Toms River"
                                        required
                                    />
                                </FormGroup>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="add-catch-latitude">Latitude*</Label>
                                            <Input
                                                type="number"
                                                id="add-catch-latitude"
                                                className="form-control"
                                                name="catch_latitude"
                                                placeholder="Enter Latitude*"
                                                step="any"
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="add-catch-longitude">Longitude*</Label>
                                            <Input
                                                type="number"
                                                id="add-catch-longitude"
                                                className="form-control"
                                                name="catch_longitude"
                                                placeholder="Enter Longitude*"
                                                step="any"
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    <Label for="add-tag-number">Tag Number*</Label>
                                    <Input
                                        type="text"
                                        id="add-tag-number"
                                        name="tag_number"
                                        className="form-control"
                                        placeholder="Enter Tag Number*"
                                        maxLength={15}
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="add-fish-species">Fish Species*</Label>
                                    <Input
                                        type="select"
                                        name="fish_species"
                                        id="add-fish-species"
                                        required
                                    >
                                        <option value="">Select Fish Species*</option>
                                        {allSpecies.map((sp) => (
                                            <option key={sp.id} value={sp.id}>
                                                {sp.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="add-fish-length">
                                                Fish Length {/* Add unit information if needed */}
                                            </Label>
                                            <Input
                                                type="number"
                                                id="add-fish-length"
                                                className="form-control"
                                                placeholder="Enter Fish Length"
                                                name="fish_length"
                                                step="any"
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="add-fish-weight">
                                                Fish Weight {/* Add unit information if needed */}
                                            </Label>
                                            <Input
                                                type="number"
                                                id="add-fish-weight"
                                                className="form-control"
                                                placeholder="Enter Fish Weight*"
                                                name="fish_weight"
                                                step="any"
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    <Label for="add-catch-info">Catch Information</Label>
                                    <Input
                                        type="text"
                                        id="add-catch-info"
                                        className="form-control"
                                        placeholder="Catch Information"
                                        name="catch_info"
                                    />
                                </FormGroup>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="add-catch-type">Catch Type*</Label>
                                            <Input
                                                type="select"
                                                name="catch_type"
                                                id="add-catch-type"
                                                required
                                            >
                                                <option value="">Select Catch Type*</option>
                                                <option value="false">New Catch</option>
                                                <option value="true">Re-Catch</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="add-catch-units">Unit System*</Label>
                                            <Input
                                                type="select"
                                                name="catch_units"
                                                id="add-catch-units"
                                                required
                                            >
                                                <option value="">Select Units System*</option>
                                                <option value="true">Metric System</option>
                                                <option value="false">Imperial System</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    <Label for="add-tag-program">Tagging Program*</Label>
                                    <Input
                                        type="select"
                                        name="tag_program"
                                        id="add-tag-program"
                                        required
                                    >
                                        <option value="">Select Tag Program*</option>
                                        {allTagPrograms.map((prog) => (
                                            <option key={prog.id} value={prog.id}>
                                                {prog.program_name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>

                                <ModalFooter>
                                    <div className="hstack gap-2 justify-content-end">
                                        <Button
                                            type="button"
                                            color="secondary"
                                            onClick={() => setModalList(false)}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="success"
                                            disabled={isUploading}
                                        >
                                            Add Catch
                                        </Button>
                                    </div>
                                </ModalFooter>
                            </>
                        )}
                        {isUploading && (
                            <div className="d-flex justify-content-center my-5">
                                <ThreeCircles
                                    visible={isUploading}
                                    height="100"
                                    width="100"
                                    color="#4192C3"
                                    ariaLabel="three-circles-loading"
                                    wrapperStyle={{ margin: "0 auto" }}
                                    wrapperClass="justify-content-center"
                                />
                            </div>
                        )}
                    </ModalBody>
                </form>
            </Modal>

            {/* Delete Re-Catch Modal */}
            <Modal
                isOpen={deleteRecatchModal}
                toggle={() => toggleDeleteRecatchModal()}
                centered
            >
                <ModalHeader toggle={() => toggleDeleteRecatchModal()}>Delete Re-Catch Data</ModalHeader>
                <ModalBody>
                    {!isDeletingRecatch ? (
                        <>
                            <p>Are you sure you want to delete this re-catch?</p>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <Button type="button" color="light" onClick={() => toggleDeleteRecatchModal()}>
                                    Cancel
                                </Button>
                                <Button type="button" color="danger" onClick={deleteRecatch}>
                                    Delete
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isDeletingRecatch}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>

            {/* Delete Catch Modal */}
            <Modal
                isOpen={deleteCatchModal}
                toggle={() => toggleDeleteCatchModal()}
                centered
            >
                <ModalHeader toggle={() => toggleDeleteCatchModal()}>Delete Catch Data</ModalHeader>
                <ModalBody>
                    {!isDeletingCatch ? (
                        <>
                            <p>This will delete the catch, connected re-catches, and the fish-tag it used!</p>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <Button type="button" color="light" onClick={() => toggleDeleteCatchModal()}>
                                    Cancel
                                </Button>
                                <Button type="button" color="danger" onClick={deleteCatch}>
                                    Delete
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isDeletingCatch}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>

            {/* Restore Re-Catch Modal */}
            <Modal
                isOpen={restoreRecatchModal}
                toggle={() => toggleRestoreRecatchModal()}
                centered
            >
                <ModalHeader toggle={() => toggleRestoreRecatchModal()}>Restore Re-Catch Data</ModalHeader>
                <ModalBody>
                    {!isRestoringRecatch ? (
                        <>
                            <p>Do you want to restore this re-catch?</p>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <Button type="button" color="light" onClick={() => toggleRestoreRecatchModal()}>
                                    Cancel
                                </Button>
                                <Button type="button" color="success" onClick={restoreRecatch}>
                                    Restore
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isRestoringRecatch}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>

            {/* Restore Catch Modal */}
            <Modal
                isOpen={restoreCatchModal}
                toggle={() => toggleRestoreCatchModal()}
                centered
            >
                <ModalHeader toggle={() => toggleRestoreCatchModal()}>Restore Catch Data</ModalHeader>
                <ModalBody>
                    {!isRestoringCatch ? (
                        <>
                            <p>This will restore the catch, connected re-catches, and the fish-tag it used!</p>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <Button type="button" color="light" onClick={() => toggleRestoreCatchModal()}>
                                    Cancel
                                </Button>
                                <Button type="button" color="success" onClick={restoreCatch}>
                                    Restore
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isRestoringCatch}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>

            {/* Upload CSV Modal */}
            <Modal isOpen={uploadCSVModal} toggle={toggleUploadCSVModal} centered>
                <form className="tablelist-form" onSubmit={(e) => { e.preventDefault(); handleUploadCSV(); }}>
                    <ModalHeader toggle={toggleUploadCSVModal}>Import Catches from CSV</ModalHeader>
                    <ModalBody>
                        {!isCsvUploading && (
                            <>
                                <FormGroup>
                                    <Label for="csvFile">Select CSV File</Label>
                                    <Input
                                        type="file"
                                        name="csvFile"
                                        id="csvFile"
                                        accept=".csv"
                                        required
                                        onChange={handleCsvFileChange}
                                    />
                                </FormGroup>
                                <p className="text-muted">
                                    The CSV file should contain the following columns:
                                    <ul>
                                        <li><strong>tag_number</strong> (String)</li>
                                        <li><strong>first_angler_email</strong> (String - User's Email Address, Optional)</li>
                                        <li><strong>catch_date</strong> (String - e.g., "2023-10-15 14:30")</li>
                                        <li><strong>fish_color</strong> (String, Optional)</li>
                                        <li><strong>fish_species</strong> (String - ID of the Fish Species)</li>
                                        <li><strong>image_url</strong> (String, Optional)</li>
                                        <li><strong>info</strong> (String, Optional)</li>
                                        <li><strong>length</strong> (Number)</li>
                                        <li><strong>weight</strong> (Number)</li>
                                        <li><strong>location_lat</strong> (Number)</li>
                                        <li><strong>location_lng</strong> (Number)</li>
                                        <li><strong>location_area</strong> (String)</li>
                                        <li><strong>location_city</strong> (String)</li>
                                        <li><strong>location_state</strong> (String)</li>
                                        <li><strong>location_state_code</strong> (String)</li>
                                        <li><strong>location_country</strong> (String)</li>
                                        <li><strong>location_string</strong> (String)</li>
                                        <li><strong>metric_system</strong> (Boolean - "true" or "false")</li>
                                        <li><strong>name</strong> (String)</li>
                                        <li><strong>recatch</strong> (Boolean - "true" or "false")</li>
                                        <li><strong>species_name</strong> (String, Optional)</li>
                                        <li><strong>user_name</strong> (String, Optional)</li>
                                    </ul>
                                </p>
                            </>
                        )}
                        {isCsvUploading && (
                            <div className="d-flex flex-column align-items-center">
                                <ThreeCircles
                                    visible={isCsvUploading}
                                    height="100"
                                    width="100"
                                    color="#4192C3"
                                    ariaLabel="three-circles-loading"
                                    wrapperStyle={{ margin: "0 auto" }}
                                    wrapperClass="justify-content-center"
                                />
                                <p className="mt-3">Uploading CSV and processing data...</p>
                                <div className="w-100 mt-3">
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: `${uploadProgress}%` }}
                                            aria-valuenow={uploadProgress}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            {uploadProgress}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </ModalBody>
                    {!isCsvUploading && (
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={toggleUploadCSVModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                >
                                    Upload CSV
                                </Button>
                            </div>
                        </ModalFooter>
                    )}
                </form>
            </Modal>

            {/* Bulk Delete Confirmation Modal (NEW) */}
            <Modal
                isOpen={bulkDeleteModal}
                toggle={toggleBulkDeleteModal}
                className="modal zoomIn"
                id="bulkDeleteModal"
                centered
            >
                {!isBulkDeleting ? (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Delete Selected Catches</h4>
                                <p className="text-muted mx-4 mb-0">
                                    Are you sure you want to delete the selected catches?
                                </p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={toggleBulkDeleteModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                onClick={deleteSelectedCatches}
                            >
                                Delete Selected
                            </Button>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center">
                            <ThreeCircles
                                visible={isBulkDeleting}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Toast Container for Notifications */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </React.Fragment>
    );

};

export default AllCatches;