// ActiveUsers.jsx

import React, { useRef, useState, useEffect, useMemo } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    CardTitle,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    ModalHeader,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { ThreeCircles } from 'react-loader-spinner';
import Flatpickr from "react-flatpickr";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import { serverTimestamp, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, getStorage, ref } from "firebase/storage";

const ActiveUsers = () => {

    const storage = getStorage();

    // ---------------------------
    // STATE VARIABLES
    // ---------------------------
    const [initialLoad, setInitialLoad] = useState(true);
    const [activeUsersList, setActiveUsersList] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    // Partner access checkboxes
    const [partnerAccessCreate, setPartnerAccessCreate] = useState(false);
    const handleCreateCheckboxChange = (event) => {
        setPartnerAccessCreate(event.target.checked);
    };

    const [partnerAccessUpdate, setPartnerAccessUpdate] = useState(false);
    const handleUpdateCheckboxChange = (event) => {
        setPartnerAccessUpdate(event.target.checked);
    };

    // Action buttons states
    const [isCreating, setIsCreating] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateUserId, setUpdateUserId] = useState('');
    const [updateUserIndex, setUpdateUserIndex] = useState(null);
    const [deleteUserId, setDeleteUserId] = useState('');
    const [deleteUserIndex, setDeleteUserIndex] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [updatedImage, setUpdatedImage] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);

    const [allTagPrograms, setAllTagPrograms] = useState([]);

    // Date Filters
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);

    // Modal Visibility States
    const [createModal, setCreateModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    // Reference for Firestore Listener
    const subscriptionRef = useRef(null);

    // ---------------------------
    // DOCUMENT TITLE
    // ---------------------------
    useEffect(() => {
        document.title = "Registrations | Fish-Trak - Internal Management Dashboard";
    }, []);

    // ---------------------------
    // FETCH TAG PROGRAMS
    // ---------------------------
    const fetchTagPrograms = () => {
        firebase
            .firestore()
            .collection('programs')
            .orderBy('created_at', 'desc')
            .onSnapshot(
                querySnapshot => {
                    const newProgramsList = [];
                    querySnapshot.forEach(doc => {
                        const programItem = doc.data();
                        programItem.id = doc.id;

                        programItem.name = programItem.program_name;
                        programItem.region = programItem.program_region;
                        programItem.country = programItem.country_code;
                        programItem.type = programItem.program_type;
                        programItem.length = programItem.tag_length;
                        programItem.image = programItem.image_url;

                        const createdAt = programItem && programItem.created_at
                            ? programItem.created_at.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                            : '';
                        programItem.created = createdAt;
                        const updatedAt = programItem && programItem.updated_at
                            ? programItem.updated_at.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                            : '';
                        programItem.updated = updatedAt;

                        newProgramsList.push(programItem);
                    });
                    setAllTagPrograms(newProgramsList);
                },
                error => {
                    console.log("Error fetching tag programs:", error);
                }
            );
    };

    // ---------------------------
    // FETCH ACTIVE USERS WITH FILTERS
    // ---------------------------
    const fetchActiveUsers = () => {
        // Unsubscribe previous listener if any
        if (subscriptionRef.current) {
            subscriptionRef.current();
        }

        // Build Firestore query
        let newQuery = firebase.firestore()
            .collection('users')
            .where('active', '==', true)
            .orderBy('created_at', 'desc');

        // Apply date filters
        if (startDateFilter && endDateFilter) {
            newQuery = newQuery
                .where('created_at', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
                .where('created_at', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        }
        else if (startDateFilter && !endDateFilter) {
            newQuery = newQuery
                .where('created_at', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
        }
        else if (!startDateFilter && endDateFilter) {
            newQuery = newQuery
                .where('created_at', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        }

        // Listen to real-time updates
        const unsub = newQuery.onSnapshot(
            (querySnapshot) => {
                const newUsersList = [];
                querySnapshot.forEach(doc => {
                    const userItem = doc.data();
                    userItem.id = doc.id;

                    userItem.name = `${userItem.first_name} ${userItem.last_name}`;
                    userItem.email = userItem.email_address;
                    userItem.port = userItem.home_port;
                    const createdAt = userItem && userItem.created_at
                        ? userItem.created_at.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                        : '';
                    userItem.created = createdAt;
                    const updatedAt = userItem && userItem.updated_at
                        ? userItem.updated_at.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                        : '';
                    userItem.updated = updatedAt;
                    userItem.count = userItem.catch_count;
                    userItem.status = userItem.active;
                    userItem.link = `/user/details/${userItem.id}`;
                    userItem.mailtoLink = `mailto:${userItem.email_address}?subject=Fish-Trak Admin`;

                    newUsersList.push(userItem);
                });
                setActiveUsersList(newUsersList);
                setFetchedData(true);
            },
            (error) => {
                console.error("Error fetching active users:", error);
            }
        );

        subscriptionRef.current = unsub;
    };

    // ---------------------------
    // INITIAL DATA FETCHING
    // ---------------------------
    useEffect(() => {
        if (initialLoad) {
            setInitialLoad(false);
            fetchActiveUsers();
            fetchTagPrograms();
        }
    }, [initialLoad]);

    // ---------------------------
    // SEARCH AND FILTER LOGIC
    // ---------------------------
    const [searchQuery, setSearchQuery] = useState('');

    const filteredUsers = useMemo(() => {
        return activeUsersList.filter(user => {
            const nameMatch = user.name.toLowerCase().includes(searchQuery.toLowerCase());
            const emailMatch = user.email.toLowerCase().includes(searchQuery.toLowerCase());
            const portMatch = user.port.toLowerCase().includes(searchQuery.toLowerCase());
            return nameMatch || emailMatch || portMatch;
        });
    }, [activeUsersList, searchQuery]);

    // ---------------------------
    // PAGINATION CALCULATIONS
    // ---------------------------
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const pageCount = Math.ceil(filteredUsers.length / itemsPerPage);
    const displayedUsers = filteredUsers.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    // ---------------------------
    // HANDLE PAGE CHANGE
    // ---------------------------
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // ---------------------------
    // HANDLE APPLY FILTERS BUTTON
    // ---------------------------
    const handleApplyFilters = () => {
        fetchActiveUsers();
        setCurrentPage(0); // Reset to first page when filters are applied
    };

    // ---------------------------
    // HANDLE DOWNLOAD CSV
    // ---------------------------
    const handleDownloadCSV = async () => {
        try {
            let csvQuery = firebase.firestore()
                .collection('users')
                .where('active', '==', true)
                .orderBy('created_at', 'desc');

            // Apply date filters
            if (startDateFilter && endDateFilter) {
                csvQuery = csvQuery
                    .where('created_at', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
                    .where('created_at', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }
            else if (startDateFilter && !endDateFilter) {
                csvQuery = csvQuery
                    .where('created_at', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
            }
            else if (!startDateFilter && endDateFilter) {
                csvQuery = csvQuery
                    .where('created_at', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }

            const snapshot = await csvQuery.get();
            const csvRows = [];
            // Header row
            csvRows.push([
                'User ID',
                'First Name',
                'Last Name',
                'Email',
                'Home Port',
                'Catch Count',
                'Created Date',
                'Created Time',
                'Updated Date',
                'Updated Time',
                'Status'
            ].join(','));

            snapshot.forEach(doc => {
                const data = doc.data();
                const row = [
                    doc.id,
                    (data.first_name || '').replace(/,/g, ''),
                    (data.last_name || '').replace(/,/g, ''),
                    (data.email_address || '').replace(/,/g, ''),
                    (data.home_port || '').replace(/,/g, ''),
                    typeof data.catch_count !== 'undefined' ? data.catch_count : 0,
                    data.created,
                    data.updated,
                    data.active ? 'Active' : 'Inactive'
                ];
                csvRows.push(row.join(','));
            });

            const csvString = csvRows.join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'filtered_active_users.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error generating CSV:', error);
            toast.error('Could not download CSV. Please try again.');
        }
    };

    // ---------------------------
    // CRUD OPERATIONS
    // ---------------------------

    // Handle Create User Form Submission
    const handleCreateUser = (event) => {
        event.preventDefault();
        submitCreateForm(event);
    };

    const submitCreateForm = async (event) => {
        setIsUploading(true);
        setIsCreating(true);
        event.preventDefault();

        if (uploadedImage) {
            await uploadImage(event);
        } else {
            await createUser(event);
        }
    };

    const uploadImage = async (event) => {
        event.preventDefault();
        const emailAddress = event.target.email_address.value.replace(/[^a-zA-Z0-9+-_~!#$%&'./=^|{}"]/g, '');
        const timeStamp = Date.now();
        const imageFileName = `user-avatars/${emailAddress}-${timeStamp}`;

        const storageRef = ref(storage, imageFileName);

        const uploadTask = uploadBytesResumable(storageRef, uploadedImage);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
            },
            (error) => {
                console.log(error);
                setIsUploading(false);
                setIsCreating(false);
                toast.error('Could not upload user avatar. Please try again.');
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
                    createUser(event, downloadedURL);
                });
            }
        );
    };

    const generateRandomPassword = () => {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
        const length = 12;
        return Array.from({ length }, () => charset[Math.floor(Math.random() * charset.length)]).join("");
    };

    const createUser = async (event, downloadedURL = null) => {
        setIsUploading(false);
        setIsCreating(true);

        const emailAddress = event.target.email_address.value.replace(/[^a-zA-Z0-9+-_~!#$%&@'./=^|{}"]/g, '');
        const userPassword = generateRandomPassword();
        const locationGeo = new firebase.firestore.GeoPoint(
            parseFloat(event.target.home_port_lat.value.replace(/[^0-9.-]/g, '')),
            parseFloat(event.target.home_port_lng.value.replace(/[^0-9.-]/g, ''))
        );

        const avatarPlaceholderImage = 'https://firebasestorage.googleapis.com/v0/b/fish-trak-app.appspot.com/o/program-logos%2Ffish-trak-program-logo.png?alt=media&token=8ef8bf14-1c21-4dd1-8ffc-6f860f41dfa6';

        try {
            const userCredential = await firebase.auth().createUserWithEmailAndPassword(emailAddress, userPassword);
            await userCredential.user.sendEmailVerification();
            const uid = userCredential.user.uid;

            // Prepare user data
            const userData = {
                id: uid,
                account_type: 1,
                active: true,
                approved: true,
                verified: false,
                onboarded: false,
                catch_count: 0,
                created_at: serverTimestamp(),
                recatch_count: 0,
                species_count: 0,
                signup_location: locationGeo,
                text_messages: true,
                updated_at: serverTimestamp(),
                privacy_mode: false,
                home_port_geo: locationGeo,
                email_address: emailAddress,
                email_updates: true,
                text_messages: true,
                recatch_alerts: true,
                notifications: true,

                first_name: event.target.first_name.value.replace(/[^a-zA-Z&-,.' ]/g, ''),
                last_name: event.target.last_name.value.replace(/[^a-zA-Z&-,.' ]/g, ''),
                mobile_number: event.target.mobile_number.value.replace(/[^0-9+]/g, ''),
                home_port: event.target.home_port.value.replace(/[^a-zA-Z&()-+',./' ]/g, ''),
                country_code: event.target.user_country.value.replace(/[^a-zA-Z]/g, ''),
                metric_system: JSON.parse(event.target.units.value),
                admin_user: event.target.admin_access.checked,
                partner_user: event.target.partner_access.checked,
                image_url: downloadedURL ? downloadedURL : avatarPlaceholderImage
            };

            // If Partner Access is enabled, assign program_access
            if (userData.partner_user && event.target.tag_program.value) {
                userData.program_access = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
            }

            // Add user to Firestore
            await firebase.firestore().collection('users').doc(uid).set(userData);

            // Send Password Reset Email
            await firebase.auth().sendPasswordResetEmail(emailAddress);

            toast.success('The user has been created successfully!');
            fetchActiveUsers();
            setCreateModal(false);
            setIsCreating(false);
        } catch (error) {
            console.error("Error creating user:", error);
            setIsUploading(false);
            setIsCreating(false);
            if (error.code === 'auth/email-already-in-use') {
                toast.error('That email address is already registered. Please use a different email.');
            }
            else {
                toast.error('Could not create account. Please try again.');
            }
        }
    };

    // ---------------------------
    // Handle Update User Form Submission
    // ---------------------------
    const handleUpdateUser = (event) => {
        event.preventDefault();
        submitUpdateForm(event);
    };

    const submitUpdateForm = async (event) => {
        setIsUploading(true);
        setIsUpdating(true);
        event.preventDefault();

        if (updatedImage) {
            await updateImage(event);
        } else {
            await updateUser(event);
        }
    };

    const updateImage = async (event) => {
        event.preventDefault();
        const imageFileName = `user-avatars/${activeUsersList[updateUserIndex].id}`;
        const storageRef = ref(storage, imageFileName);

        const uploadTask = uploadBytesResumable(storageRef, updatedImage);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
            },
            (error) => {
                console.log(error);
                setIsUpdating(false);
                setIsUploading(false);
                toast.error('Could not update user avatar. Please try again.');
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
                    updateUser(event, downloadedURL);
                });
            }
        );
    };

    const updateUser = async (event, downloadedURL = null) => {
        // Prepare user data
        const userData = {
            updated_at: serverTimestamp(),
        };

        // User information
        if (event.target.first_name.value !== activeUsersList[updateUserIndex].first_name) {
            userData['first_name'] = event.target.first_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
        }
        if (event.target.last_name.value !== activeUsersList[updateUserIndex].last_name) {
            userData['last_name'] = event.target.last_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
        }

        // Contact details
        if (event.target.email_address.value !== activeUsersList[updateUserIndex].email_address) {
            userData['email_address'] = event.target.email_address.value.replace(/[^a-zA-Z0-9+-_~!#$%&@'./=^|{}"]/g, '');
        }
        if (event.target.mobile_number.value !== activeUsersList[updateUserIndex].mobile_number) {
            userData['mobile_number'] = event.target.mobile_number.value.replace(/[^0-9+]/g, '');
        }

        // Location data
        if (event.target.home_port.value !== activeUsersList[updateUserIndex].home_port) {
            userData['home_port'] = event.target.home_port.value.replace(/[^a-zA-Z&()-+',./' ]/g, '');
        }
        if (
            parseFloat(event.target.home_port_lat.value) !== activeUsersList[updateUserIndex].home_port_geo.latitude ||
            parseFloat(event.target.home_port_lng.value) !== activeUsersList[updateUserIndex].home_port_geo.longitude
        ) {
            userData['home_port_geo'] = new firebase.firestore.GeoPoint(
                parseFloat(event.target.home_port_lat.value.replace(/[^0-9.-]/g, '')),
                parseFloat(event.target.home_port_lng.value.replace(/[^0-9.-]/g, ''))
            );
        }
        if (event.target.user_country.value !== activeUsersList[updateUserIndex].country_code) {
            userData['country_code'] = event.target.user_country.value.replace(/[^a-zA-Z]/g, '');
        }

        // Metric counts 
        if (parseInt(event.target.catch_count.value) !== activeUsersList[updateUserIndex].catch_count) {
            userData['catch_count'] = parseInt(event.target.catch_count.value) || 0;
        }
        if (parseInt(event.target.recatch_count.value) !== activeUsersList[updateUserIndex].recatch_count) {
            userData['recatch_count'] = parseInt(event.target.recatch_count.value) || 0;
        }
        if (parseInt(event.target.species_count.value) !== activeUsersList[updateUserIndex].species_count) {
            userData['species_count'] = parseInt(event.target.species_count.value) || 0;
        }

        // Account Status
        if (event.target.units.value !== String(activeUsersList[updateUserIndex].metric_system)) {
            userData['metric_system'] = JSON.parse(event.target.units.value);
        }
        if (event.target.active.checked !== activeUsersList[updateUserIndex].active) {
            userData['active'] = event.target.active.checked;
        }
        if (event.target.approved.checked !== activeUsersList[updateUserIndex].approved) {
            userData['approved'] = event.target.approved.checked;
        }
        if (event.target.onboarded.checked !== activeUsersList[updateUserIndex].onboarded) {
            userData['onboarded'] = event.target.onboarded.checked;
        }
        if (event.target.verified.checked !== activeUsersList[updateUserIndex].verified) {
            userData['verified'] = event.target.verified.checked;
        }

        // Notifications
        if (event.target.email_updates.checked !== activeUsersList[updateUserIndex].email_updates) {
            userData['email_updates'] = event.target.email_updates.checked;
        }
        if (event.target.text_messages.checked !== activeUsersList[updateUserIndex].text_messages) {
            userData['text_messages'] = event.target.text_messages.checked;
        }
        if (event.target.recatch_alerts.checked !== activeUsersList[updateUserIndex].recatch_alerts) {
            userData['recatch_alerts'] = event.target.recatch_alerts.checked;
        }
        if (event.target.push_notifications.checked !== activeUsersList[updateUserIndex].notifications) {
            userData['notifications'] = event.target.push_notifications.checked;
        }

        // Permissions
        if (event.target.admin_access.checked !== activeUsersList[updateUserIndex].admin_user) {
            userData['admin_user'] = event.target.admin_access.checked;
        }
        if (event.target.partner_access.checked !== activeUsersList[updateUserIndex].partner_user) {
            userData['partner_user'] = event.target.partner_access.checked;
        }
        if (event.target.privacy_mode.checked !== activeUsersList[updateUserIndex].privacy_mode) {
            userData['privacy_mode'] = event.target.privacy_mode.checked;
        }

        // Program Access
        if (partnerAccessUpdate) {
            if (event.target.tag_program.value !== activeUsersList[updateUserIndex].program_access?.id) {
                userData['program_access'] = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
            }
        } else {
            userData['program_access'] = null;
        }

        // New Image URL
        if (downloadedURL) {
            userData['image_url'] = downloadedURL;
        }

        try {
            const userRef = firebase.firestore().collection('users').doc(activeUsersList[updateUserIndex].id);
            await userRef.update(userData);

            // Handle program_access updates
            if (partnerAccessUpdate) {
                if (activeUsersList[updateUserIndex].program_access && event.target.tag_program.value !== activeUsersList[updateUserIndex].program_access.id) {
                    // Remove from old program
                    await activeUsersList[updateUserIndex].program_access.update({
                        program_managers: arrayRemove(userRef)
                    });
                    // Add to new program
                    const newProgramRef = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
                    await newProgramRef.update({
                        program_managers: arrayUnion(userRef)
                    });
                } else if (!activeUsersList[updateUserIndex].program_access && event.target.tag_program.value) {
                    // Add to new program
                    const newProgramRef = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
                    await newProgramRef.update({
                        program_managers: arrayUnion(userRef)
                    });
                }
            } else if (activeUsersList[updateUserIndex].program_access && !partnerAccessUpdate) {
                // Remove from old program
                await activeUsersList[updateUserIndex].program_access.update({
                    program_managers: arrayRemove(userRef)
                });
            }

            toast.success('The user has been updated successfully!');
            fetchActiveUsers();
            setIsUpdating(false);
            setIsUploading(false);
            setEditModal(false);
        } catch (error) {
            console.error("Error updating user:", error);
            setIsUpdating(false);
            setIsUploading(false);
            toast.error('Could not update user entry. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE DELETE USER
    // ---------------------------
    const disableUser = async () => {
        setIsDeleting(true);

        try {
            const userRef = firebase.firestore().collection('users').doc(activeUsersList[deleteUserIndex].id);
            await userRef.update({ active: false });
            toast.success('The user account has been disabled successfully!');
            fetchActiveUsers();
            setDeleteUserId('');
            setDeleteUserIndex(null);
            setIsDeleting(false);
            setDeleteModal(false);
        } catch (error) {
            console.error("Error disabling user:", error);
            setIsDeleting(false);
            toast.error('Could not disable user. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE EDIT USER MODAL TOGGLE
    // ---------------------------
    const openEditModal = (userId, userIndex) => {
        setUpdateUserId(userId);
        setUpdateUserIndex(userIndex);
        if (activeUsersList[userIndex] && activeUsersList[userIndex].partner_user) {
            setPartnerAccessUpdate(activeUsersList[userIndex].partner_user);
        }
        setEditModal(true);
    };

    const closeEditModal = () => {
        setUpdateUserId('');
        setUpdateUserIndex(null);
        setUpdatedImage(null);
        setPartnerAccessUpdate(false);
        setEditModal(false);
    };

    // ---------------------------
    // HANDLE DELETE USER MODAL TOGGLE
    // ---------------------------
    const openDeleteModal = (userId, userIndex) => {
        setDeleteUserId(userId);
        setDeleteUserIndex(userIndex);
        setDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setDeleteUserId('');
        setDeleteUserIndex(null);
        setDeleteModal(false);
    };

    // ---------------------------
    // HANDLE CREATE USER MODAL TOGGLE
    // ---------------------------
    const openCreateModal = () => {
        setCreateModal(true);
    };

    const closeCreateModal = () => {
        setCreateModal(false);
        setPartnerAccessCreate(false);
        setUploadedImage(null);
    };

    // ---------------------------
    // SEARCH AND FILTER EFFECTS
    // ---------------------------
    useEffect(() => {
        if (fetchedData && activeUsersList.length > 0) {
            setFetchedData(false);
        }
    }, [fetchedData, activeUsersList]);

    // ---------------------------
    // COMPONENT RETURN
    // ---------------------------
    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content">
                <Container fluid>
                    <CardTitle>Registrations</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Active Users</h4>
                                </CardHeader>

                                <CardBody>
                                    {/* Filter and Action Controls */}
                                    <Row className="g-4 mb-3">
                                        {/* Add User Button */}
                                        <Col sm="auto">
                                            <div className="d-flex gap-1">
                                                <Button
                                                    color="success"
                                                    onClick={openCreateModal}
                                                    id="create-btn"
                                                >
                                                    <i className="ri-add-line align-bottom me-1"></i> Add New User
                                                </Button>
                                            </div>
                                        </Col>
                                        {/* Search Box */}
                                        <Col className="d-flex align-items-start">
                                                <Input
                                                    type="search"
                                                    name="search"
                                                    id="search"
                                                    placeholder="Search Anglers..."
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                        </Col>
                                        {/* Start Date */}
                                        <Col sm="2">
                                                <Flatpickr
                                                    id="start-date"
                                                    className="form-control"
                                                    options={{ dateFormat: "Y-m-d" }}
                                                    placeholder="Start Date"
                                                    value={startDateFilter}
                                                    onChange={(dates) => setStartDateFilter(dates[0] || null)}
                                                />
                                        </Col>
                                        {/* End Date */}
                                        <Col sm="2">
                                                <Flatpickr
                                                    id="end-date"
                                                    className="form-control"
                                                    options={{ dateFormat: "Y-m-d" }}
                                                    placeholder="End Date"
                                                    value={endDateFilter}
                                                    onChange={(dates) => setEndDateFilter(dates[0] || null)}
                                                />
                                        </Col>
                                        {/* Apply Filters Button */}
                                        <Col sm="1" md="1" className="d-flex align-items-end">
                                            <Button
                                                color="secondary"
                                                onClick={handleApplyFilters}
                                                style={{ width: '100%' }}
                                            >
                                                <i className="mdi mdi-filter"></i>
                                            </Button>
                                        </Col>
                                        {/* Download CSV Button */}
                                        <Col sm="1" md="1" className="d-flex align-items-end">
                                            <Button
                                                color="primary"
                                                onClick={handleDownloadCSV}
                                                style={{ width: '100%' }}
                                            >
                                                <i className="mdi mdi-download"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                    {/* End Filter and Action Controls */}

                                    {/* Users Table */}
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        {(!isUpdating && !isDeleting && !isCreating) && (
                                            <table className="table align-middle table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">User Name</th>
                                                        <th scope="col">Email Address</th>
                                                        <th scope="col">Home Port</th>
                                                        <th scope="col">Catches</th>
                                                        <th scope="col">Created Date</th>
                                                        <th scope="col">Last Active</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {displayedUsers.length > 0 ? displayedUsers.map((user, index) => (
                                                        <tr key={user.id}>
                                                            <th scope="row">
                                                                <Link to={user.link} className="btn btn-sm btn-success" title="View User">
                                                                    <i className="mdi mdi-eye-outline"></i>
                                                                </Link>
                                                            </th>
                                                            <td>
                                                                {user.image_url ? (
                                                                    <img
                                                                        className="avatar-sm rounded-circle"
                                                                        alt={user.name}
                                                                        src={user.image_url}
                                                                    />
                                                                ) : (
                                                                    <span>No Image</span>
                                                                )}
                                                            </td>
                                                            <td>{user.name}</td>
                                                            <td>
                                                                <a href={user.mailtoLink} className="email-link">
                                                                    {user.email}
                                                                </a>
                                                            </td>
                                                            <td>{user.port}</td>
                                                            <td>
                                                                <span className="badge badge-soft-success text-uppercase">
                                                                    {user.count}
                                                                </span>
                                                            </td>
                                                            <td>{user.created}</td>
                                                            <td>{user.updated}</td>
                                                            <td>
                                                                {user.status ? (
                                                                    <span className="badge badge-soft-success text-uppercase">Active</span>
                                                                ) : (
                                                                    <span className="badge badge-soft-danger text-uppercase">Inactive</span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    {/* Email Button */}
                                                                    <Button
                                                                        color="primary"
                                                                        size="sm"
                                                                        onClick={() => window.location.href = user.mailtoLink}
                                                                        title="Email User"
                                                                    >
                                                                        <i className="mdi mdi-email-send"></i>
                                                                    </Button>
                                                                    {/* Edit Button */}
                                                                    <Button
                                                                        color="warning"
                                                                        size="sm"
                                                                        onClick={() => openEditModal(user.id, index)}
                                                                        title="Edit User"
                                                                    >
                                                                        <i className="mdi mdi-square-edit-outline"></i>
                                                                    </Button>
                                                                    {/* Disable Button */}
                                                                    {user.status && (
                                                                        <Button
                                                                            color="danger"
                                                                            size="sm"
                                                                            onClick={() => openDeleteModal(user.id, index)}
                                                                            title="Disable User"
                                                                        >
                                                                            <i className="mdi mdi-account-off-outline"></i>
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )) : (
                                                        <tr>
                                                            <td colSpan="10" className="text-center">No Active Users Found!</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        )}

                                        {/* Loader */}
                                        {(isUpdating || isDeleting || isCreating) && (
                                            <div className="d-flex justify-content-center my-5">
                                                <ThreeCircles
                                                    visible={isUpdating || isDeleting || isCreating}
                                                    height="100"
                                                    width="100"
                                                    color="#4192C3"
                                                    ariaLabel="three-circles-loading"
                                                    wrapperStyle={{ margin: "0 auto" }}
                                                    wrapperClass="justify-content-center"
                                                />
                                            </div>
                                        )}

                                        {/* No Results Message */}
                                        {filteredUsers.length === 0 && !isUpdating && !isDeleting && !isCreating && (
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Active Users Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any active users.</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/* End Users Table */}

                                    {/* Pagination Controls */}
                                    {pageCount > 1 && (
                                        <div className="d-flex justify-content-end">
                                            <ReactPaginate
                                                previousLabel={"← Previous"}
                                                nextLabel={"Next →"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageChange}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"page-link"}
                                                nextLinkClassName={"page-link"}
                                                disabledClassName={"disabled"}
                                                activeClassName={"active"}
                                                pageLinkClassName={"page-link"}
                                                breakLabel={"..."}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* --------------------------- */}
            {/* Modals */}
            {/* --------------------------- */}

            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={closeEditModal} centered>
                {!isUpdating && activeUsersList[updateUserIndex] && (
                    <form className="tablelist-form" onSubmit={handleUpdateUser}>
                        <ModalHeader toggle={closeEditModal}>Edit User</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="first-name">First Name*</Label>
                                <Input
                                    type="text"
                                    name="first_name"
                                    id="first-name"
                                    placeholder="Richard"
                                    required
                                    defaultValue={activeUsersList[updateUserIndex].first_name}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="last-name">Last Name*</Label>
                                <Input
                                    type="text"
                                    name="last_name"
                                    id="last-name"
                                    placeholder="Ryan"
                                    required
                                    defaultValue={activeUsersList[updateUserIndex].last_name}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email Address*</Label>
                                <Input
                                    type="email"
                                    name="email_address"
                                    id="email"
                                    placeholder="rich@fish-trak.com"
                                    required
                                    defaultValue={activeUsersList[updateUserIndex].email_address}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="mobile-number">Mobile Number*</Label>
                                <Input
                                    type="tel"
                                    name="mobile_number"
                                    id="mobile-number"
                                    placeholder="+14151234567"
                                    required
                                    defaultValue={activeUsersList[updateUserIndex].mobile_number}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="avatar">User Avatar*</Label>
                                <Row>
                                    <Col xs="4">
                                        {activeUsersList[updateUserIndex].image_url ? (
                                            <img
                                                className="avatar-sm rounded-circle"
                                                alt={activeUsersList[updateUserIndex].name}
                                                src={activeUsersList[updateUserIndex].image_url}
                                            />
                                        ) : (
                                            <span>No Image</span>
                                        )}
                                    </Col>
                                    <Col xs="8">
                                        <Input
                                            type="file"
                                            name="image_link"
                                            id="avatar"
                                            className="form-control"
                                            onChange={(e) => { setUpdatedImage(e.target.files[0]); }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Label for="home-port">Home Port Location*</Label>
                                <Input
                                    type="text"
                                    name="home_port"
                                    id="home-port"
                                    placeholder="Toms River"
                                    required
                                    defaultValue={activeUsersList[updateUserIndex].home_port}
                                />
                            </FormGroup>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="home-port-lat">Home Port Latitude*</Label>
                                        <Input
                                            type="number"
                                            name="home_port_lat"
                                            id="home-port-lat"
                                            placeholder="Enter Latitude*"
                                            step="any"
                                            required
                                            defaultValue={activeUsersList[updateUserIndex].home_port_geo.latitude}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="home-port-lng">Home Port Longitude*</Label>
                                        <Input
                                            type="number"
                                            name="home_port_lng"
                                            id="home-port-lng"
                                            placeholder="Enter Longitude*"
                                            step="any"
                                            required
                                            defaultValue={activeUsersList[updateUserIndex].home_port_geo.longitude}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label for="user-country">User Country*</Label>
                                <Input
                                    type="select"
                                    name="user_country"
                                    id="user-country"
                                    required
                                    defaultValue={activeUsersList[updateUserIndex].country_code}
                                >
                                    <option value="">Select User Country</option>
                                    <option value="USA">United States</option>
                                    <option value="CAN">Canada</option>
                                    {/* Add more countries as needed */}
                                </Input>
                            </FormGroup>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="catch-count">Catch Count*</Label>
                                        <Input
                                            type="number"
                                            name="catch_count"
                                            id="catch-count"
                                            placeholder="Enter Catch Count*"
                                            required
                                            defaultValue={activeUsersList[updateUserIndex].catch_count}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="recatch-count">Recatch Count*</Label>
                                        <Input
                                            type="number"
                                            name="recatch_count"
                                            id="recatch-count"
                                            placeholder="Enter Recatch Count*"
                                            required
                                            defaultValue={activeUsersList[updateUserIndex].recatch_count}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="species-count">Species Count*</Label>
                                        <Input
                                            type="number"
                                            name="species_count"
                                            id="species-count"
                                            placeholder="Enter Species Count*"
                                            required
                                            defaultValue={activeUsersList[updateUserIndex].species_count}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label>Units System*</Label>
                                <div>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="units"
                                                value="true"
                                                defaultChecked={activeUsersList[updateUserIndex].metric_system}
                                            />{' '}
                                            Metric
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="units"
                                                value="false"
                                                defaultChecked={!activeUsersList[updateUserIndex].metric_system}
                                            />{' '}
                                            Imperial
                                        </Label>
                                    </FormGroup>
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label>Account Status*</Label>
                                <div>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="active"
                                                defaultChecked={activeUsersList[updateUserIndex].active}
                                            />{' '}
                                            Activated
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="approved"
                                                defaultChecked={activeUsersList[updateUserIndex].approved}
                                            />{' '}
                                            Approved
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="onboarded"
                                                defaultChecked={activeUsersList[updateUserIndex].onboarded}
                                            />{' '}
                                            Onboarded
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="verified"
                                                defaultChecked={activeUsersList[updateUserIndex].verified}
                                            />{' '}
                                            Verified
                                        </Label>
                                    </FormGroup>
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label>Notifications*</Label>
                                <div>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="email_updates"
                                                defaultChecked={activeUsersList[updateUserIndex].email_updates}
                                            />{' '}
                                            Email Updates
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="text_messages"
                                                defaultChecked={activeUsersList[updateUserIndex].text_messages}
                                            />{' '}
                                            Text Messages
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="recatch_alerts"
                                                defaultChecked={activeUsersList[updateUserIndex].recatch_alerts}
                                            />{' '}
                                            Recatch Alerts
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="push_notifications"
                                                defaultChecked={activeUsersList[updateUserIndex].notifications}
                                            />{' '}
                                            Push Notifications
                                        </Label>
                                    </FormGroup>
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label>Permissions*</Label>
                                <div>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="admin_access"
                                                defaultChecked={activeUsersList[updateUserIndex].admin_user}
                                            />{' '}
                                            Admin Access
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="partner_access"
                                                defaultChecked={activeUsersList[updateUserIndex].partner_user}
                                                onChange={handleUpdateCheckboxChange}
                                            />{' '}
                                            Partner Access
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="privacy_mode"
                                                defaultChecked={activeUsersList[updateUserIndex].privacy_mode}
                                            />{' '}
                                            Privacy Mode
                                        </Label>
                                    </FormGroup>
                                </div>
                            </FormGroup>

                            {partnerAccessUpdate && (
                                <FormGroup>
                                    <Label for="tag-program">Partner Tag Program*</Label>
                                    <Input
                                        type="select"
                                        name="tag_program"
                                        id="tag-program"
                                        required
                                        defaultValue={activeUsersList[updateUserIndex].program_access?.id || ''}
                                    >
                                        <option value="">Select Tag Program</option>
                                        {allTagPrograms.map((prog) => (
                                            <option key={prog.id} value={prog.id}>
                                                {prog.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={closeEditModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={isUploading}
                                >
                                    Update
                                </Button>
                            </div>
                        </ModalFooter>
                    </form>
                )}
                {isUpdating && (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isUpdating}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Create User Modal */}
            <Modal isOpen={createModal} toggle={closeCreateModal} centered>
                {!isCreating ? (
                    <form className="tablelist-form" onSubmit={handleCreateUser}>
                        <ModalHeader toggle={closeCreateModal}>Create User</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="create-first-name">First Name*</Label>
                                <Input
                                    type="text"
                                    name="first_name"
                                    id="create-first-name"
                                    placeholder="Richard"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="create-last-name">Last Name*</Label>
                                <Input
                                    type="text"
                                    name="last_name"
                                    id="create-last-name"
                                    placeholder="Ryan"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="create-email">Email Address*</Label>
                                <Input
                                    type="email"
                                    name="email_address"
                                    id="create-email"
                                    placeholder="rich@fish-trak.com"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="create-mobile-number">Mobile Number*</Label>
                                <Input
                                    type="tel"
                                    name="mobile_number"
                                    id="create-mobile-number"
                                    placeholder="+14151234567"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="create-avatar">User Avatar*</Label>
                                <Row>
                                    <Col xs="12">
                                        <Input
                                            type="file"
                                            name="image_link"
                                            id="create-avatar"
                                            className="form-control"
                                            onChange={(e) => { setUploadedImage(e.target.files[0]); }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Label for="create-home-port">Home Port Location*</Label>
                                <Input
                                    type="text"
                                    name="home_port"
                                    id="create-home-port"
                                    placeholder="Toms River"
                                    required
                                />
                            </FormGroup>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="create-home-port-lat">Home Port Latitude*</Label>
                                        <Input
                                            type="number"
                                            name="home_port_lat"
                                            id="create-home-port-lat"
                                            placeholder="Enter Latitude*"
                                            step="any"
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="create-home-port-lng">Home Port Longitude*</Label>
                                        <Input
                                            type="number"
                                            name="home_port_lng"
                                            id="create-home-port-lng"
                                            placeholder="Enter Longitude*"
                                            step="any"
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label for="create-user-country">User Country*</Label>
                                <Input
                                    type="select"
                                    name="user_country"
                                    id="create-user-country"
                                    required
                                >
                                    <option value="">Select User Country</option>
                                    <option value="USA">United States</option>
                                    <option value="CAN">Canada</option>
                                    {/* Add more countries as needed */}
                                </Input>
                            </FormGroup>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Units System*</Label>
                                        <div>
                                            <FormGroup check inline>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="units"
                                                        value="true"
                                                        defaultChecked={true}
                                                    />{' '}
                                                    Metric
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check inline>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="units"
                                                        value="false"
                                                        defaultChecked={false}
                                                    />{' '}
                                                    Imperial
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label>Permissions*</Label>
                                <div>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="admin_access"
                                            />{' '}
                                            Admin Access
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="partner_access"
                                                onChange={handleCreateCheckboxChange}
                                            />{' '}
                                            Partner Access
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="privacy_mode"
                                            />{' '}
                                            Privacy Mode
                                        </Label>
                                    </FormGroup>
                                </div>
                            </FormGroup>

                            {partnerAccessCreate && (
                                <FormGroup>
                                    <Label for="create-tag-program">Partner Tag Program*</Label>
                                    <Input
                                        type="select"
                                        name="tag_program"
                                        id="create-tag-program"
                                        required
                                    >
                                        <option value="">Select Tag Program</option>
                                        {allTagPrograms.map((prog) => (
                                            <option key={prog.id} value={prog.id}>
                                                {prog.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={closeCreateModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={isUploading}
                                >
                                    Create
                                </Button>
                            </div>
                        </ModalFooter>
                    </form>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isCreating}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Disable User Modal */}
            <Modal isOpen={deleteModal} toggle={closeDeleteModal} centered>
                {!isDeleting ? (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Disable User's Account</h4>
                                <p className="text-muted mx-4 mb-0">Are you sure you want to disable this user?</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={closeDeleteModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                onClick={disableUser}
                            >
                                Disable
                            </Button>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isDeleting}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>
        </React.Fragment>
    );

};

export default ActiveUsers;