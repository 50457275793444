const SidebarData = [
    {
        label: "Overview",
        icon: "mdi mdi-view-dashboard",
        url: "/dashboard",
        isHasArrow: true,
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "Live"
    },
    {
        label: "Tables",
        isMainMenu: true,
    },
    {
        label: "Catch Ledger",
        icon: "mdi mdi-hook",
        subItem: [
            { sublabel: "All Catches", link: "/all-catches"  },
            { sublabel: "New Catches", link: "/new-catches" },
            { sublabel: "Re-Catches", link: "/re-catches" },
        ],
    },
    {
        label: "Fish Species",
        icon: "mdi mdi-fish",
        isHasArrow: true,
        url: "/fish-species",
    },
    {
        label: "Tag Programs",
        icon: "mdi mdi-tag-faces",
        isHasArrow: true,
        url: "/tag-programs",
    },
    {
        label: "Fish Tags",
        icon: "mdi mdi-tag-multiple",
        subItem: [
            { sublabel: "All Tags", link: "/all-tags" },
            { sublabel: "Used Tags", link: "/used-tags" },
            { sublabel: "Un-Used Tags", link: "/unused-tags" },
        ],
    },
    {
        label: "Users",
        isMainMenu: true,
    },
    {
        label: "Registrations",
        icon: "mdi mdi-account-circle",
        subItem: [
            { sublabel: "All Users", link: "/all-users" },
            { sublabel: "Activated", link: "/active-users" },
            { sublabel: "Disabled", link: "/disabled-users" },
        ],
    },
    {
        label: "Onboardings",
        icon: "mdi mdi-comment-question",
        isHasArrow: true,
        url: "/onboardings",
    },
    // {
    //     label: "Notifications",
    //     icon: "mdi mdi-bell",
    //     subItem: [
    //         { sublabel: "Send New" },
    //         { sublabel: "Historical",},
    //     ],
    // },
]
export default SidebarData;