import React, { useRef, useState, useEffect, useMemo } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    ModalHeader,
    Input,
    FormGroup,
    Label,
    Table,
} from 'reactstrap';
import { ThreeCircles } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import Papa from 'papaparse';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import { serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, getStorage, ref } from "firebase/storage";

const FishSpecies = () => {

    const storage = getStorage();

    // ---------------------------
    // STATE VARIABLES
    // ---------------------------
    const [initialLoad, setInitialLoad] = useState(true);
    const [allSpeciesList, setAllSpeciesList] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isRestoring, setIsRestoring] = useState(false);
    const [fetchedSpecies, setFetchedSpecies] = useState(false);
    const [speciesImage, setSpeciesImage] = useState(null);
    const [updatedImage, setUpdatedImage] = useState(null);

    // Modal States
    const [modalList, setModalList] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [restoreModal, setRestoreModal] = useState(false);
    const [uploadCSVModal, setUploadCSVModal] = useState(false);

    // Action Identifiers
    const [updateSpeciesId, setUpdateSpeciesId] = useState('');
    const [updateSpeciesIndex, setUpdateSpeciesIndex] = useState(null);
    const [deleteSpeciesId, setDeleteSpeciesId] = useState('');
    const [deleteSpeciesIndex, setDeleteSpeciesIndex] = useState(null);
    const [restoreSpeciesId, setRestoreSpeciesId] = useState('');
    const [restoreSpeciesIndex, setRestoreSpeciesIndex] = useState(null);

    // Search and Pagination States
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    // CSV Upload States
    const [csvFile, setCsvFile] = useState(null);
    const [isCsvUploading, setIsCsvUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    // Reference for Firestore Listener
    const subscriptionRef = useRef(null);

    // ---------------------------
    // DOCUMENT TITLE
    // ---------------------------
    useEffect(() => {
        document.title = "Fish Species | Fish-Trak - Internal Management Dashboard";
    }, []);

    // ---------------------------
    // FETCH ALL SPECIES
    // ---------------------------
    const fetchAllSpecies = () => {
        // Unsubscribe previous listener if any
        if (subscriptionRef.current) {
            subscriptionRef.current();
        }

        const firestore = firebase.firestore();

        const query = firestore.collection('species').orderBy('name', 'asc');

        const unsub = query.onSnapshot(
            (querySnapshot) => {
                const speciesArray = [];
                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    speciesArray.push({
                        id: doc.id,
                        name: data.name || '',
                        image_url: data.image_url || '',
                        fish_type: data.fish_type || '',
                        created_at: data.created_at ? data.created_at.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) : '',
                        updated_at: data.updated_at ? data.updated_at.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) : '',
                        deleted: data.deleted || false,
                    });
                });
                setAllSpeciesList(speciesArray);
                setFetchedSpecies(true);
                // Reset modal states
                setModalList(false);
                setEditModal(false);
                setDeleteModal(false);
                setRestoreModal(false);
                setIsUploading(false);
                setIsUpdating(false);
                setIsDeleting(false);
                setIsRestoring(false);
            },
            (error) => {
                console.error("Error fetching species:", error);
                toast.error("Error fetching species.");
            }
        );

        subscriptionRef.current = unsub;
    };

    // ---------------------------
    // INITIAL DATA FETCHING
    // ---------------------------
    useEffect(() => {
        if (initialLoad) {
            setInitialLoad(false);
            fetchAllSpecies();
        }
    }, [initialLoad]);

    // ---------------------------
    // CLEANUP ON UNMOUNT
    // ---------------------------
    useEffect(() => {
        return () => {
            if (subscriptionRef.current) {
                subscriptionRef.current();
            }
        };
    }, []);

    // ---------------------------
    // SEARCH AND FILTER LOGIC
    // ---------------------------
    const filteredSpecies = useMemo(() => {
        return allSpeciesList.filter(species => {
            const matchesSearch =
                species.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                species.fish_type.toLowerCase().includes(searchQuery.toLowerCase());
            return matchesSearch;
        });
    }, [allSpeciesList, searchQuery]);

    // ---------------------------
    // PAGINATION CALCULATIONS
    // ---------------------------
    const pageCount = Math.ceil(filteredSpecies.length / itemsPerPage);
    const displayedSpecies = filteredSpecies.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    // ---------------------------
    // HANDLE PAGE CHANGE
    // ---------------------------
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // ---------------------------
    // HANDLE APPLY FILTERS BUTTON
    // ---------------------------
    const handleApplyFilters = () => {
        fetchAllSpecies();
        setCurrentPage(0); // Reset to first page when filters are applied
        toast.info("Filters applied.");
    };

    // ---------------------------
    // HANDLE DOWNLOAD CSV
    // ---------------------------
    const handleDownloadCSV = () => {
        try {
            // Build CSV from allSpeciesList array
            const csvRows = [];
            // CSV header
            csvRows.push([
                'id',
                'name',
                'image_url',
                'fish_type',
                'created_at',
                'created_time',
                'updated_at',
                'updated_time',
                'deleted'
            ].join(','));

            // Add rows
            allSpeciesList.forEach(species => {
                const row = [
                    species.id,
                    `"${species.name.replace(/"/g, '""')}"`, // Escape quotes
                    species.image_url,
                    species.fish_type,
                    species.created_at,
                    species.updated_at,
                    species.deleted
                ];
                csvRows.push(row.join(','));
            });

            // Convert to Blob
            const csvString = csvRows.join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);

            // Trigger download
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "fish_species.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            toast.success("CSV downloaded successfully.");
        } catch (error) {
            console.error('Error generating CSV:', error);
            toast.error('Could not download CSV. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE CSV FILE SELECTION
    // ---------------------------
    const handleCsvFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setCsvFile(event.target.files[0]);
        }
    };

    // ---------------------------
    // IMPORT SINGLE CSV ROW
    // ---------------------------
    const importCsvRow = async (row) => {
        const firestore = firebase.firestore();

        // Extract fields from CSV
        const name = (row['name'] || '').trim();
        const imageUrl = (row['image_url'] || '').trim();
        const fishType = (row['fish_type'] || '').trim();

        // Validate required fields
        if (!name) {
            throw new Error('Missing required field: name');
        }
        if (!imageUrl) {
            throw new Error('Missing required field: image_url');
        }
        if (!fishType) {
            throw new Error('Missing required field: fish_type');
        }

        // Prepare Species Data
        const speciesData = {
            name: name,
            image_url: imageUrl,
            fish_type: fishType,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
            deleted: false,
        };

        try {
            await firestore.collection('species').add(speciesData);
        } catch (error) {
            console.error("Error importing species:", error);
            throw new Error('Failed to import species.');
        }
    };

    // ---------------------------
    // HANDLE CSV UPLOAD
    // ---------------------------
    const handleUploadCSV = () => {
        if (!csvFile) {
            toast.error('No CSV file selected.');
            return;
        }
        setIsCsvUploading(true);
        setUploadProgress(0);

        Papa.parse(csvFile, {
            header: true,
            skipEmptyLines: true,
            complete: async function (results) {
                const data = results.data;
                if (data.length === 0) {
                    toast.error("CSV file is empty.");
                    setIsCsvUploading(false);
                    return;
                }

                let processedCount = 0;
                let successCount = 0;
                let failureCount = 0;

                for (let i = 0; i < data.length; i++) {
                    const row = data[i];
                    try {
                        await importCsvRow(row);
                        processedCount++;
                        successCount++;
                        setUploadProgress(Math.round((processedCount / data.length) * 100));
                    } catch (err) {
                        processedCount++;
                        failureCount++;
                        console.error('Error importing row', row, err);
                    }
                }

                toast.success(`CSV upload completed. Successfully imported ${successCount} of ${data.length} rows.`);
                if (failureCount > 0) {
                    toast.error(`${failureCount} row(s) failed to import.`);
                }
                setIsCsvUploading(false);
                setCsvFile(null);
                toggleUploadCSVModal();
                fetchAllSpecies();
            },
            error: function (err) {
                console.error("Error parsing CSV:", err);
                toast.error("Error parsing CSV file.");
                setIsCsvUploading(false);
            }
        });
    };

    // ---------------------------
    // HANDLE CREATE SPECIES
    // ---------------------------
    const submitCreateForm = async (event) => {
        event.preventDefault();
        setIsUploading(true);

        const form = event.target;
        const speciesName = form.species_name.value.trim();
        const fishType = form.fish_type.value;
        const imageFile = form.image_file.files[0];

        if (!imageFile) {
            toast.error('Image file is required.');
            setIsUploading(false);
            return;
        }

        // Upload Image to Firebase Storage
        const imageFileName = `fish-species/${speciesName.replace(/[^0-9a-zA-Z-]/g, '-')}-${Date.now()}`;
        const storageRef = ref(storage, imageFileName);
        const uploadTask = uploadBytesResumable(storageRef, imageFile);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setUploadProgress(progress);
            },
            (error) => {
                console.error("Error uploading image:", error);
                toast.error('Could not upload image. Please try again.');
                setIsUploading(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadedURL) => {
                    // Create new species in Firestore
                    const speciesData = {
                        name: speciesName,
                        image_url: downloadedURL,
                        fish_type: fishType,
                        created_at: serverTimestamp(),
                        updated_at: serverTimestamp(),
                        deleted: false,
                    };

                    try {
                        await firebase.firestore().collection('species').add(speciesData);
                        toast.success('New species added successfully!');
                        setIsUploading(false);
                        setUploadProgress(0);
                        setSpeciesImage(null);
                        setModalList(false);
                        fetchAllSpecies();
                    } catch (error) {
                        console.error("Error adding species:", error);
                        toast.error('Could not add species. Please try again.');
                        setIsUploading(false);
                    }
                });
            }
        );
    };

    // ---------------------------
    // HANDLE UPDATE SPECIES
    // ---------------------------
    const submitUpdateForm = async (event) => {
        event.preventDefault();
        setIsUpdating(true);
        setIsUploading(true);

        const form = event.target;
        const speciesName = form.species_name.value.trim();
        const fishType = form.fish_type.value;
        const imageFile = form.image_file.files[0];

        const speciesId = updateSpeciesId;
        const speciesRef = firebase.firestore().collection('species').doc(speciesId);

        let updatedData = {
            updated_at: serverTimestamp(),
        };

        if (speciesName !== allSpeciesList[updateSpeciesIndex].name) {
            updatedData.name = speciesName;
        }

        if (fishType !== allSpeciesList[updateSpeciesIndex].fish_type) {
            updatedData.fish_type = fishType;
        }

        if (imageFile) {
            // Upload new image
            const imageFileName = `fish-species/${speciesName.replace(/[^0-9a-zA-Z-]/g, '-')}-${Date.now()}`;
            const storageRef = ref(storage, imageFileName);
            const uploadTask = uploadBytesResumable(storageRef, imageFile);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setUploadProgress(progress);
                },
                (error) => {
                    console.error("Error uploading image:", error);
                    toast.error('Could not upload image. Please try again.');
                    setIsUpdating(false);
                    setIsUploading(false);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadedURL) => {
                        updatedData.image_url = downloadedURL;

                        try {
                            await speciesRef.update(updatedData);
                            toast.success('Species updated successfully!');
                            setIsUpdating(false);
                            setIsUploading(false);
                            setUpdatedImage(null);
                            setEditModal(false);
                            fetchAllSpecies();
                        } catch (error) {
                            console.error("Error updating species:", error);
                            toast.error('Could not update species. Please try again.');
                            setIsUpdating(false);
                            setIsUploading(false);
                        }
                    });
                }
            );
        } else {
            // No image update
            try {
                await speciesRef.update(updatedData);
                toast.success('Species updated successfully!');
                setIsUpdating(false);
                setIsUploading(false);
                setEditModal(false);
                fetchAllSpecies();
            } catch (error) {
                console.error("Error updating species:", error);
                toast.error('Could not update species. Please try again.');
                setIsUpdating(false);
                setIsUploading(false);
            }
        }
    };

    // ---------------------------
    // HANDLE DELETE SPECIES
    // ---------------------------
    const deleteSpecies = async () => {
        setIsDeleting(true);

        const speciesRef = firebase.firestore().collection('species').doc(deleteSpeciesId);

        try {
            await speciesRef.update({
                deleted: true,
                updated_at: serverTimestamp(),
            });
            toast.success('Species deleted successfully!');
            setIsDeleting(false);
            setDeleteSpeciesId('');
            setDeleteSpeciesIndex(null);
            setDeleteModal(false);
            fetchAllSpecies();
        } catch (error) {
            console.error("Error deleting species:", error);
            toast.error('Could not delete species. Please try again.');
            setIsDeleting(false);
        }
    };

    // ---------------------------
    // HANDLE RESTORE SPECIES
    // ---------------------------
    const restoreSpecies = async () => {
        setIsRestoring(true);

        const speciesRef = firebase.firestore().collection('species').doc(restoreSpeciesId);

        try {
            await speciesRef.update({
                deleted: false,
                updated_at: serverTimestamp(),
            });
            toast.success('Species restored successfully!');
            setIsRestoring(false);
            setRestoreSpeciesId('');
            setRestoreSpeciesIndex(null);
            setRestoreModal(false);
            fetchAllSpecies();
        } catch (error) {
            console.error("Error restoring species:", error);
            toast.error('Could not restore species. Please try again.');
            setIsRestoring(false);
        }
    };

    // ---------------------------
    // HANDLE CSV UPLOAD MODAL TOGGLE
    // ---------------------------
    const toggleUploadCSVModal = () => {
        setCsvFile(null);
        setUploadProgress(0);
        setUploadCSVModal(!uploadCSVModal);
    };

    // ---------------------------
    // COMPONENT RETURN
    // ---------------------------
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Fish Species</h4>
                                </CardHeader>

                                <CardBody>
                                    {/* Filter and Action Controls */}
                                    <Row className="g-4 mb-3">
                                        {/* Add Species Button */}
                                        <Col sm="auto">
                                            <div className="d-flex gap-1">
                                                <Button
                                                    color="success"
                                                    onClick={() => setModalList(true)}
                                                    id="create-btn"
                                                >
                                                    <i className="ri-add-line align-bottom me-1"></i> Add Species
                                                </Button>
                                            </div>
                                        </Col>
                                        {/* Search Box */}
                                        <Col className="d-flex align-items-start">
                                            <Input
                                                type="search"
                                                name="search"
                                                id="search"
                                                placeholder="Search Species..."
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        </Col>
                                        {/* Apply Filters Button (if any filters are added in future) */}
                                        {/* <Col sm="auto">
                                            <Button color="secondary" onClick={handleApplyFilters}>
                                                <i className="mdi mdi-filter"></i> Apply Filters
                                            </Button>
                                        </Col> */}
                                        {/* Download CSV Button */}
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button
                                                color="primary"
                                                onClick={handleDownloadCSV}
                                                title="Download CSV"
                                                style={{ width: '100%' }}
                                            >
                                                <i className="mdi mdi-download"></i>
                                            </Button>
                                        </Col>
                                        {/* Upload CSV Button */}
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button
                                                color="info"
                                                onClick={toggleUploadCSVModal}
                                                title="Upload CSV"
                                                style={{ width: '100%' }}
                                            >
                                                <i className="mdi mdi-upload"></i>
                                            </Button>
                                        </Col>
                                    </Row>

                                    {/* Species Table */}
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        {(!isUpdating && !isDeleting && !isRestoring && !isCsvUploading && !isUploading) && (
                                            <Table className="align-middle table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">Species Name</th>
                                                        <th scope="col">Fish Type</th>
                                                        <th scope="col">Created At</th>
                                                        <th scope="col">Updated At</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {displayedSpecies.length > 0 ? displayedSpecies.map((species, index) => (
                                                        <tr key={species.id}>
                                                            <td>
                                                                <a href={`/species/details/${species.id}`} className="btn btn-sm btn-success">
                                                                    <i className="mdi mdi-eye-outline"></i>
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {species.image_url ? (
                                                                    <img
                                                                        className="avatar-sm rounded"
                                                                        alt={species.name}
                                                                        src={species.image_url}
                                                                    />
                                                                ) : (
                                                                    <span>No Image</span>
                                                                )}
                                                            </td>
                                                            <td>{species.name}</td>
                                                            <td>{species.fish_type}</td>
                                                            <td>{species.created_at}</td>
                                                            <td>{species.updated_at}</td>
                                                            <td>
                                                                {species.deleted ? (
                                                                    <span className="badge badge-soft-danger text-uppercase">Deleted</span>
                                                                ) : (
                                                                    <span className="badge badge-soft-success text-uppercase">Active</span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    {/* Edit Button */}
                                                                    <Button
                                                                        color="warning"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            setUpdateSpeciesId(species.id);
                                                                            setUpdateSpeciesIndex(index);
                                                                            setEditModal(true);
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-square-edit-outline"></i>
                                                                    </Button>
                                                                    {/* Delete or Restore Buttons */}
                                                                    {!species.deleted ? (
                                                                        <Button
                                                                            color="danger"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                setDeleteSpeciesId(species.id);
                                                                                setDeleteSpeciesIndex(index);
                                                                                setDeleteModal(true);
                                                                            }}
                                                                        >
                                                                            <i className="mdi mdi-delete-outline"></i>
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            color="success"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                setRestoreSpeciesId(species.id);
                                                                                setRestoreSpeciesIndex(index);
                                                                                setRestoreModal(true);
                                                                            }}
                                                                        >
                                                                            <i className="mdi mdi-history"></i>
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )) : (
                                                        <tr>
                                                            <td colSpan="8" className="text-center">No Fish Species Found!</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        )}

                                        {/* Loader */}
                                        {(isUpdating || isDeleting || isRestoring || isCsvUploading || isUploading) && (
                                            <div className="d-flex justify-content-center my-5">
                                                <ThreeCircles
                                                    visible={isUpdating || isDeleting || isRestoring || isCsvUploading || isUploading}
                                                    height="100"
                                                    width="100"
                                                    color="#4192C3"
                                                    ariaLabel="three-circles-loading"
                                                    wrapperStyle={{ margin: "0 auto" }}
                                                    wrapperClass="justify-content-center"
                                                />
                                            </div>
                                        )}

                                        {/* No Results Message */}
                                        {filteredSpecies.length === 0 && (
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Fish Species Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any fish species.</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Pagination Controls */}
                                    {pageCount > 1 && (
                                        <div className="d-flex justify-content-end">
                                            <ReactPaginate
                                                previousLabel={"← Previous"}
                                                nextLabel={"Next →"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageChange}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"page-link"}
                                                nextLinkClassName={"page-link"}
                                                disabledClassName={"disabled"}
                                                activeClassName={"active"}
                                                pageLinkClassName={"page-link"}
                                                breakLabel={"..."}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* --------------------------- */}
            {/* Modals */}
            {/* --------------------------- */}

            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={() => setEditModal(!editModal)} centered>
                <form className="tablelist-form" onSubmit={submitUpdateForm}>
                    <ModalHeader toggle={() => setEditModal(!editModal)}>Edit Fish Species</ModalHeader>
                    <ModalBody>
                        {!isUpdating && allSpeciesList[updateSpeciesIndex] && (
                            <>
                                <FormGroup>
                                    <Label for="species_name">Species Name*</Label>
                                    <Input
                                        type="text"
                                        name="species_name"
                                        id="species_name"
                                        className="form-control"
                                        placeholder="Atlantic Salmon"
                                        required
                                        defaultValue={allSpeciesList[updateSpeciesIndex].name}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="fish_type">Fish Type*</Label>
                                    <Input
                                        type="select"
                                        name="fish_type"
                                        id="fish_type"
                                        required
                                        defaultValue={allSpeciesList[updateSpeciesIndex].fish_type}
                                    >
                                        <option value="">Select Fish Type</option>
                                        <option value="Freshwater">Freshwater</option>
                                        <option value="Water">Water</option>
                                        <option value="Saltwater">Saltwater</option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="image_file">Image File</Label>
                                    <Input
                                        type="file"
                                        name="image_file"
                                        id="image_file"
                                        className="form-control"
                                        onChange={(e) => { setUpdatedImage(e.target.files[0]); }}
                                        accept="image/*"
                                    />
                                    {allSpeciesList[updateSpeciesIndex].image_url && (
                                        <img
                                            className="avatar-sm rounded mt-2"
                                            alt={allSpeciesList[updateSpeciesIndex].name}
                                            src={allSpeciesList[updateSpeciesIndex].image_url}
                                        />
                                    )}
                                </FormGroup>
                            </>
                        )}
                        {isUpdating && (
                            <div className="d-flex justify-content-center my-5">
                                <ThreeCircles
                                    visible={isUpdating}
                                    height="100"
                                    width="100"
                                    color="#4192C3"
                                    ariaLabel="three-circles-loading"
                                    wrapperStyle={{ margin: "0 auto" }}
                                    wrapperClass="justify-content-center"
                                />
                            </div>
                        )}
                    </ModalBody>
                    {!isUpdating && (
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => setEditModal(false)}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    color="success"
                                    onClick={submitUpdateForm}
                                >
                                    Update
                                </Button>
                            </div>
                        </ModalFooter>
                    )}
                </form>
            </Modal>

            {/* Add Species Modal */}
            <Modal isOpen={modalList} toggle={() => setModalList(!modalList)} centered>
                <form className="tablelist-form" onSubmit={submitCreateForm}>
                    <ModalHeader toggle={() => setModalList(!modalList)}>Add Fish Species</ModalHeader>
                    <ModalBody>
                        {!isUploading && (
                            <>
                                <FormGroup>
                                    <Label for="species_name">Species Name*</Label>
                                    <Input
                                        type="text"
                                        name="species_name"
                                        id="species_name"
                                        className="form-control"
                                        placeholder="Atlantic Salmon"
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="fish_type">Fish Type*</Label>
                                    <Input
                                        type="select"
                                        name="fish_type"
                                        id="fish_type"
                                        required
                                    >
                                        <option value="">Select Fish Type</option>
                                        <option value="Freshwater">Freshwater</option>
                                        <option value="Water">Water</option>
                                        <option value="Saltwater">Saltwater</option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="image_file">Image File*</Label>
                                    <Input
                                        type="file"
                                        name="image_file"
                                        id="image_file"
                                        className="form-control"
                                        accept="image/*"
                                        required
                                    />
                                </FormGroup>
                            </>
                        )}
                        {isUploading && (
                            <div className="d-flex justify-content-center my-5">
                                <ThreeCircles
                                    visible={isUploading}
                                    height="100"
                                    width="100"
                                    color="#4192C3"
                                    ariaLabel="three-circles-loading"
                                    wrapperStyle={{ margin: "0 auto" }}
                                    wrapperClass="justify-content-center"
                                />
                            </div>
                        )}
                    </ModalBody>
                    {!isUploading && (
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => setModalList(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={isUploading}
                                >
                                    Add Species
                                </Button>
                            </div>
                        </ModalFooter>
                    )}
                </form>
            </Modal>

            {/* Delete Species Modal */}
            <Modal
                isOpen={deleteModal}
                toggle={() => setDeleteModal(!deleteModal)}
                centered
            >
                {!isDeleting ? (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Delete Fish Species</h4>
                                <p className="text-muted mx-4 mb-0">Are you sure you want to delete this species?</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={() => setDeleteModal(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                onClick={deleteSpecies}
                            >
                                Delete
                            </Button>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isDeleting}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Restore Species Modal */}
            <Modal
                isOpen={restoreModal}
                toggle={() => setRestoreModal(!restoreModal)}
                centered
            >
                {!isRestoring ? (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Restore Fish Species</h4>
                                <p className="text-muted mx-4 mb-0">Do you really want to restore this species?</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={() => setRestoreModal(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="success"
                                onClick={restoreSpecies}
                            >
                                Restore
                            </Button>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isRestoring}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Upload CSV Modal */}
            <Modal isOpen={uploadCSVModal} toggle={toggleUploadCSVModal} centered>
                <form className="tablelist-form" onSubmit={(e) => { e.preventDefault(); handleUploadCSV(); }}>
                    <ModalHeader toggle={toggleUploadCSVModal}>Import Species from CSV</ModalHeader>
                    <ModalBody>
                        {!isCsvUploading && (
                            <>
                                <FormGroup>
                                    <Label for="csvFile">Select CSV File</Label>
                                    <Input
                                        type="file"
                                        name="csvFile"
                                        id="csvFile"
                                        accept=".csv"
                                        required
                                        onChange={handleCsvFileChange}
                                    />
                                </FormGroup>
                                <p className="text-muted">
                                    The CSV file should contain the following columns:
                                    <ul>
                                        <li><strong>name</strong> (String)</li>
                                        <li><strong>image_url</strong> (String)</li>
                                        <li><strong>fish_type</strong> (String)</li>
                                    </ul>
                                </p>
                            </>
                        )}
                        {isCsvUploading && (
                            <div className="d-flex flex-column align-items-center">
                                <ThreeCircles
                                    visible={isCsvUploading}
                                    height="100"
                                    width="100"
                                    color="#4192C3"
                                    ariaLabel="three-circles-loading"
                                    wrapperStyle={{ margin: "0 auto" }}
                                    wrapperClass="justify-content-center"
                                />
                                <p className="mt-3">Uploading CSV and processing data...</p>
                                <div className="w-100 mt-3">
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: `${uploadProgress}%` }}
                                            aria-valuenow={uploadProgress}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            {uploadProgress}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </ModalBody>
                    {!isCsvUploading && (
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={toggleUploadCSVModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                >
                                    Upload CSV
                                </Button>
                            </div>
                        </ModalFooter>
                    )}
                </form>
            </Modal>

            {/* Toast Container for Notifications */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </React.Fragment>
    );

};

export default FishSpecies;