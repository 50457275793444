// UnusedTags.jsx

import React, { useRef, useState, useEffect, useMemo } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    ModalHeader,
    Input,
    FormGroup,
    Label
} from 'reactstrap';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import Papa from 'papaparse'; // Import PapaParse for CSV parsing
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import { serverTimestamp } from 'firebase/firestore';

const UnusedTags = () => {

    // ---------------------------
    // STATE VARIABLES
    // ---------------------------
    const [initialLoad, setInitialLoad] = useState(true);
    const [unusedTagsList, setUnusedTagsList] = useState([]);
    const [filteredTagsList, setFilteredTagsList] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [allTagPrograms, setAllTagPrograms] = useState([]);
    const [allUsersList, setAllUsersList] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);

    // Action States
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateTagId, setUpdateTagId] = useState('');
    const [updateTagIndex, setUpdateTagIndex] = useState(null);
    const [deleteTagId, setDeleteTagId] = useState('');
    const [deleteTagIndex, setDeleteTagIndex] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    const [restoreTagId, setRestoreTagId] = useState('');
    const [restoreTagIndex, setRestoreTagIndex] = useState(null);
    const [isRestoringUnused, setIsRestoringUnused] = useState(false);

    // Modal States
    const [modalList, setModalList] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [restoreUnusedModal, setRestoreUnusedModal] = useState(false);

    // Filter and Search States
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    // Pagination States
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    // Reference for Firestore Listener
    const subscriptionRef = useRef(null);

    // ---------------------------
    // CSV Upload State Variables (Added)
    // ---------------------------
    const [csvFile, setCsvFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isCSVUploading, setIsCSVUploading] = useState(false);
    const [uploadCSVModal, setUploadCSVModal] = useState(false); // Modal state for CSV Upload

    // ---------------------------
    // MULTI-SELECT STATE VARIABLES
    // ---------------------------
    const [selectedTags, setSelectedTags] = useState([]);
    const [bulkDeleteModal, setBulkDeleteModal] = useState(false);
    const [isBulkDeleting, setIsBulkDeleting] = useState(false);

    // ---------------------------
    // DOCUMENT TITLE
    // ---------------------------
    useEffect(() => {
        document.title = "Fish Tags | Fish-Trak - Internal Management Dashboard";
    }, []);

    // ---------------------------
    // FIND USER NAME BY ID
    // ---------------------------
    const findNameById = (userId) => {
        const user = allUsersList.find(user => user.id === userId);
        return user ? user.name : "No User Name";
    };

    // ---------------------------
    // FETCH TAG PROGRAMS
    // ---------------------------
    const fetchTagPrograms = () => {
        firebase.firestore().collection('programs').orderBy('created_at', 'desc')
            .onSnapshot(
                querySnapshot => {
                    const programs = [];
                    querySnapshot.forEach(doc => {
                        const data = doc.data();
                        programs.push({
                            id: doc.id,
                            name: data.program_name,
                            image: data.image_url,
                            // Add other necessary fields if required
                        });
                    });
                    setAllTagPrograms(programs);
                },
                error => {
                    console.error("Error fetching tag programs:", error);
                    toast.error("Error fetching tag programs.");
                }
            );
    };

    // ---------------------------
    // FETCH USERS
    // ---------------------------
    const fetchAllUsers = () => {
        firebase.firestore().collection('users').orderBy('created_at', 'desc')
            .onSnapshot(
                querySnapshot => {
                    const users = [];
                    querySnapshot.forEach(doc => {
                        const data = doc.data();
                        users.push({
                            id: doc.id,
                            name: `${data.first_name} ${data.last_name}`,
                            email: data.email_address,
                            // Add other necessary fields if required
                        });
                    });
                    setAllUsersList(users);
                },
                error => {
                    console.error("Error fetching users:", error);
                    toast.error("Error fetching users.");
                }
            );
    };

    // ---------------------------
    // FETCH UNUSED FISH TAGS WITH FILTERS
    // ---------------------------
    const fetchUnusedFishTags = () => {
        // Unsubscribe previous listener if any
        if (subscriptionRef.current) {
            subscriptionRef.current();
        }

        // Base query
        let query = firebase.firestore().collection('tags').where('used', '==', false).orderBy('created_at', 'desc');

        // Apply date filters
        if (startDateFilter) {
            query = query.where('created_at', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
        }
        if (endDateFilter) {
            query = query.where('created_at', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        }

        // Listen to real-time updates
        const unsubscribe = query.onSnapshot(
            snapshot => {
                const tags = [];
                snapshot.forEach(doc => {
                    const data = doc.data();
                    tags.push({
                        id: doc.id,
                        tag_number: data.tag_number,
                        program_name: data.program_name,
                        first_angler: data.first_angler.id, // Assuming first_angler is a reference
                        created_at: data.created_at,
                        updated_at: data.updated_at,
                        deleted: data.deleted,
                        used: data.used,
                        tag_program: data.tag_program.id, // Assuming tag_program is a reference
                    });
                });
                setUnusedTagsList(tags);
                setFetchedData(true);
                setIsUploading(false);
                setModalList(false);
                setDeleteModal(false);
                setIsDeleting(false);
                setEditModal(false);
                setIsUpdating(false);
                setRestoreUnusedModal(false);
                setIsRestoringUnused(false);
                setBulkDeleteModal(false);
                setIsBulkDeleting(false);
            },
            error => {
                console.error("Error fetching unused fish tags:", error);
                toast.error("Error fetching unused fish tags.");
            }
        );

        // Save the unsubscribe function
        subscriptionRef.current = unsubscribe;
    };

    // ---------------------------
    // INITIAL DATA FETCHING
    // ---------------------------
    useEffect(() => {
        if (initialLoad) {
            setInitialLoad(false);
            fetchTagPrograms();
            fetchAllUsers();
            fetchUnusedFishTags();
        }
    }, [initialLoad]);

    // ---------------------------
    // SEARCH AND FILTER LOGIC
    // ---------------------------
    useEffect(() => {
        let tempTags = [...unusedTagsList];

        // Apply search filter
        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            tempTags = tempTags.filter(tag =>
                tag.tag_number.toLowerCase().includes(query) ||
                tag.program_name.toLowerCase().includes(query) ||
                (tag.first_angler && findNameById(tag.first_angler).toLowerCase().includes(query))
            );
        }

        setFilteredTagsList(tempTags);
        setCurrentPage(0); // Reset to first page when filters change
    }, [unusedTagsList, searchQuery]);

    // ---------------------------
    // PAGINATION HANDLER
    // ---------------------------
    const handlePageChange = (data) => {
        setCurrentPage(data.selected);
    };

    const offset = currentPage * itemsPerPage;
    const currentPageData = filteredTagsList.slice(offset, offset + itemsPerPage);
    const pageCount = Math.ceil(filteredTagsList.length / itemsPerPage);

    // ---------------------------
    // MULTI-SELECT DELETE FUNCTIONS
    // ---------------------------
    // Handler for selecting/deselecting a single tag
    const handleSelectTag = (tagId) => {
        setSelectedTags(prevSelected => {
            if (prevSelected.includes(tagId)) {
                return prevSelected.filter(id => id !== tagId);
            } else {
                return [...prevSelected, tagId];
            }
        });
    };

    // Handler for selecting/deselecting all tags
    const handleSelectAll = () => {
        if (selectedTags.length === filteredTagsList.length) {
            setSelectedTags([]);
        } else {
            const allTagIds = filteredTagsList.map(tag => tag.id);
            setSelectedTags(allTagIds);
        }
    };

    // Handler to toggle the bulk delete modal
    const toggleBulkDeleteModal = () => {
        setBulkDeleteModal(!bulkDeleteModal);
    };

    // Bulk Delete Function
    const deleteSelectedTags = async () => {
        setIsBulkDeleting(true);

        const batch = firebase.firestore().batch();
        const tagsRef = firebase.firestore().collection('tags');

        selectedTags.forEach(tagId => {
            const tagDocRef = tagsRef.doc(tagId);
            batch.update(tagDocRef, {
                updated_at: serverTimestamp(),
                deleted: true
            });
        });

        try {
            await batch.commit();
            toast.success('Selected tags have been deleted successfully!');
            setSelectedTags([]);
            toggleBulkDeleteModal();
            fetchUnusedFishTags();
        } catch (error) {
            console.error('Error deleting selected tags:', error);
            toast.error('Could not delete some of the selected tags. Please try again.');
        } finally {
            setIsBulkDeleting(false);
        }
    };

    // ---------------------------
    // HANDLE APPLY FILTERS BUTTON
    // ---------------------------
    const handleApplyFilters = () => {
        fetchUnusedFishTags();
        setCurrentPage(0); // Reset to first page when filters are applied
        toast.info("Filters applied.");
    };

    // ---------------------------
    // HANDLE CSV DOWNLOAD
    // ---------------------------
    const handleDownloadCSV = async () => {
        try {
            let query = firebase.firestore().collection('tags').where('used', '==', false).orderBy('created_at', 'desc');

            // Apply date filters
            if (startDateFilter) {
                query = query.where('created_at', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
            }
            if (endDateFilter) {
                query = query.where('created_at', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }

            const snapshot = await query.get();
            const csvRows = [];
            // Header
            csvRows.push(['ID', 'Tag Number', 'Program Name', 'Tag Program ID', 'Assigned Angler', 'Created At', 'Created Time', 'Updated At', 'Updated Time',  'Catch Count', 'Deleted', 'Used'].join(','));

            snapshot.forEach(doc => {
                const data = doc.data();
                csvRows.push([
                    doc.id,
                    data.tag_number,
                    data.program_name,
                    data.tag_program.id, // Tag Program ID
                    findNameById(data.first_angler.id), // Assigned Angler Name
                    data.created_at ? data.created_at.toDate().toISOString() : '',
                    data.updated_at ? data.updated_at.toDate().toISOString() : '',
                    data.catch_count || 0,
                    data.deleted ? 'true' : 'false',
                    data.used ? 'true' : 'false'
                ].join(','));
            });

            const csvContent = csvRows.join('\n');
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "unused_fish_tags.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            toast.success("CSV downloaded successfully.");
        } catch (error) {
            console.error("Error downloading CSV:", error);
            toast.error('Could not download CSV. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE CSV UPLOAD (Added)
    // ---------------------------
    const handleCSVUpload = (e) => {
        e.preventDefault();
        if (!csvFile) {
            toast.error("Please select a CSV file to upload.");
            return;
        }

        Papa.parse(csvFile, {
            header: true,
            skipEmptyLines: true,
            complete: async function(results) {
                const data = results.data;
                if (data.length === 0) {
                    toast.error("CSV file is empty.");
                    return;
                }

                setIsCSVUploading(true);
                let processedCount = 0;

                const batch = firebase.firestore().batch();

                data.forEach(row => {
                    // Validate required fields
                    const tagNumber = row.tag_number?.trim();
                    const anglerEmail = row.first_angler_email?.trim();
                    const tagProgramId = row.tag_program_id?.trim(); // New field

                    if (!tagNumber || !anglerEmail || !tagProgramId) {
                        toast.error(`Missing required fields in row: ${JSON.stringify(row)}`);
                        return;
                    }

                    // Find the user by email (case-insensitive)
                    const user = allUsersList.find(user => user.email.toLowerCase() === anglerEmail.toLowerCase());
                    if (!user) {
                        toast.error(`User with email ${anglerEmail} not found.`);
                        return;
                    }

                    // Find the program by tagProgramId
                    const program = allTagPrograms.find(p => p.id === tagProgramId);
                    if (!program) {
                        toast.error(`Program with ID ${tagProgramId} not found.`);
                        return;
                    }

                    // Prepare tag data
                    const tagData = {
                        tag_number: tagNumber,
                        first_angler: firebase.firestore().collection('users').doc(user.id),
                        catch_count: 0,
                        created_at: serverTimestamp(),
                        updated_at: serverTimestamp(),
                        deleted: false,
                        program_image: program.image || '',
                        program_name: program.name || '',
                        tag_program: firebase.firestore().collection('programs').doc(program.id),
                        used: false,
                    };

                    const newDocRef = firebase.firestore().collection('tags').doc(); // Generate new doc reference
                    batch.set(newDocRef, tagData);
                    processedCount++;
                });

                try {
                    await batch.commit();
                    toast.success(`CSV upload completed. Successfully added ${processedCount} tags.`);
                    setIsCSVUploading(false);
                    toggleUploadCSVModal();
                    fetchUnusedFishTags(); // Refresh the tags list
                } catch (error) {
                    console.error("Error uploading CSV:", error);
                    toast.error('Could not upload some of the tags. Please try again.');
                    setIsCSVUploading(false);
                }
            },
            error: function(err) {
                console.error("Error parsing CSV:", err);
                toast.error("Error parsing CSV file.");
            }
        });
    };

    // ---------------------------
    // CRUD OPERATIONS
    // ---------------------------

    // Update Tag
    const handleUpdateTag = (event) => {
        event.preventDefault();
        submitUpdateForm(event);
    };

    const submitUpdateForm = async (event) => {
        setIsUpdating(true);
        const tagRef = firebase.firestore().collection('tags').doc(updateTagId);
        const updatedData = {
            updated_at: serverTimestamp(),
            tag_number: event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, ''),
            tag_program: firebase.firestore().collection('programs').doc(event.target.tag_program.value),
            first_angler: firebase.firestore().collection('users').doc(event.target.first_angler.value),
        };

        try {
            await tagRef.update(updatedData);
            toast.success('Tag updated successfully!');
            fetchUnusedFishTags();
            setIsUpdating(false);
            toggleEditModal();
        } catch (error) {
            console.error("Error updating tag:", error);
            toast.error('Could not update tag. Please try again.');
            setIsUpdating(false);
        }
    };

    // Create New Tag
    const handleCreateTag = (event) => {
        event.preventDefault();
        createNewFishTag(event);
    };

    const createNewFishTag = async (event) => {
        setIsUploading(true);
        const tagRef = firebase.firestore().collection('tags');
        const selectedProgram = allTagPrograms.find(p => p.id === event.target.tag_program.value);

        const newTag = {
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
            tag_number: event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, ''),
            catch_count: 0,
            used: false,
            deleted: false,
            program_image: selectedProgram?.image || '',
            program_name: selectedProgram?.name || '',
            tag_program: firebase.firestore().collection('programs').doc(event.target.tag_program.value),
            first_angler: firebase.firestore().collection('users').doc(event.target.first_user.value),
        };

        try {
            await tagRef.add(newTag);
            toast.success('New fish tag created successfully!');
            fetchUnusedFishTags();
            setIsUploading(false);
            toggleModalList();
        } catch (error) {
            console.error("Error creating new tag:", error);
            toast.error('Could not create new tag. Please try again.');
            setIsUploading(false);
        }
    };

    // Delete Tag
    const handleDeleteTag = async () => {
        setIsDeleting(true);
        const tagRef = firebase.firestore().collection('tags').doc(deleteTagId);

        try {
            await tagRef.update({
                updated_at: serverTimestamp(),
                deleted: true
            });
            toast.success('Tag deleted successfully!');
            fetchUnusedFishTags();
            setIsDeleting(false);
            toggleDeleteModal();
        } catch (error) {
            console.error("Error deleting tag:", error);
            toast.error('Could not delete tag. Please try again.');
            setIsDeleting(false);
        }
    };

    // Restore Tag
    const handleRestoreTag = async () => {
        setIsRestoringUnused(true);
        const tagRef = firebase.firestore().collection('tags').doc(restoreTagId);

        try {
            await tagRef.update({
                updated_at: serverTimestamp(),
                deleted: false
            });
            toast.success('Tag restored successfully!');
            fetchUnusedFishTags();
            setIsRestoringUnused(false);
            toggleRestoreUnusedModal();
        } catch (error) {
            console.error("Error restoring tag:", error);
            toast.error('Could not restore tag. Please try again.');
            setIsRestoringUnused(false);
        }
    };

    // ---------------------------
    // MODAL TOGGLE FUNCTIONS
    // ---------------------------
    const toggleModalList = () => setModalList(!modalList);

    const toggleEditModal = (tagId, tagIndex) => {
        if (editModal) {
            setUpdateTagId('');
            setUpdateTagIndex(null);
        } else {
            setUpdateTagId(tagId);
            setUpdateTagIndex(tagIndex);
        }
        setEditModal(!editModal);
    };

    const toggleDeleteModal = (tagId, tagIndex) => {
        if (deleteModal) {
            setDeleteTagId('');
            setDeleteTagIndex(null);
        } else {
            setDeleteTagId(tagId);
            setDeleteTagIndex(tagIndex);
        }
        setDeleteModal(!deleteModal);
    };

    const toggleRestoreUnusedModal = (tagId, tagIndex) => {
        if (restoreUnusedModal) {
            setRestoreTagId('');
            setRestoreTagIndex(null);
        } else {
            setRestoreTagId(tagId);
            setRestoreTagIndex(tagIndex);
        }
        setRestoreUnusedModal(!restoreUnusedModal);
    };

    const toggleUploadCSVModal = () => { // Toggle function for CSV Upload Modal
        setCsvFile(null);
        setUploadProgress(0);
        setUploadCSVModal(!uploadCSVModal);
    };

    // ---------------------------
    // COMPONENT RETURN
    // ---------------------------
    return (
        <React.Fragment>
            {/* Toast Container for Notifications */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="page-content">
                <Container fluid>
                    <CardTitle>Fish Tags</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Un-Used Tags</h4>
                                </CardHeader>

                                <CardBody>
                                    {/* Filter and Action Controls */}
                                    <Row className="g-4 mb-3">
                                        {/* Select All Checkbox and Action Buttons */}
                                        <Col sm="1" className="d-flex align-items-end">
                                                <Button
                                                    color="success"
                                                    className="add-btn"
                                                    onClick={() => toggleModalList()}
                                                    id="create-btn"
                                                    style={{ width: '100%' }}
                                                >
                                                    <i className="mdi mdi-tag-plus"></i>
                                                </Button>
                                        </Col>
                                        <Col sm="1" className="d-flex align-items-end">
                                                <Button
                                                    color="danger"
                                                    className="add-btn"
                                                    onClick={toggleBulkDeleteModal}
                                                    disabled={selectedTags.length === 0}
                                                    style={{ width: '100%' }}
                                                >
                                                    <i className="mdi mdi-delete"></i>
                                                </Button>
                                        </Col>
                                        {/* Search Box */}
                                        <Col className="col-sm">
                                            <div className="justify-content-sm-start">
                                                <div className="search-box">
                                                    <Input
                                                        type="search"
                                                        className="form-control"
                                                        placeholder="Search Tags..."
                                                        value={searchQuery}
                                                        onChange={(e) => setSearchQuery(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        {/* Start Date */}
                                        <Col sm="2">
                                            <Flatpickr
                                                className="form-control"
                                                options={{ dateFormat: "Y-m-d" }}
                                                placeholder="Start Date"
                                                value={startDateFilter}
                                                onChange={(dates) => setStartDateFilter(dates[0] || null)}
                                            />
                                        </Col>
                                        {/* End Date */}
                                        <Col sm="2">
                                            <Flatpickr
                                                className="form-control"
                                                options={{ dateFormat: "Y-m-d" }}
                                                placeholder="End Date"
                                                value={endDateFilter}
                                                onChange={(dates) => setEndDateFilter(dates[0] || null)}
                                            />
                                        </Col>
                                        {/* Apply Filters Button */}
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button color="secondary" style={{ width: '100%' }} onClick={handleApplyFilters}>
                                                <i className="mdi mdi-filter"></i>
                                            </Button>
                                        </Col>
                                        {/* Download CSV Button */}
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button color="primary" style={{ width: '100%' }} onClick={handleDownloadCSV}>
                                                <i className="mdi mdi-download"></i>
                                            </Button>
                                        </Col>
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button
                                                color="info"
                                                className="add-btn"
                                                style={{ width: '100%' }}
                                                onClick={toggleUploadCSVModal}
                                            >
                                                <i className="mdi mdi-upload align-bottom"></i>
                                            </Button>
                                        </Col>
                                    </Row>

                                    {/* Tags Table */}
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        {(!isUpdating && !isDeleting && !isRestoringUnused && !isBulkDeleting && !isCSVUploading && !isUploading) && (
                                            <table className="table align-middle table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        {/* Select All Checkbox */}
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <Input
                                                                type="checkbox"
                                                                onChange={handleSelectAll}
                                                                checked={selectedTags.length === filteredTagsList.length && filteredTagsList.length > 0}
                                                            />
                                                        </th>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th scope="col">Fish Tag Number</th>
                                                        <th scope="col">Program Name</th>
                                                        <th scope="col">Assigned Angler</th>
                                                        <th scope="col">Created Date</th>
                                                        <th scope="col">Updated Date</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentPageData.length > 0 ? currentPageData.map((tag) => (
                                                        <tr key={tag.id}>
                                                            {/* Individual Checkbox */}
                                                            <td>
                                                                <Input
                                                                    type="checkbox"
                                                                    checked={selectedTags.includes(tag.id)}
                                                                    onChange={() => handleSelectTag(tag.id)}
                                                                />
                                                            </td>
                                                            <th scope="row">
                                                                <a className="btn btn-sm btn-success" href={`/tag/details/${tag.id}`}>
                                                                    <i className="mdi mdi-eye-outline"></i>
                                                                </a>
                                                            </th>
                                                            <td>{`#${tag.tag_number}`}</td>
                                                            <td>
                                                                <a href={`/program/details/${tag.tag_program}`} className="fw-medium link-primary">
                                                                    {tag.program_name}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a href={`/user/details/${tag.first_angler}`} className="fw-medium link-primary">
                                                                    {findNameById(tag.first_angler)}
                                                                </a>
                                                            </td>
                                                            <td>{tag.created_at ? tag.created_at.toDate().toLocaleDateString() : 'N/A'}</td>
                                                            <td>{tag.updated_at ? tag.updated_at.toDate().toLocaleDateString() : 'N/A'}</td>
                                                            <td>
                                                                <span className={`badge ${tag.deleted ? 'badge-soft-danger' : 'badge-soft-warning'} text-uppercase`}>
                                                                    {tag.deleted ? 'Deleted' : 'Un-Used'}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    {/* Edit Button */}
                                                                    <Button
                                                                        color="warning"
                                                                        size="sm"
                                                                        onClick={() => toggleEditModal(tag.id, unusedTagsList.findIndex(t => t.id === tag.id))}
                                                                    >
                                                                        <i className="mdi mdi-square-edit-outline"></i>
                                                                    </Button>
                                                                    {/* Delete or Restore Button */}
                                                                    {!tag.deleted ? (
                                                                        <Button
                                                                            color="danger"
                                                                            size="sm"
                                                                            onClick={() => toggleDeleteModal(tag.id, unusedTagsList.findIndex(t => t.id === tag.id))}
                                                                        >
                                                                            <i className="mdi mdi-delete-outline"></i>
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            color="success"
                                                                            size="sm"
                                                                            onClick={() => toggleRestoreUnusedModal(tag.id, unusedTagsList.findIndex(t => t.id === tag.id))}
                                                                        >
                                                                            <i className="mdi mdi-history"></i>
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )) : (
                                                        <tr>
                                                            <td colSpan="9" className="text-center">No Fish Tags Found!</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        )}

                                        {/* Loader for Single Operations */}
                                        {(isUpdating || isDeleting || isRestoringUnused || isCSVUploading || isUploading) && (
                                            <div className="d-flex justify-content-center my-5">
                                                <ThreeCircles
                                                    visible={isUpdating || isDeleting || isRestoringUnused || isCSVUploading || isUploading}
                                                    height="100"
                                                    width="100"
                                                    color="#4192C3"
                                                    ariaLabel="three-circles-loading"
                                                    wrapperStyle={{ margin: "0 auto" }}
                                                    wrapperClass="justify-content-center"
                                                />
                                            </div>
                                        )}

                                        {/* Bulk Delete Loader */}
                                        {isBulkDeleting && (
                                            <div className="d-flex justify-content-center my-5">
                                                <ThreeCircles
                                                    visible={isBulkDeleting}
                                                    height="100"
                                                    width="100"
                                                    color="#ff3d60"
                                                    ariaLabel="three-circles-loading"
                                                    wrapperStyle={{ margin: "0 auto" }}
                                                    wrapperClass="justify-content-center"
                                                />
                                            </div>
                                        )}

                                        {/* No Results Message */}
                                        {filteredTagsList.length === 0 && !isUpdating && !isDeleting && !isRestoringUnused && !isBulkDeleting && !isCSVUploading && !isUploading && (
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Fish Tags Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any fish tags.</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Pagination Controls */}
                                    {pageCount > 1 && (
                                        <div className="d-flex justify-content-center">
                                            <ReactPaginate
                                                previousLabel={"← Previous"}
                                                nextLabel={"Next →"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageChange}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"page-link"}
                                                nextLinkClassName={"page-link"}
                                                disabledClassName={"disabled"}
                                                activeClassName={"active"}
                                                pageLinkClassName={"page-link"}
                                                breakLabel={"..."}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* --------------------------- */}
            {/* Modals */}
            {/* --------------------------- */}

            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={() => toggleEditModal()} centered>
                <ModalHeader toggle={() => toggleEditModal()}>Edit Tag</ModalHeader>
                <ModalBody>
                    {!isUpdating && updateTagId && (
                        <form id="edit-tag-form" onSubmit={handleUpdateTag}>
                            <FormGroup>
                                <Label for="edit-tag-number">Tag Number*</Label>
                                <Input
                                    type="text"
                                    id="edit-tag-number"
                                    name="tag_number"
                                    defaultValue={unusedTagsList[updateTagIndex]?.tag_number || ''}
                                    required
                                    maxLength={15}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="edit-tag-program">Tagging Program*</Label>
                                <Input
                                    type="select"
                                    id="edit-tag-program"
                                    name="tag_program"
                                    defaultValue={unusedTagsList[updateTagIndex]?.tag_program || ''}
                                    required
                                >
                                    <option value="">Select Tag Program</option>
                                    {allTagPrograms.map(program => (
                                        <option key={program.id} value={program.id}>
                                            {program.name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="edit-assigned-angler">Assigned Angler*</Label>
                                <Input
                                    type="select"
                                    id="edit-assigned-angler"
                                    name="first_angler"
                                    defaultValue={unusedTagsList[updateTagIndex]?.first_angler || ''}
                                    required
                                >
                                    <option value="">Select User Email</option>
                                    {allUsersList.map(user => (
                                        <option key={user.id} value={user.id}>
                                            {user.email}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <ModalFooter>
                                <Button type="button" color="secondary" onClick={() => toggleEditModal()}>
                                    Cancel
                                </Button>
                                <Button type="submit" color="success">
                                    Update
                                </Button>
                            </ModalFooter>
                        </form>
                    )}
                    {isUpdating && (
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isUpdating}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>

            {/* Add Tag Modal */}
            <Modal isOpen={modalList} toggle={() => toggleModalList()} centered>
                <ModalHeader toggle={() => toggleModalList()}>Add Fish Tag</ModalHeader>
                <ModalBody>
                    {!isUploading && (
                        <form id="add-tag-form" onSubmit={handleCreateTag}>
                            <FormGroup>
                                <Label for="add-tag-number">Tag Number*</Label>
                                <Input
                                    type="text"
                                    id="add-tag-number"
                                    name="tag_number"
                                    placeholder="#ABCDE1234567890"
                                    required
                                    maxLength={15}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="add-tag-program">Tagging Program*</Label>
                                <Input
                                    type="select"
                                    id="add-tag-program"
                                    name="tag_program"
                                    required
                                >
                                    <option value="">Select Tag Program</option>
                                    {allTagPrograms.map(program => (
                                        <option key={program.id} value={program.id}>
                                            {program.name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="add-assigned-angler">Assigned Angler*</Label>
                                <Input
                                    type="select"
                                    id="add-assigned-angler"
                                    name="first_user"
                                    required
                                >
                                    <option value="">Select User Email</option>
                                    {allUsersList.map(user => (
                                        <option key={user.id} value={user.id}>
                                            {user.email}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <ModalFooter>
                                <Button type="button" color="secondary" onClick={() => toggleModalList()}>
                                    Cancel
                                </Button>
                                <Button type="submit" color="success">
                                    Add Tag
                                </Button>
                            </ModalFooter>
                        </form>
                    )}
                    {isUploading && (
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isUploading}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal isOpen={deleteModal} toggle={() => toggleDeleteModal()} centered>
                <ModalHeader toggle={() => toggleDeleteModal()}>Delete Un-Used Fish Tag</ModalHeader>
                <ModalBody>
                    {!isDeleting ? (
                        <>
                            <p>Are you sure you want to delete this fish tag?</p>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <Button type="button" color="light" onClick={() => toggleDeleteModal()}>
                                    Cancel
                                </Button>
                                <Button type="button" color="danger" onClick={handleDeleteTag}>
                                    Delete
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isDeleting}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>

            {/* Restore Confirmation Modal */}
            <Modal isOpen={restoreUnusedModal} toggle={() => toggleRestoreUnusedModal()} centered>
                <ModalHeader toggle={() => toggleRestoreUnusedModal()}>Restore Un-Used Fish Tag</ModalHeader>
                <ModalBody>
                    {!isRestoringUnused ? (
                        <>
                            <p>Are you sure you want to restore this tag?</p>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <Button type="button" color="light" onClick={() => toggleRestoreUnusedModal()}>
                                    Cancel
                                </Button>
                                <Button type="button" color="success" onClick={handleRestoreTag}>
                                    Restore
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isRestoringUnused}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>

            {/* Bulk Delete Confirmation Modal */}
            <Modal isOpen={bulkDeleteModal} toggle={toggleBulkDeleteModal} className="modal zoomIn" id="bulkDeleteModal" centered >
                <ModalBody>
                    {!isBulkDeleting ? (
                        <>
                            <div className="mt-2 text-center">
                                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                    <h4>Delete Selected Fish Tags</h4>
                                    <p className="text-muted mx-4 mb-0">Are you sure you want to delete the selected fish tags?</p>
                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <Button
                                    type="button"
                                    color="light"
                                    onClick={toggleBulkDeleteModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    onClick={deleteSelectedTags}
                                >
                                    Delete Selected
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isBulkDeleting}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>

            {/* Upload CSV Modal (Added) */}
            <Modal isOpen={uploadCSVModal} toggle={toggleUploadCSVModal} centered>
                {!isCSVUploading && (
                    <form className="tablelist-form" onSubmit={handleCSVUpload}>
                        <ModalHeader toggle={toggleUploadCSVModal}>Import Tags from CSV</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="csvFile">Select CSV File</Label>
                                <Input
                                    type="file"
                                    name="csvFile"
                                    id="csvFile"
                                    accept=".csv"
                                    required
                                    onChange={(e) => setCsvFile(e.target.files[0])}
                                />
                            </FormGroup>
                            <p className="text-muted">
                                The CSV file should contain the following columns:
                                <ul>
                                    <li><strong>tag_number</strong> (String)</li>
                                    <li><strong>first_angler_email</strong> (String - User's Email Address)</li>
                                    <li><strong>tag_program_id</strong> (String - ID of the Tagging Program)</li>
                                </ul>
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button type="button" color="secondary" onClick={() => toggleUploadCSVModal()}>
                                    Cancel
                                </Button>
                                <Button type="submit" color="primary">
                                    Upload CSV
                                </Button>
                            </div>
                        </ModalFooter>
                    </form>
                )}
                {isCSVUploading && (
                    <ModalBody>
                        <div className="d-flex flex-column align-items-center">
                            <ThreeCircles
                                visible={isCSVUploading}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                            <p className="mt-3">Uploading CSV and processing data...</p>
                            <div className="w-100 mt-3">
                                <div className="progress">
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{ width: `${uploadProgress}%` }}
                                        aria-valuenow={uploadProgress}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        {uploadProgress}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                )}
            </Modal>
        </React.Fragment>
    );

};

export default UnusedTags;