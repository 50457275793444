// UsedTags.jsx

import React, { useRef, useState, useEffect } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    ModalHeader,
    Input,
    FormGroup,
    Label
} from 'reactstrap';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import { serverTimestamp } from 'firebase/firestore';

const UsedTags = () => {

    // ---------------------------
    // STATE VARIABLES
    // ---------------------------
    const [initialLoad, setInitialLoad] = useState(true);
    const [usedTagsList, setUsedTagsList] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [allTagPrograms, setAllTagPrograms] = useState([]);
    const [allUsersList, setAllUsersList] = useState([]);
    const [allSpeciesList, setAllSpeciesList] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);

    // Action states
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateTagId, setUpdateTagId] = useState('');
    const [updateTagIndex, setUpdateTagIndex] = useState(null);
    const [deleteTagId, setDeleteTagId] = useState('');
    const [deleteTagIndex, setDeleteTagIndex] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    const [restoreTagId, setRestoreTagId] = useState('');
    const [restoreTagIndex, setRestoreTagIndex] = useState(null);
    const [isRestoringUsed, setIsRestoringUsed] = useState(false);

    // Modal states
    const [modal_list, setmodal_list] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [restoreUsedModal, setRestoreUsedModal] = useState(false);

    // Filter and Search states
    const [selectedSpeciesFilter, setSelectedSpeciesFilter] = useState('');
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    // NEW: States for "searchable" species filter
    const [speciesSearch, setSpeciesSearch] = useState('');
    const [showSpeciesDropdown, setShowSpeciesDropdown] = useState(false);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    // Multi-select states
    const [selectedTags, setSelectedTags] = useState([]);
    const [bulkDeleteModal, setBulkDeleteModal] = useState(false);
    const [isBulkDeleting, setIsBulkDeleting] = useState(false);

    // Firestore Listener Subscription Ref
    const subscriptionRef = useRef(null);

    // ---------------------------
    // DOCUMENT TITLE
    // ---------------------------
    useEffect(() => {
        document.title = "Fish Tags | Fish-Trak - Internal Management Dashboard";
    }, []);

    // ---------------------------
    // HELPER FUNCTIONS
    // ---------------------------

    // Find user name by user ID
    const findNameById = (userId) => {
        const user = allUsersList.find(u => u.id === userId);
        return user ? user.name : "No User Name";
    };

    // ---------------------------
    // FETCH TAG PROGRAMS
    // ---------------------------
    const fetchTagPrograms = () => {
        firebase.firestore().collection('programs').orderBy('created_at','desc')
            .onSnapshot(
                querySnapshot => {
                    const programs = [];
                    querySnapshot.forEach(doc => {
                        const data = doc.data();
                        programs.push({
                            id: doc.id,
                            name: data.program_name,
                            image: data.image_url,
                        });
                    });
                    setAllTagPrograms(programs);
                },
                error => {
                    console.error("Error fetching tag programs:", error);
                    toast.error("Error fetching tag programs.");
                }
            );
    };

    // ---------------------------
    // FETCH USERS
    // ---------------------------
    const fetchAllUsers = () => {
        firebase.firestore().collection('users').orderBy('created_at','desc')
            .onSnapshot(
                querySnapshot => {
                    const users = [];
                    querySnapshot.forEach(doc => {
                        const data = doc.data();
                        users.push({
                            id: doc.id,
                            name: `${data.first_name} ${data.last_name}`,
                            email: data.email_address,
                        });
                    });
                    setAllUsersList(users);
                },
                error => {
                    console.error("Error fetching users:", error);
                    toast.error("Error fetching users.");
                }
            );
    };

    // ---------------------------
    // FETCH ALL SPECIES
    // ---------------------------
    const fetchAllSpecies = () => {
        firebase.firestore().collection('species').orderBy('name','asc')
            .onSnapshot(
                querySnapshot => {
                    const species = [];
                    querySnapshot.forEach(doc => {
                        const data = doc.data();
                        species.push({
                            id: doc.id,
                            name: data.name,
                        });
                    });
                    setAllSpeciesList(species);
                },
                error => {
                    console.error("Error fetching species:", error);
                    toast.error("Error fetching species.");
                }
            );
    };

    // ---------------------------
    // FETCH USED FISH TAGS (with filters)
    // ---------------------------
    const fetchUsedFishTags = () => {
        // Unsubscribe if existing
        if(subscriptionRef.current) {
            subscriptionRef.current();
        }

        let query = firebase.firestore().collection('tags')
            .where('used','==',true)
            .orderBy('created_at','desc');

        // Species filter
        if(selectedSpeciesFilter) {
            const speciesRef = firebase.firestore().collection('species').doc(selectedSpeciesFilter);
            query = query.where('fish_species','==',speciesRef);
        }

        // Date range filters
        if(startDateFilter && endDateFilter) {
            query = query
                .where('created_at','>=',firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
                .where('created_at','<=',firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        }
        else if(startDateFilter && !endDateFilter) {
            query = query.where('created_at','>=',firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
        }
        else if(!startDateFilter && endDateFilter) {
            query = query.where('created_at','<=',firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        }

        const unsubscribe = query.onSnapshot(
            (snapshot) => {
                const newTags = [];
                snapshot.forEach(doc => {
                    const tagData = doc.data();
                    tagData.id = doc.id;

                    // Additional computed fields
                    tagData.tag = `#${tagData.tag_number}`;
                    tagData.program = tagData.program_name;
                    tagData.created = tagData.created_at
                        ? tagData.created_at.toDate().toLocaleString('en-US',{year:'numeric',month:'short',day:'numeric'})
                        : '';
                    tagData.updated = tagData.updated_at
                        ? tagData.updated_at.toDate().toLocaleString('en-US',{year:'numeric',month:'short',day:'numeric'})
                        : '';
                    tagData.userId = tagData.first_angler.id;
                    tagData.user_link = `user/details/${tagData.userId}`;
                    tagData.link = `tag/details/${doc.id}`;
                    tagData.program_link = `program/details/${tagData.tag_program.id}`;

                    newTags.push(tagData);
                });
                setUsedTagsList(newTags);
                setFetchedData(true);
                setIsUploading(false);
                setmodal_list(false);
                setEditModal(false);
                setDeleteModal(false);
                setRestoreUsedModal(false);
                setIsUpdating(false);
                setIsDeleting(false);
                setIsRestoringUsed(false);
                setBulkDeleteModal(false);
                setIsBulkDeleting(false);
            },
            (err) => {
                console.error("Error fetching used tags:", err);
                toast.error("Error fetching used fish tags.");
            }
        );

        subscriptionRef.current = unsubscribe;
    };

    // ---------------------------
    // INITIAL LOAD
    // ---------------------------
    useEffect(() => {
        if(initialLoad) {
            setInitialLoad(false);
            fetchAllSpecies();
            fetchTagPrograms();
            fetchAllUsers();
            fetchUsedFishTags();
        }
    }, [initialLoad]);

    // ---------------------------
    // SEARCH & FILTER
    // ---------------------------
    useEffect(() => {
        let tempList = [...usedTagsList];
        if(searchQuery) {
            const q = searchQuery.toLowerCase();
            tempList = tempList.filter(tag =>
                tag.tag.toLowerCase().includes(q) ||
                tag.program.toLowerCase().includes(q) ||
                (tag.userId && findNameById(tag.userId).toLowerCase().includes(q))
            );
        }
        // We set it back so that pagination can re-render
        setUsedTagsList(tempList);
        setCurrentPage(0);
    }, [searchQuery]);

    // ---------------------------
    // PAGINATION
    // ---------------------------
    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };
    const offset = currentPage * itemsPerPage;
    const currentPageData = usedTagsList.slice(offset, offset + itemsPerPage);
    const pageCount = Math.ceil(usedTagsList.length / itemsPerPage);

    // ---------------------------
    // MULTI-SELECTION
    // ---------------------------
    const handleSelectTag = (tagId) => {
        setSelectedTags(prev => {
            if(prev.includes(tagId)) {
                return prev.filter(id => id !== tagId);
            } else {
                return [...prev, tagId];
            }
        });
    };
    const handleSelectAll = () => {
        const allOnCurrentPage = currentPageData.map(tag => tag.id);
        const allSelected = allOnCurrentPage.every(id => selectedTags.includes(id));
        if(allSelected) {
            // unselect them
            setSelectedTags(prev => prev.filter(id => !allOnCurrentPage.includes(id)));
        } else {
            // add them
            setSelectedTags(prev => [...new Set([...prev, ...allOnCurrentPage])]);
        }
    };
    const toggleBulkDeleteModal = () => {
        setBulkDeleteModal(!bulkDeleteModal);
    };

    // ---------------------------
    // DELETE SELECTED TAGS (BULK)
    // ---------------------------
    const deleteSelectedTags = async () => {
        setIsBulkDeleting(true);

        const batch = firebase.firestore().batch();
        const tagsRef = firebase.firestore().collection('tags');
        const catchesRef = firebase.firestore().collection('catches');
        const usersRef = firebase.firestore().collection('users');

        try {
            for (let tagId of selectedTags) {
                const tagDocRef = tagsRef.doc(tagId);
                const tagSnapshot = await tagDocRef.get();
                if(!tagSnapshot.exists) {
                    toast.error(`Tag with ID ${tagId} does not exist.`);
                    continue;
                }

                // For each catch associated with this tag
                const allAssociatedCatches = await catchesRef.where('tag','==',tagDocRef).get();
                allAssociatedCatches.forEach(c => {
                    const catchData = c.data();
                    const catchDocRef = catchesRef.doc(c.id);

                    // We must update the user doc
                    const userDocRef = usersRef.doc(catchData.user.id);
                    const userData = { updated_at: serverTimestamp() };

                    if(catchData.recatch) {
                        if(!catchData.recatch_count || catchData.recatch_count <= 1) {
                            userData['recatch_count'] = 0;
                        } else {
                            userData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                        }
                    } else {
                        if(!catchData.catch_count || catchData.catch_count <= 1) {
                            userData['catch_count'] = 0;
                        } else {
                            userData['catch_count'] = firebase.firestore.FieldValue.increment(-1);
                        }
                    }
                    // Also decrement species_count (since user "loses" that species from active records)
                    if(catchData.fish_species && catchData.fish_species.id) {
                        userData['species_count'] = firebase.firestore.FieldValue.increment(-1);
                    }

                    batch.update(userDocRef, userData);
                    batch.update(catchDocRef, {
                        updated_at: serverTimestamp(),
                        deleted: true
                    });
                });

                // Finally, mark the tag as deleted
                batch.update(tagDocRef, {
                    updated_at: serverTimestamp(),
                    deleted: true
                });
            }

            await batch.commit();
            toast.success('Selected tags have been deleted successfully!');
            setSelectedTags([]);
            toggleBulkDeleteModal();
            fetchUsedFishTags();
        } catch(error) {
            console.error('Error deleting selected tags:', error);
            toast.error('Could not delete some of the selected tags. Please try again.');
        } finally {
            setIsBulkDeleting(false);
        }
    };

    // ---------------------------
    // FILTERS
    // ---------------------------
    const handleApplyFilters = () => {
        fetchUsedFishTags();
        setCurrentPage(0);
        toast.info("Filters applied.");
    };

    // ---------------------------
    // CSV DOWNLOAD
    // ---------------------------
    const handleDownloadCSV = async () => {
        try {
            let csvQuery = firebase.firestore().collection('tags')
                .where('used','==',true)
                .orderBy('created_at','desc');

            if(selectedSpeciesFilter) {
                const speciesRef = firebase.firestore().collection('species').doc(selectedSpeciesFilter);
                csvQuery = csvQuery.where('fish_species','==',speciesRef);
            }
            if(startDateFilter && endDateFilter) {
                csvQuery = csvQuery
                    .where('created_at','>=',firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
                    .where('created_at','<=',firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }
            else if(startDateFilter && !endDateFilter) {
                csvQuery = csvQuery
                    .where('created_at','>=',firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
            }
            else if(!startDateFilter && endDateFilter) {
                csvQuery = csvQuery
                    .where('created_at','<=',firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }

            const snapshot = await csvQuery.get();
            const csvRows = [];
            // Build header
            csvRows.push([
                'id',
                'tag_number',
                'program_name',
                'created_date',
                'created_time',
                'updated_date',
                'updated_time',
                'catch_count',
                'deleted',
                'used'
            ].join(','));

            snapshot.forEach(doc => {
                const data = doc.data();
                let createdVal = '';
                let updatedVal = '';
                if(data.created_at) createdVal = data.created_at.toDate().toLocaleString();
                if(data.updated_at) updatedVal = data.updated_at.toDate().toLocaleString();

                const row = [
                    doc.id,
                    (data.tag_number || '').replace(/,/g,''),
                    (data.program_name || '').replace(/,/g,''),
                    createdVal,
                    updatedVal,
                    data.catch_count || '0',
                    data.deleted ? 'true' : 'false',
                    data.used ? 'true' : 'false'
                ];
                csvRows.push(row.join(','));
            });

            const csvString = csvRows.join('\n');
            const blob = new Blob([csvString], { type:'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download','filtered_used_tags.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
            URL.revokeObjectURL(url);

            toast.success("CSV downloaded successfully.");
        } catch (error) {
            console.error('CSV error:', error);
            toast.error('Could not download CSV. Please try again.');
        }
    };

    // ---------------------------
    // CREATE NEW FISH TAG
    // ---------------------------
    const createNewFishTag = async (event) => {
        setIsUploading(true);
        event.preventDefault();

        const programId = event.target.tag_program.value;
        const userId = event.target.first_user.value;
        const tagRef = firebase.firestore().collection('tags');

        const newTagData = {
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
            tag_number: event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g,''),
            catch_count: 0,
            used: true,  // By definition, these are used tags
            deleted: false,
            program_image: allTagPrograms.find(p => p.id === programId)?.image || '',
            program_name: allTagPrograms.find(p => p.id === programId)?.name || '',
            tag_program: firebase.firestore().collection('programs').doc(programId),
            first_angler: firebase.firestore().collection('users').doc(userId),
        };

        try {
            await tagRef.add(newTagData);
            toast.success('The new fish tag has been created successfully!');
            fetchUsedFishTags();
            setIsUploading(false);
            tog_list(); // Close modal
        } catch(err) {
            console.error("Error creating new tag:", err);
            setIsUploading(false);
            toast.error('Could not create fish tag entry. Please try again.');
        }
    };

    // ---------------------------
    // UPDATE TAG
    // ---------------------------
    const handleUpdateTag = (event) => {
        event.preventDefault();
        submitUpdateForm(event);
    };

    const submitUpdateForm = async (event) => {
        setIsUpdating(true);

        const programId = event.target.tag_program.value;
        const userId = event.target.first_angler.value;
        const tagRef = firebase.firestore().collection('tags').doc(updateTagId);

        const updatedData = {
            updated_at: serverTimestamp(),
        };

        // Compare and update fields
        if(event.target.tag_number.value !== usedTagsList[updateTagIndex].tag_number) {
            updatedData['tag_number'] = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g,'');
        }
        if(programId !== usedTagsList[updateTagIndex].tag_program.id) {
            updatedData['tag_program'] = firebase.firestore().collection('programs').doc(programId);
            updatedData['program_image'] = allTagPrograms.find(p => p.id === programId)?.image || '';
            updatedData['program_name'] = allTagPrograms.find(p => p.id === programId)?.name || '';
        }
        if(userId !== usedTagsList[updateTagIndex].first_angler.id) {
            updatedData['first_angler'] = firebase.firestore().collection('users').doc(userId);
        }

        try {
            await tagRef.update(updatedData);
            toast.success('The tag has been updated successfully!');
            fetchUsedFishTags();
            setIsUpdating(false);
            setEditModal(false);
        } catch(error) {
            console.error(error);
            setIsUpdating(false);
            toast.error('Could not update tag entry. Please try again.');
        }
    };

    // ---------------------------
    // DELETE / RESTORE INDIVIDUAL TAG
    // ---------------------------
    const deleteTag = async () => {
        setIsDeleting(true);

        const tagsRef = firebase.firestore().collection('tags');
        const catchesRef = firebase.firestore().collection('catches');
        const usersRef = firebase.firestore().collection('users');
        const tagDocRef = tagsRef.doc(deleteTagId);

        try {
            const snapshot = await catchesRef.where('tag','==',tagDocRef).get();
            const batch = firebase.firestore().batch();

            snapshot.forEach(c => {
                const catchData = c.data();
                const catchDocRef = catchesRef.doc(c.id);

                const userDocRef = usersRef.doc(catchData.user.id);
                let userUpdate = { updated_at: serverTimestamp() };

                if(catchData.recatch) {
                    if(!catchData.recatch_count || catchData.recatch_count <= 1) {
                        userUpdate['recatch_count'] = 0;
                    } else {
                        userUpdate['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                    }
                } else {
                    if(!catchData.catch_count || catchData.catch_count <= 1) {
                        userUpdate['catch_count'] = 0;
                    } else {
                        userUpdate['catch_count'] = firebase.firestore.FieldValue.increment(-1);
                    }
                }
                // Decrement species_count if a species is recorded
                if(catchData.fish_species && catchData.fish_species.id) {
                    userUpdate['species_count'] = firebase.firestore.FieldValue.increment(-1);
                }

                batch.update(userDocRef, userUpdate);
                batch.update(catchDocRef, {
                    updated_at: serverTimestamp(),
                    deleted: true
                });
            });

            // Mark the tag as deleted
            batch.update(tagDocRef, {
                updated_at: serverTimestamp(),
                deleted: true
            });

            await batch.commit();
            toast.success('The fish tag has been deleted successfully!');
            setDeleteTagId('');
            setDeleteTagIndex(null);
            setIsDeleting(false);
            toggleDeleteModal();
            fetchUsedFishTags();
        } catch(error) {
            console.error(error);
            setIsDeleting(false);
            toast.error('Could not delete tag entry. Please try again.');
        }
    };

    const restoreUsedTag = async () => {
        setIsRestoringUsed(true);

        const tagsRef = firebase.firestore().collection('tags');
        const catchesRef = firebase.firestore().collection('catches');
        const usersRef = firebase.firestore().collection('users');
        const tagDocRef = tagsRef.doc(restoreTagId);

        try {
            const snapshot = await catchesRef.where('tag','==',tagDocRef).get();
            const batch = firebase.firestore().batch();

            snapshot.forEach(c => {
                const catchData = c.data();
                const catchDocRef = catchesRef.doc(c.id);

                const userDocRef = usersRef.doc(catchData.user.id);
                let userUpdate = { updated_at: serverTimestamp() };

                if(catchData.recatch) {
                    userUpdate['recatch_count'] = firebase.firestore.FieldValue.increment(1);
                } else {
                    userUpdate['catch_count'] = firebase.firestore.FieldValue.increment(1);
                }
                // Increment species_count if a species is recorded
                if(catchData.fish_species && catchData.fish_species.id) {
                    userUpdate['species_count'] = firebase.firestore.FieldValue.increment(1);
                }

                batch.update(userDocRef, userUpdate);
                batch.update(catchDocRef, {
                    updated_at: serverTimestamp(),
                    deleted: false
                });
            });

            // Mark the tag as not deleted
            batch.update(tagDocRef, {
                updated_at: serverTimestamp(),
                deleted: false
            });

            await batch.commit();
            toast.success('The fish tag has been restored successfully!');
            setRestoreTagId('');
            setRestoreTagIndex(null);
            setIsRestoringUsed(false);
            toggleRestoreUsedModal();
            fetchUsedFishTags();
        } catch(error) {
            console.error(error);
            setIsRestoringUsed(false);
            toast.error('Could not restore tag entry. Please try again.');
        }
    };

    // ---------------------------
    // MODAL TOGGLE
    // ---------------------------
    const tog_list = () => setmodal_list(!modal_list);

    const toggleEditModal = (tagId = '', tagIndex = null) => {
        if(editModal) {
            setUpdateTagId('');
            setUpdateTagIndex(null);
        } else {
            setUpdateTagId(tagId);
            setUpdateTagIndex(tagIndex);
        }
        setEditModal(!editModal);
    };

    const toggleDeleteModal = (tagId = '', tagIndex = null) => {
        if(deleteModal) {
            setDeleteTagId('');
            setDeleteTagIndex(null);
        } else {
            setDeleteTagId(tagId);
            setDeleteTagIndex(tagIndex);
        }
        setDeleteModal(!deleteModal);
    };

    const toggleRestoreUsedModal = (tagId = '', tagIndex = null) => {
        if(restoreUsedModal) {
            setRestoreTagId('');
            setRestoreTagIndex(null);
        } else {
            setRestoreTagId(tagId);
            setRestoreTagIndex(tagIndex);
        }
        setRestoreUsedModal(!restoreUsedModal);
    };

    return (
        <React.Fragment>
            {/* Toast Container for Notifications */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="page-content">
                <Container fluid>
                    <CardTitle>Fish Tags</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Used Tags</h4>
                                </CardHeader>

                                <CardBody>
                                    {/* Filter and Action Controls */}
                                    <Row className="g-4 mb-3">
                                        {/* Add Tag Button */}
                                        <Col sm="1" className="d-flex align-items-start">
                                            <Button
                                                color="success"
                                                className="add-btn"
                                                onClick={tog_list}
                                                id="create-btn"
                                                style={{ width:'100%' }}
                                            >
                                                <i className="mdi mdi-tag-plus"></i>
                                            </Button>
                                        </Col>
                                        {/* Bulk Delete Button */}
                                        <Col sm="1" className="d-flex align-items-start">
                                            <Button
                                                color="danger"
                                                className="add-btn"
                                                style={{ width:'100%' }}
                                                onClick={toggleBulkDeleteModal}
                                                disabled={selectedTags.length === 0}
                                            >
                                                <i className="mdi mdi-delete"></i>
                                            </Button>
                                        </Col>
                                        {/* Search */}
                                        <Col className="col-sm">
                                            <div className="justify-content-sm-start">
                                                <div className="search-box">
                                                    <Input
                                                        type="search"
                                                        className="form-control"
                                                        placeholder="Search Tags..."
                                                        value={searchQuery}
                                                        onChange={(e) => setSearchQuery(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                        {/* 
                                          REPLACE the old <Input type="select" ...> for Species 
                                          with a text-based search + dropdown 
                                        */}
                                        <Col sm="2" style={{ position: "relative" }}>
                                            <Input
                                                type="text"
                                                placeholder="Search Species..."
                                                value={speciesSearch}
                                                onChange={(e) => {
                                                    setSpeciesSearch(e.target.value);
                                                    if(e.target.value.trim() !== '') {
                                                        setShowSpeciesDropdown(true);
                                                    } else {
                                                        setShowSpeciesDropdown(false);
                                                    }
                                                }}
                                                onFocus={() => {
                                                    if(speciesSearch.trim() !== '') {
                                                        setShowSpeciesDropdown(true);
                                                    }
                                                }}
                                            />
                                            {showSpeciesDropdown && (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: '100%',
                                                        left: 0,
                                                        right: 0,
                                                        zIndex: 999,
                                                        background: '#fff',
                                                        border: '1px solid #ccc',
                                                        maxHeight: '200px',
                                                        overflowY: 'auto'
                                                    }}
                                                >
                                                    {/* "All Species" to reset filter */}
                                                    <div
                                                        className="p-2"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setSelectedSpeciesFilter('');
                                                            setSpeciesSearch('');
                                                            setShowSpeciesDropdown(false);
                                                        }}
                                                    >
                                                        All Species
                                                    </div>
                                                    {allSpeciesList
                                                        .filter(sp =>
                                                            sp.name.toLowerCase().includes(speciesSearch.toLowerCase())
                                                        )
                                                        .map(sp => (
                                                            <div
                                                                key={sp.id}
                                                                className="p-2"
                                                                style={{ cursor:'pointer' }}
                                                                onClick={() => {
                                                                    setSelectedSpeciesFilter(sp.id);
                                                                    setSpeciesSearch(sp.name);
                                                                    setShowSpeciesDropdown(false);
                                                                }}
                                                            >
                                                                {sp.name}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )}
                                        </Col>
                                        
                                        {/* Start Date */}
                                        <Col sm="2">
                                            <Flatpickr
                                                className="form-control"
                                                options={{ dateFormat:"Y-m-d" }}
                                                placeholder="Start Date"
                                                value={startDateFilter}
                                                onChange={(dates)=> setStartDateFilter(dates[0] || null)}
                                            />
                                        </Col>
                                        {/* End Date */}
                                        <Col sm="2">
                                            <Flatpickr
                                                className="form-control"
                                                options={{ dateFormat:"Y-m-d" }}
                                                placeholder="End Date"
                                                value={endDateFilter}
                                                onChange={(dates)=> setEndDateFilter(dates[0] || null)}
                                            />
                                        </Col>
                                        {/* Apply Filters */}
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button color="secondary" style={{ width:'100%' }} onClick={handleApplyFilters}>
                                                <i className="mdi mdi-filter"></i>
                                            </Button>
                                        </Col>
                                        {/* Download CSV */}
                                        <Col sm="1" className="d-flex align-items-end">
                                            <Button color="primary" style={{ width:'100%' }} onClick={handleDownloadCSV}>
                                                <i className="mdi mdi-download"></i>
                                            </Button>
                                        </Col>
                                    </Row>

                                    {/* Tags Table */}
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        {(!isUpdating && !isDeleting && !isRestoringUsed && !isBulkDeleting && !isUploading) && (
                                            <table className="table align-middle table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        {/* Select All checkbox for current page */}
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <Input
                                                                type="checkbox"
                                                                onChange={handleSelectAll}
                                                                checked={
                                                                    currentPageData.length > 0 &&
                                                                    currentPageData.every(t => selectedTags.includes(t.id))
                                                                }
                                                            />
                                                        </th>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th scope="col">Fish Tag Number</th>
                                                        <th scope="col">Program Name</th>
                                                        <th scope="col">Angler</th>
                                                        <th scope="col">Created Date</th>
                                                        <th scope="col">Updated Date</th>
                                                        <th scope="col">Catches</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentPageData.length > 0 ? currentPageData.map((tag) => (
                                                        <tr key={tag.id}>
                                                            {/* Individual row selection */}
                                                            <td>
                                                                <Input
                                                                    type="checkbox"
                                                                    checked={selectedTags.includes(tag.id)}
                                                                    onChange={() => handleSelectTag(tag.id)}
                                                                />
                                                            </td>
                                                            <th scope="row">
                                                                <a className="btn btn-sm btn-success" href={tag.link}>
                                                                    <i className="mdi mdi-eye-outline"></i>
                                                                </a>
                                                            </th>
                                                            <td>{tag.tag}</td>
                                                            <td>
                                                                <a href={tag.program_link} className="fw-medium link-primary">
                                                                    {tag.program}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a href={tag.user_link} className="fw-medium link-primary">
                                                                    {findNameById(tag.userId)}
                                                                </a>
                                                            </td>
                                                            <td>{tag.created}</td>
                                                            <td>{tag.updated}</td>
                                                            <td>
                                                                <span className="badge badge-soft-success text-uppercase">
                                                                    {tag.catch_count}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="badge badge-soft-success text-uppercase">
                                                                    Used
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    {/* Edit Button */}
                                                                    <Button
                                                                        color="warning"
                                                                        size="sm"
                                                                        onClick={() => toggleEditModal(tag.id, usedTagsList.findIndex(t => t.id === tag.id))}
                                                                    >
                                                                        <i className="mdi mdi-square-edit-outline"></i>
                                                                    </Button>
                                                                    {/* Delete/Restore Buttons */}
                                                                    {!tag.deleted ? (
                                                                        <Button
                                                                            color="danger"
                                                                            size="sm"
                                                                            onClick={() => toggleDeleteModal(tag.id, usedTagsList.findIndex(t => t.id === tag.id))}
                                                                        >
                                                                            <i className="mdi mdi-delete-outline"></i>
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            color="success"
                                                                            size="sm"
                                                                            onClick={() => toggleRestoreUsedModal(tag.id, usedTagsList.findIndex(t => t.id === tag.id))}
                                                                        >
                                                                            <i className="mdi mdi-history"></i>
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )) : (
                                                        <tr>
                                                            <td colSpan="10" className="text-center">No Fish Tags Found!</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        )}
                                        {(isUpdating || isDeleting || isRestoringUsed || isBulkDeleting || isUploading) && (
                                            <div className="d-flex justify-content-center my-5">
                                                <ThreeCircles
                                                    visible={isUpdating || isDeleting || isRestoringUsed || isBulkDeleting || isUploading}
                                                    height="100"
                                                    width="100"
                                                    color="#4192C3"
                                                    ariaLabel="three-circles-loading"
                                                    wrapperStyle={{ margin: "0 auto" }}
                                                    wrapperClass="justify-content-center"
                                                />
                                            </div>
                                        )}
                                        {usedTagsList.length === 0 && !isUpdating && !isDeleting && !isRestoringUsed && !isBulkDeleting && !isUploading && (
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Fish Tags Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any fish tags.</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Pagination */}
                                    {pageCount > 1 && (
                                        <div className="d-flex justify-content-center">
                                            <ReactPaginate
                                                previousLabel={"← Previous"}
                                                nextLabel={"Next →"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"page-link"}
                                                nextLinkClassName={"page-link"}
                                                disabledClassName={"disabled"}
                                                activeClassName={"active"}
                                                pageLinkClassName={"page-link"}
                                                breakLabel={"..."}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* --------------------------- */}
            {/* Modals Section */}
            {/* --------------------------- */}

            {/* Edit Tag Modal */}
            <Modal isOpen={editModal} toggle={() => toggleEditModal()} centered>
                <ModalHeader toggle={() => toggleEditModal()}>Edit Tag Program</ModalHeader>
                <ModalBody>
                    {!isUpdating && usedTagsList[updateTagIndex] && (
                        <form id="edit-tag-form" onSubmit={handleUpdateTag}>
                            <FormGroup>
                                <Label for="tagnumber-field">Tag Number*</Label>
                                <Input
                                    type="text"
                                    id="tagnumber-field"
                                    className="form-control"
                                    placeholder="#ABCDE1234567890"
                                    maxLength={15}
                                    required
                                    name="tag_number"
                                    defaultValue={usedTagsList[updateTagIndex].tag_number}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="program-field">Tagging Program*</Label>
                                <Input
                                    type="select"
                                    name="tag_program"
                                    id="program-field"
                                    required
                                    defaultValue={usedTagsList[updateTagIndex].tag_program.id}
                                >
                                    <option value="">Select Tag Program</option>
                                    {allTagPrograms.map(({ name, id }, idx) => (
                                        <option key={idx} value={id}>
                                            {name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="angler-field">Assigned User*</Label>
                                <Input
                                    type="select"
                                    name="first_angler"
                                    id="angler-field"
                                    required
                                    defaultValue={usedTagsList[updateTagIndex].first_angler.id}
                                >
                                    <option value="">Select User Email</option>
                                    {allUsersList.map(({ email, id }, idx) => (
                                        <option key={idx} value={id}>
                                            {email}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </form>
                    )}
                    {isUpdating && (
                        <div className="d-flex justify-content-center">
                            <ThreeCircles
                                visible={isUpdating}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin:"0 auto" }}
                            />
                        </div>
                    )}
                </ModalBody>
                {!isUploading && !isUpdating && (
                    <ModalFooter>
                        <Button
                            type="button"
                            color="light"
                            onClick={() => toggleEditModal()}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            color="success"
                            form="edit-tag-form"
                        >
                            Update
                        </Button>
                    </ModalFooter>
                )}
            </Modal>

            {/* Add Tag Modal */}
            <Modal isOpen={modal_list} toggle={() => tog_list()} centered>
                <ModalHeader toggle={() => tog_list()}>Add Fish Tag</ModalHeader>
                <ModalBody>
                    {!isUploading && (
                        <form id="add-tag-form" onSubmit={createNewFishTag}>
                            <FormGroup>
                                <Label for="tagnumber-field">Tag Number*</Label>
                                <Input
                                    type="text"
                                    id="tagnumber-field"
                                    className="form-control"
                                    placeholder="#ABCDE1234567890"
                                    maxLength={15}
                                    required
                                    name="tag_number"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="program-field">Tagging Program*</Label>
                                <Input
                                    type="select"
                                    name="tag_program"
                                    id="program-field"
                                    required
                                >
                                    <option value="">Select Tag Program</option>
                                    {allTagPrograms.map(({ name, id }, idx) => (
                                        <option key={idx} value={id}>
                                            {name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="angler-field">Assigned User*</Label>
                                <Input
                                    type="select"
                                    name="first_user"
                                    id="angler-field"
                                    required
                                >
                                    <option value="">Select User Email</option>
                                    {allUsersList.map(({ email, id }, idx) => (
                                        <option key={idx} value={id}>
                                            {email}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </form>
                    )}
                    {isUploading && (
                        <div className="d-flex justify-content-center">
                            <ThreeCircles
                                visible={isUploading}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin:"0 auto" }}
                            />
                        </div>
                    )}
                </ModalBody>
                {!isUploading && (
                    <ModalFooter>
                        <Button
                            type="button"
                            color="light"
                            onClick={() => tog_list()}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            color="success"
                            form="add-tag-form"
                        >
                            Add Tag
                        </Button>
                    </ModalFooter>
                )}
            </Modal>

            {/* Delete Tag Modal */}
            <Modal
                isOpen={deleteModal}
                toggle={() => toggleDeleteModal()}
                className="modal zoomIn"
                id="deleteTagModal"
                centered
            >
                {!isDeleting ? (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Delete Used Fish Tag</h4><br/>
                                <h5 className="text-muted mx-4 mb-0">
                                    This will delete the tag along with connected catches or recatches!!!
                                </h5><br/>
                                <p className="text-muted mx-4 mb-0">
                                    Are you sure you want to delete this tag?
                                </p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={() => toggleDeleteModal()}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                id="delete-record"
                                onClick={deleteTag}
                            >
                                Delete
                            </Button>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center">
                            <ThreeCircles
                                visible={isDeleting}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin:"0 auto" }}
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Restore Tag Modal */}
            <Modal
                isOpen={restoreUsedModal}
                toggle={() => toggleRestoreUsedModal()}
                className="modal zoomIn"
                id="restoreUsedModal"
                centered
            >
                {!isRestoringUsed ? (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Restore Used Fish Tag</h4>
                                <p className="text-muted mx-4 mb-0">
                                    This will restore the tag along with connected catches or recatches!!!
                                </p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={() => toggleRestoreUsedModal()}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="success"
                                id="restore-record"
                                onClick={restoreUsedTag}
                            >
                                Restore
                            </Button>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center">
                            <ThreeCircles
                                visible={isRestoringUsed}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin:"0 auto" }}
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Bulk Delete Confirmation Modal */}
            <Modal
                isOpen={bulkDeleteModal}
                toggle={toggleBulkDeleteModal}
                className="modal zoomIn"
                id="bulkDeleteModal"
                centered
            >
                {!isBulkDeleting ? (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Delete Selected Fish Tags</h4>
                                <p className="text-muted mx-4 mb-0">
                                    Are you sure you want to delete the selected fish tags?
                                </p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={toggleBulkDeleteModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                onClick={deleteSelectedTags}
                            >
                                Delete Selected
                            </Button>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center">
                            <ThreeCircles
                                visible={isBulkDeleting}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin:"0 auto" }}
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>
        </React.Fragment>
    );
};

export default UsedTags;